import { lazy } from 'react';
import useHead from '../../common/hooks/useHead';
import Outlet from '../../routes/RouteWithSubRoutes';
import { ROOT_URL } from './config';
import { ROUTES as ADJUDICATION_ROUTES } from './screens/adjudication/App';
import { ROUTES as DISPATCH_ROUTES } from './screens/dispatch/App';
import { ROUTES as AAS_ADJUDICATION_ROUTES } from './screens/aas-adjudication/App';

export const ROUTES = [
  {
    path: ROOT_URL,
    component: lazy(() => import('./App')),
    routes: [
      ...DISPATCH_ROUTES,
      ...ADJUDICATION_ROUTES,
      ...AAS_ADJUDICATION_ROUTES,
      {
        path: '*',
        component: lazy(() => import('../error-screen/ErrorScreen')),
      },
    ],
  },
];

export default function App({ routes }) {
  useHead('Tasks');
  return <Outlet routes={routes} />;
}
