import { Auth } from 'aws-amplify';

const apiGatewayLocal = {
  name: `equips-app`,
  region: 'us-east-1',
  endpoint: `http://localhost:3000/dev`,
  // For SLS Offline https://github.com/dherault/serverless-offline#custom-headers
  custom_header: async () => {
    let userSub = '';
    try {
      const session = await Auth.currentSession();
      userSub = session.getIdToken()?.payload?.sub;
    } catch {
      // Handles "No current user" error to allow requests to unauthenticated endpoints.
    }
    return {
      'cognito-authentication-provider': 'x:x:' + userSub,
    };
  },
};

export default {
  stage: 'local',
  isProd: false,
  apiGateway: apiGatewayLocal,
  apiGateways: [apiGatewayLocal],
  cognito: {
    mandatorySignIn: true,
    region: 'us-east-1',
    userPoolId: 'us-east-1_upt87tVa2',
    identityPoolId: 'us-east-1:28db12d0-b299-4206-aa8e-60452206c467',
    userPoolWebClientId: '4s0ebo3hs5u69gah17djfh1l5t', // App client ID
  },
  users: {
    systemImportUserGuid: 'b2c02780-ef99-4292-8ec0-f1f5bef296bd',
  },
  host: {
    url: 'http://localhost:3500',
  },
  googleAnalytics: {
    trackingID: 'G-98QPXJ41NB',
  },
  flipt: {
    url: 'https://flipt.equips.com',
  },
  defaultSRDetailsForSoftwareIssues: {
    organizationId: 'b7883211c3f24929830b56568aab0ee5',
    locationId: '16314ce60137430393969e298cb95e63',
    equipmentId: 'f67fa0666fd84b57bcd6bf7b30821bdd',
  },
};
