import { Note } from '@equips/entities-schema';
import { JsonParam } from 'use-query-params';
import { TableColumn } from '../../common/components/DataTable';
import { TableFilterTypes } from '../../common/components/DataTable/TableFilters';

// These are currently only used in Task view and are not ready for full DataTable use.
export default function NoteColumns(): TableColumn<Note>[] {
  return [
    { id: 'metadata.createdAt' },
    { id: 'metadata.modifiedAt' },
    {
      id: 'metadata.createdByUserId',
      filterOptions: {
        type: TableFilterTypes.multiSelect,
        defaultValue: [],
        filterType: JsonParam,
      },
    },
    {
      id: 'metadata.modifiedByUserId',
      filterOptions: {
        type: TableFilterTypes.multiSelect,
        defaultValue: [],
        filterType: JsonParam,
      },
    },
    {
      id: 'metadata.tags',
      filterOptions: {
        type: TableFilterTypes.multiSelect,
        defaultValue: [],
        filterType: JsonParam,
      },
    },
    {
      id: 'metadata.not.tags',
      filterOptions: {
        type: TableFilterTypes.multiSelect,
        defaultValue: [],
        filterType: JsonParam,
      },
    },
    {
      id: 'metadata.parentLabel',
      filterOptions: {
        type: TableFilterTypes.multiSelect,
        defaultValue: [],
        filterType: JsonParam,
      },
    },
    { id: 'metadata.byInternalUser' },
    { id: 'organizationMetadata.clientOrganizationType' },
    {
      id: 'metadata.organizationId',
      filterOptions: {
        type: TableFilterTypes.multiSelect,
        defaultValue: [],
        filterType: JsonParam,
      },
    },
    {
      id: 'metadata.not.organizationId',
      filterOptions: {
        type: TableFilterTypes.multiSelect,
        defaultValue: [],
        filterType: JsonParam,
      },
    },
  ];
}
