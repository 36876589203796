import { Note } from '@equips/entities-schema';
import { JsonParam } from 'use-query-params';
import { TableFilterTypes } from '../../../common/components/DataTable/TableFilters';
import { TableColumn } from '../../../common/components/DataTable/types';

// These are currently only used in Task view and are not ready for full DataTable use.
export default function CaptureColumns(): TableColumn<Note>[] {
  return [
    { id: 'metadata.createdAt' },
    { id: 'metadata.modifiedAt' },
    { id: 'metadata.deactivatedAt' },
    {
      id: 'metadata.createdByUserId',
      filterOptions: {
        type: TableFilterTypes.multiSelect,
        defaultValue: [],
        filterType: JsonParam,
      },
    },
    {
      id: 'metadata.modifiedByUserId',
      filterOptions: {
        type: TableFilterTypes.multiSelect,
        defaultValue: [],
        filterType: JsonParam,
      },
    },
    {
      id: 'metadata.deactivatedByUserId',
      filterOptions: {
        type: TableFilterTypes.multiSelect,
        defaultValue: [],
        filterType: JsonParam,
      },
    },
    { id: 'metadata.equipmentExists' },
    { id: 'metadata.equipmentId' },
    { id: 'metadata.organizationId' },
    { id: 'metadata.shortId' },
  ];
}
