import { Capture, Incident, Note, ServiceRequest } from '@equips/entities-schema';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Alert, AppBar, Box, IconButton, Tab, Toolbar, Tooltip } from '@mui/material';
import { createContext, useContext, useState } from 'react';
import { useTask } from '../../screens/tasks/hooks/useTask';
import { Task } from '../../screens/tasks/libs/interfaces';
import EmptyComponent from './EmptyComponent';
import { InAppTwoColumnContainer } from './InAppTwoColumnContainer';
import LoaderCards from './Loaders/LoaderCards';

interface QueueState {
  activeTab: number;
  activeTaskItem?: ServiceRequest | Incident | Note | Capture;
  activeTask?: Task;
  tasks: Array<Task>;
}

type QueueValue = {
  queueState: QueueState;
  mutateQueueState: (payload: Partial<QueueState>) => void;
  sidebarOpen: boolean;
  toggleSidebar: () => void;
};
const QueueContext = createContext<QueueValue | null>(null);

export function useQueueContext() {
  const context = useContext(QueueContext);

  if (!context) {
    throw new Error('No QueueContext provided');
  }

  return context;
}

const DEFAULT_STATE: QueueState = {
  activeTab: 0,
  activeTaskItem: undefined,
  activeTask: undefined,
  tasks: [],
};

export default function Queue({ tasks = [], children = null }: { tasks: Array<Task>; children: React.ReactNode }) {
  const [queueState, setQueueState] = useState<QueueState>(() => ({
    ...DEFAULT_STATE,
    activeTask: tasks[DEFAULT_STATE.activeTab],
    tasks,
  }));
  const mutateQueueState = (payload: Partial<QueueState>) =>
    setQueueState((currentState) => ({
      ...currentState,
      ...payload,
    }));

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  return <QueueContext.Provider value={{ queueState, mutateQueueState, sidebarOpen, toggleSidebar }}>{children}</QueueContext.Provider>;
}

export function QueueHeader({
  title = 'Toolbar Title',
  toolbarActions = 'Toolbar Actions',
}: {
  title: React.ReactNode;
  toolbarActions: React.ReactNode;
}) {
  const { sidebarOpen, toggleSidebar } = useQueueContext();

  return (
    <AppBar className="z-10" position="sticky" color="transparent" sx={{ boxShadow: 1 }}>
      <Toolbar variant="dense">
        <div className={`flex justify-between`}>
          {/* <div className={`flex justify-between ${!sidebarOpen ? 'min-w-[72px]' : 'min-w-[360px]'}`}> */}
          <div className="flex items-center justify-between gap-2">
            {!sidebarOpen ? (
              <Tooltip title="Expand">
                <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={toggleSidebar}>
                  <MenuIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <>
                <Tooltip title="Collapse">
                  <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={toggleSidebar}>
                    <MenuOpenIcon />
                  </IconButton>
                </Tooltip>
                <h2 className="text-2xl font-semibold tracking-wider">{title}</h2>
              </>
            )}
          </div>
          {/* <Divider className="border-r-2 border-gray-400" orientation="vertical" variant="middle" flexItem /> */}
        </div>

        <div className="ml-3">{toolbarActions}</div>
      </Toolbar>
    </AppBar>
  );
}

export function QueueFeed({
  renderItem,
  disableRefetchInterval = false,
  disableRefetchOnWindowFocus = false,
}: {
  renderItem: ({ item, activeTaskItem, activeTask, sidebarOpen }) => React.ReactNode;
  disableRefetchInterval?: boolean;
  disableRefetchOnWindowFocus?: boolean;
}) {
  const { queueState, mutateQueueState, sidebarOpen } = useQueueContext();
  const { activeTask, activeTaskItem } = queueState;

  const taskData = useTask(activeTask, {
    enabled: Boolean(activeTask),
    ...(disableRefetchInterval ? { refetchInterval: false } : {}),
    ...(disableRefetchOnWindowFocus ? { refetchOnWindowFocus: false } : {}),
  });

  if (!taskData || !taskData.length) {
    return <EmptyComponent text="No Data" className="min-h-screen content-center" />;
  }

  function handleClick(newTaskItem) {
    mutateQueueState({
      activeTaskItem: newTaskItem,
    });
  }

  return (
    <div>
      {taskData.map(({ data, status }, idx) => (
        <div key={idx}>
          {status === 'loading' ? <LoaderCards childQuantity={2} parentQuantity={10} /> : null}

          {status === 'error' ? (
            <div className="p-4">
              <Alert severity="error" itemType="warning">
                Unable to load task at this time
              </Alert>
            </div>
          ) : null}

          {status === 'success' ? (
            <>
              {
                //@ts-ignore TODO: type useTasks
                data.length === 0 ? <EmptyComponent text="No Data" className="content-center" /> : null
              }
              {
                //@ts-ignore TODO: type useTasks
                data.map((item, idx) => (
                  <Box key={idx} onClick={() => handleClick(item)} className="hover:cursor-pointer">
                    {renderItem({ item, activeTaskItem, activeTask, sidebarOpen })}
                  </Box>
                ))
              }
            </>
          ) : null}
        </div>
      ))}
    </div>
  );
}

export function QueueSidebar({
  onChange,
  children,
  tabWidth = 'w-1/2',
}: {
  tabWidth?: string;
  onChange?: () => void;
  children: React.ReactNode;
}) {
  const { queueState, mutateQueueState, sidebarOpen } = useQueueContext();
  const { activeTab, tasks } = queueState;
  const tabs = tasks.map(({ taskId, name }) => ({ taskId, name }));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (activeTab === newValue) return;
    mutateQueueState({ activeTab: newValue, activeTask: tasks[newValue], activeTaskItem: undefined });

    if (onChange) {
      onChange();
    }
  };

  return (
    <div id="sidebar" className="h-[calc(100vh-108px)] overflow-y-scroll border-r-2 border-gray-400">
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={activeTab}>
          <TabList
            className="border-b-2 border-gray-400"
            onChange={handleChange}
            aria-label="scrollable tabs"
            variant="scrollable"
            orientation={!sidebarOpen ? 'vertical' : 'horizontal'}
          >
            {tabs.map((task, idx) => (
              <Tab key={task.taskId} className={tabWidth} label={task.name} value={idx} sx={{ textTransform: 'none' }} />
            ))}
          </TabList>

          {tabs.map((task, idx) => (
            <TabPanel key={task.taskId} value={idx} sx={{ p: 0 }}>
              {children}
            </TabPanel>
          ))}
        </TabContext>
      </Box>
    </div>
  );
}

interface QueueSlotProps {
  appTitle: React.ReactNode;
  toolbarActions?: React.ReactNode;
  sidebar: React.ReactNode;
}

export function QueueLayout({ slots, children, size = 'small' }: { slots: QueueSlotProps; children: React.ReactNode; size?: string }) {
  const { sidebarOpen } = useQueueContext();

  return (
    <InAppTwoColumnContainer
      size={!sidebarOpen ? 'xs' : !size ? 'small' : size}
      header={<QueueHeader title={slots.appTitle} toolbarActions={slots?.toolbarActions} />}
      sidebar={slots.sidebar}
    >
      {children}
    </InAppTwoColumnContainer>
  );
}
