import { addressFields, Maybe, OrganizationFeatureFlagEnum, queryLocations } from '@equips/entities-schema';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from '../auth/AuthContext';
import { useFliptBoolean } from '../providers/flipt/useFliptClient';

export function useTarpProvider(locationId: Maybe<string>) {
  const { auth } = useAuth();

  const organizationId = auth?.organizationId || '';

  const organizationFeatureFlags = auth?.organization?.metadata?.featureFlags || [];
  const hasTarpFeatureFlag = organizationFeatureFlags.includes(OrganizationFeatureFlagEnum.ServiceProviderNetworkTarp);
  const isTarpEnabledFlipt = useFliptBoolean('RD-451-add-tarp-to-available-providers', false, auth);

  const { data: availableProvider } = useQuery(
    ['getTarpProviderFromLocation', locationId],
    async () => {
      const data = await queryLocations(
        {
          locationId,
        },
        {
          query: `
          metadata {
            tarpProvider {
              provider {
                dispatchEmail
                dispatchPhone
              }
              metadata {
                organizationId   
                organizationName
              }
              billingAddress {
                ${addressFields}
              }
            }
          }`,
        },
      );

      return data?.data?.locations?.data?.[0] || null;
    },
    {
      enabled: !!locationId && hasTarpFeatureFlag && isTarpEnabledFlipt,
      select: (data) => data?.metadata?.tarpProvider || null,
    },
  );

  return availableProvider;
}
