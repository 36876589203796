import { EntityLabelEnum, InvoiceStatusEnumType } from '@equips/entities-schema';
import ApprovalIcon from '@mui/icons-material/Approval';
import BackHandIcon from '@mui/icons-material/BackHand';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { ButtonProps, Card, CardContent } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { lazy } from 'react';
import { Link, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { AuthorizationData, useAuth } from '../../../../common/auth/AuthContext';
import DisplayTime from '../../../../common/components/Date/DisplayTime';
import Queue, { QueueFeed, QueueLayout, QueueSidebar } from '../../../../common/components/Queue';
import { isAasOrganizationId } from '../../../../common/functions/aasHelpers';
import { useURLSearchParams } from '../../../../common/hooks/useURLSearchParams';
import { useFliptBoolean } from '../../../../common/providers/flipt/useFliptClient';
import Outlet from '../../../../routes/RouteWithSubRoutes';
import ErrorScreen from '../../../error-screen/ErrorScreen';
import InvoiceStatusTags from '../../../invoices/InvoiceStatusTags';
import { ROOT_URL } from '../../config';
import { URLS as AAS_URLS } from '../aas-adjudication/App';
import { fetchMockTasks } from './server';

const APP_TITLE = 'Invoices';

export const URLS = {
  ROOT: `${ROOT_URL}/adjudication`,
  DETAILS: `${ROOT_URL}/adjudication/details`,
};

export const ROUTES = [
  {
    key: 'adjudication',
    path: URLS.ROOT,
    component: lazy(() => import('./App')),
    routes: [
      {
        path: `${URLS.DETAILS}/:entity/:id`,
        component: lazy(() => import('./ViewInvoice')),
      },
    ],
  },
];

function WrappedSidebar() {
  const history = useHistory();

  function handleChange() {
    history.push(URLS.ROOT);
  }

  return (
    <QueueSidebar onChange={handleChange} tabWidth="w-1/3">
      <QueueFeed
        renderItem={({ item, activeTaskItem, activeTask, sidebarOpen }) => {
          const providerName = item?.metadata?.providerOrganizationName;
          return (
            <>
              {item?.__typename === 'Invoice' ? (
                <Link to={`${URLS.DETAILS}/${EntityLabelEnum.Invoice}/${item.metadata[`${EntityLabelEnum.Invoice}Id`]}`}>
                  <Card
                    className={`rounded-none border-b-2 border-gray-400 ${
                      item?.metadata?.invoiceId === activeTaskItem?.metadata?.invoiceId
                        ? 'border-2 border-gray-600 bg-gray-400 shadow-inner'
                        : ''
                    }`}
                  >
                    <CardContent>
                      <InvoiceStatusTags
                        invoiceStatus={item?.metadata?.invoiceStatus}
                        classNames={`w-fit ${!sidebarOpen ? 'text-4xs' : 'text-xs'}`}
                      />
                      <h2 className={!sidebarOpen ? 'mt-2 text-sm font-semibold' : 'mt-2 font-semibold'}>{item?.metadata?.shortId}</h2>
                      {providerName && (
                        <div className={`mb-1 text-gray-700 ${!sidebarOpen ? 'text-sm' : 'text-md'}`}>Provider: {providerName}</div>
                      )}
                      <div className={`text-gray-500 ${!sidebarOpen ? 'text-xs' : 'text-sm'}`}>
                        {activeTask?.slug === 'holds' ? (
                          <span>
                            Follow-up <DisplayTime timestamp={item?.metadata?.followUpDate} />
                          </span>
                        ) : (
                          <span>
                            Created <DisplayTime timestamp={item?.metadata?.createdAt} />
                          </span>
                        )}
                      </div>
                    </CardContent>
                  </Card>
                </Link>
              ) : null}
            </>
          );
        }}
      />
    </QueueSidebar>
  );
}

export type LocationToolbarActionState = {
  action: {
    id: string;
    payload: { invoiceStatus: InvoiceStatusEnumType };
  };
};

export function useToolbarAction() {
  const match = useRouteMatch(`${URLS.DETAILS}/:entity/:id`);
  const { entity, id } = useParams<{ entity: EntityLabelEnum; id: string }>();
  const { state = null } = useLocation<LocationToolbarActionState | null>();
  const action = state?.action;
  const param = useURLSearchParams('action');
  const isValid = !!state && !!param && match?.isExact && entity === EntityLabelEnum.Invoice && !!id;

  return { action, param, isValid };
}

export enum InvoiceActionEnum {
  Save = 'SAVE',
}

function ToolbarActions() {
  const match = useRouteMatch(`${URLS.DETAILS}/:entity/:id`);
  const { pathname } = useLocation();
  const { param, isValid: disabled } = useToolbarAction();

  const actionProps: Array<ButtonProps> = [
    {
      id: InvoiceActionEnum.Save,
      value: InvoiceActionEnum.Save,
      children: 'save',
      startIcon: <SaveIcon />,
      disabled,
    },
    {
      id: InvoiceStatusEnumType.Hold,
      value: InvoiceStatusEnumType.Hold,
      children: 'hold',
      startIcon: <BackHandIcon />,
      disabled,
    },
    {
      id: InvoiceStatusEnumType.Adjudicated,
      value: InvoiceStatusEnumType.Adjudicated,
      children: 'adjudicate',
      startIcon: <ApprovalIcon />,
      disabled,
    },
    {
      id: InvoiceStatusEnumType.Review,
      value: InvoiceStatusEnumType.Review,
      children: 'review',
      startIcon: <ChecklistRtlIcon />,
      disabled,
    },
    {
      id: InvoiceStatusEnumType.Finalized,
      value: InvoiceStatusEnumType.Finalized,
      children: 'finalize',
      startIcon: <CheckCircleIcon />,
      disabled,
    },
  ];

  const defaultButtonProps: ButtonProps = {
    className: 'capitalize disabled:cursor-not-allowed hover:bg-equipsNavyBlue hover:text-white',
    color: 'primary',
    size: 'small',
    sx: { textTransform: 'none' },
    variant: 'outlined',
  };

  return (
    <>
      {match?.isExact ? (
        <nav className="flex flex-row gap-4">
          {actionProps.map(({ disabled, ...props }) => (
            <div key={props.id}>
              {!disabled ? (
                <Link
                  to={{
                    pathname,
                    search: `?action=${props.value}`,
                    state: {
                      action: {
                        id: props.id,
                        payload: { invoiceStatus: props.value },
                      },
                    },
                  }}
                >
                  <LoadingButton {...defaultButtonProps} {...props} />
                </Link>
              ) : (
                <LoadingButton
                  {...defaultButtonProps}
                  {...props}
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  loading={param === props.id}
                  loadingPosition="start"
                  sx={{
                    cursor: 'not-allowed',
                  }}
                />
              )}
            </div>
          ))}
        </nav>
      ) : null}
    </>
  );
}

function useMockTasks() {
  const { auth } = useAuth();
  const proservTaskAdjudication = useFliptBoolean('RD-558-task-view-adjudication-proserve', false, auth);

  return useQuery({
    queryKey: ['tasks', 'adjudication', { auth }],
    queryFn: () => fetchMockTasks(auth as AuthorizationData),
    enabled: proservTaskAdjudication && !isAasOrganizationId(auth?.organizationId),
  });
}

export default function App({ routes }) {
  const { auth, permissions } = useAuth();
  const { data: tasks = [], status } = useMockTasks();
  const proservTaskAdjudication = useFliptBoolean('RD-558-task-view-adjudication-proserve', false, auth);
  const history = useHistory();

  if (isAasOrganizationId(auth?.organizationId)) {
    history.replace(AAS_URLS.ROOT);
  }

  if (!permissions.isCoverageInternalUser || !proservTaskAdjudication) {
    return <ErrorScreen />;
  }

  return (
    <>
      {status === 'success' ? (
        <Queue tasks={tasks}>
          <QueueLayout
            slots={{
              appTitle: APP_TITLE,
              toolbarActions: <ToolbarActions />,
              sidebar: <WrappedSidebar />,
            }}
          >
            <Outlet routes={routes} />
          </QueueLayout>
        </Queue>
      ) : null}
    </>
  );
}
