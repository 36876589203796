import { EntityLabelEnum } from '@equips/entities-schema';
import { getAasOrganizationId } from '../../../../common/functions/aasHelpers';
import { generateId } from '../../../../common/functions/id';
import { Task } from '../../libs/interfaces';

export const DISPATCH: Task = {
  taskId: generateId(),
  shortId: generateId(),
  name: 'Dispatch',
  slug: 'dispatch',
  description: 'Get sidebar service requests and incidents',
  feeds: [
    {
      taskFeedId: generateId(),
      shortId: generateId(),
      name: 'Preventive SR',
      slug: 'preventive-sr',
      description: 'All non preventive SR with status = equips dispatch that are not ignored or set to ignore until today',
      entity: EntityLabelEnum.ServiceRequest,
      search: `?metadata.ignoreUntil=custom%23%3A%3Atoday&metadata.requestStatus=%5B"equipsDispatch"%5D&metadata.serviceType=%5B"Consumables"%2C"Corrective"%2C"Other"%5D&metadata.not.organizationId=%5B"${getAasOrganizationId()}"%5D`,
    },
    {
      taskFeedId: generateId(),
      shortId: generateId(),
      name: 'Cancellation requests',
      slug: 'cancellation-requests',
      description: 'All cancellation request after Monday November 14, 2022 16:43:25 CST',
      entity: EntityLabelEnum.ServiceRequest,
      search: `?metadata.cancelationRequested=true&metadata.createdAt=custom%232022-11-14%3A%3A&metadata.requestStatus=%5B"equipsDispatch"%2C"providerDispatch"%2C"canceled"%5D&metadata.not.organizationId=%5B"${getAasOrganizationId()}"%5D`,
    },
    {
      taskFeedId: generateId(),
      shortId: generateId(),
      name: 'Escalated SR',
      slug: 'escalated-sr',
      description: 'All escalated SR',
      entity: EntityLabelEnum.ServiceRequest,
      search: `?metadata.requestStatus=%5B"equipsDispatch"%2C"providerDispatch"%5D&metadata.severity=escalated&metadata.not.organizationId=%5B"${getAasOrganizationId()}"%5D`,
    },
    {
      taskFeedId: generateId(),
      shortId: generateId(),
      name: 'PM scheduled today',
      slug: 'pm-scheduled-today',
      description: 'All PM that are scheduled today with status = equips dispatch that are not ignored or set to ignore until today',
      entity: EntityLabelEnum.ServiceRequest,
      search: `?metadata.ignoreUntil=custom%23%3A%3Atoday&metadata.requestStatus=%5B"equipsDispatch"%5D&metadata.scheduledDate=custom%23%3A%3Atoday&metadata.serviceType=%5B"Preventive"%5D&metadata.not.organizationId=%5B"${getAasOrganizationId()}"%5D`,
    },
    {
      taskFeedId: generateId(),
      shortId: generateId(),
      name: 'Potentially chronic',
      slug: 'potentially-chronic',
      description: 'Potentially chronic',
      entity: EntityLabelEnum.ServiceRequest,
      search:
        '?metadata.potentiallyChronic=true&metadata.requestStatus=%5B"equipsDispatch"%2C"providerDispatch"%5D&metadata.tags=Potentially%20Chronic',
    },
    // TODO: See dispatchQueries.ts
    // {
    //   taskFeedId: generateId(),
    //   shortId: generateId(),
    //   name: 'Incidents',
    //   slug: 'incidents',
    //   description:
    //     'All incidents that are not set to be ignored or ignoredUntil today, not ended, does not have SR, does not have fixes, created after Saturday October 31, 2020 23:00:00 CST',
    //   entity: EntityLabelEnum.Incident,
    //   search:
    //     '?metadata.createdAt%3Dcustom%25232020-10-31%253A%253A%26metadata.endedAt%3Dfalse%26metadata.fixes%3Dfalse%26metadata.ignoreUntil%3Dcustom%2523%253A%253Atoday',
    // },
  ],
};

export const NOTES: Task = {
  taskId: generateId(),
  shortId: generateId(),
  name: 'Notes',
  slug: 'notes',
  description: 'Get sidebar notes',
  feeds: [
    {
      taskFeedId: generateId(),
      shortId: generateId(),
      name: 'Dispatch Notes',
      slug: 'dispatch-notes',
      description:
        'All notes created between 1/04/2022 to 1/20/2023 that is not by internal user, made on SR or equipment, does not have the tag handled/escalated/cancelled.',
      entity: EntityLabelEnum.Note,
      search: `?metadata.createdAt=custom#2022-01-04::2023-01-20&metadata.not.tags=%5B"handled"%2C"escalated"%2C"cancelled"%2C"onsiteCheckin"%2C"onsiteCheckout"%2C"unauthenticatedOnsiteCheckin"%2C"unauthenticatedOnsiteCheckout"%2C"arrivalWindow"%5D&metadata.parentLabel=%5B"serviceRequest"%2C"equipment"%5D&metadata.byInternalUser=false&metadata.not.organizationId=%5B"${getAasOrganizationId()}"%5D`,
    },
    {
      taskFeedId: generateId(),
      shortId: generateId(),
      name: 'Coverage Dispatch Notes',
      slug: 'coverage-dispatch-notes',
      description: 'All notes with above condition, coming from coverage clients, created after 1/20/2023.',
      entity: EntityLabelEnum.Note,
      search: `?metadata.createdAt=custom#2023-01-20::&metadata.not.tags=%5B"handled"%2C"escalated"%2C"cancelled"%2C"onsiteCheckin"%2C"onsiteCheckout"%2C"unauthenticatedOnsiteCheckin"%2C"unauthenticatedOnsiteCheckout"%2C"arrivalWindow"%5D&metadata.parentLabel=%5B"serviceRequest"%2C"equipment"%5D&metadata.byInternalUser=false&organizationMetadata.clientOrganizationType=equipsCoverage&metadata.not.organizationId=%5B"${getAasOrganizationId()}"%5D`,
    },
    {
      taskFeedId: generateId(),
      shortId: generateId(),
      name: 'QB/Autorequest Notes',
      slug: 'qb-autorequest-notes',
      description: 'All notes created by QB and autorequest.',
      entity: EntityLabelEnum.Note,
      search: `?metadata.createdAt=custom#2022-01-20::&metadata.createdByUserId=%5B"quickbooksApiUser"%2C"autorequest-service-account"%5D&metadata.not.tags=%5B"handled"%2C"escalated"%2C"cancelled"%2C"onsiteCheckin"%2C"onsiteCheckout"%2C"unauthenticatedOnsiteCheckin"%2C"unauthenticatedOnsiteCheckout"%2C"arrivalWindow"%5D&metadata.parentLabel=%5B"serviceRequest"%2C"equipment"%5D&metadata.not.organizationId=%5B"${getAasOrganizationId()}"%5D`,
    },
    {
      taskFeedId: generateId(),
      shortId: generateId(),
      name: 'Technician Notes',
      slug: 'technician-notes',
      description: 'All notes created by Technician for coverage clients.',
      entity: EntityLabelEnum.Note,
      search: `?metadata.createdAt=custom#2024-10-16::&metadata.createdByUserId=%5B"technicianUserId"%5D&metadata.not.tags=%5B"handled"%2C"escalated"%2C"cancelled"%2C"onsiteCheckin"%2C"onsiteCheckout"%2C"unauthenticatedOnsiteCheckin"%2C"unauthenticatedOnsiteCheckout"%2C"arrivalWindow"%5D&metadata.parentLabel=%5B"serviceRequest"%2C"equipment"%5D&organizationMetadata.clientOrganizationType=equipsCoverage&metadata.not.organizationId=%5B"${getAasOrganizationId()}"%5D`,
    },
  ],
};

// TODO: See dispatchQueries.ts
// const ACTION: Task = {
//   taskId: generateId(),
//   shortId: generateId(),
//   name: 'Action',
//   slug: 'action',
//   description: 'Get sidebar service requests and incidents -- action',
//   feeds: [
//     {
//       taskFeedId: generateId(),
//       shortId: 'ID_E',
//       name: 'Potentially chronic',
//       slug: 'potentially-chronic',
//       description: 'Potentially chronic',
//       entity: EntityLabelEnum.ServiceRequest,
//       search:
//         '?metadata.potentiallyChronic=true&metadata.requestStatus=%5B"equipsDispatch"%2C"providerDispatch"%5D&metadata.tags=Potentially%20Chronic',
//     },
//   ],
// };

export const MOCK_TASKS: Array<Task> = [DISPATCH, NOTES];
