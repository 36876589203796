import { CapturesFiltersInput } from '@equips/entities-schema';
import { TableFetchArgs } from '../../common/components/DataTable';
import { transformDateRangeSelectAndCustomDateIntoGraphQLQuery } from '../../common/components/Date/DateRangeFilter';

// These are currently only used in Task view and are not ready for full DataTable use.
export function getCaptureFiltersInput(filterMap: TableFetchArgs['filterMap']): CapturesFiltersInput[] {
  return [
    {
      metadata: {
        createdAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.createdAt']),
        modifiedAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.modifiedAt']),
        deactivatedAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.deactivatedAt']),
        ...(filterMap['metadata.createdByUserId']?.length
          ? { createdByUserId: [{ keyword: [{ terms: filterMap['metadata.createdByUserId'] }] }] }
          : {}),
        ...(filterMap['metadata.modifiedByUserId']?.length
          ? { modifiedByUserId: [{ keyword: [{ terms: filterMap['metadata.modifiedByUserId'] }] }] }
          : {}),
        // equipmentId and organizationId not in Capture entity, tbd
        ...(filterMap['metadata.equipmentExists']
          ? { equipmentId: { keyword: [{ exists: filterMap['metadata.equipmentExists'] === 'true' }] } }
          : {}),
        ...(filterMap['metadata.equipmentId'] ? { equipmentId: [{ keyword: [{ term: filterMap['metadata.organizationId'] }] }] } : {}),
        ...(filterMap['metadata.organizationId']
          ? { organizationId: [{ keyword: [{ term: filterMap['metadata.organizationId'] }] }] }
          : {}),
      },
    },
  ];
}
