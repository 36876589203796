import { EntityLabelEnum } from '@equips/entities-schema';
import { AuthorizationData } from '../../../../common/auth/AuthContext';
import { generateId } from '../../../../common/functions/id';
import { Task } from '../../libs/interfaces';

export async function fetchMockTasks({ userId }: AuthorizationData) {
  const newTask: Task = {
    taskId: generateId(),
    shortId: generateId(),
    name: 'New',
    slug: 'new',
    description: 'Get all invoices created by the user, new and in progress--oldest one appears first.',
    feeds: [
      {
        taskFeedId: generateId(),
        shortId: generateId(),
        name: '',
        slug: '',
        description: '',
        entity: EntityLabelEnum.Invoice,
        search: `?metadata.createdByUserId=${userId}&metadata.invoiceStatus=["NEW","APPROVED"]&sortBy=[{"id":"metadata.createdAt","desc":false}]&pageSize=100`,
      },
    ],
  };

  const holdsTask: Task = {
    taskId: generateId(),
    shortId: generateId(),
    name: 'Holds',
    slug: 'holds',
    description: 'Get all invoices assigned to the user, on hold--oldest one appears first',
    feeds: [
      {
        taskFeedId: generateId(),
        shortId: generateId(),
        name: '',
        slug: '',
        description: '',
        entity: EntityLabelEnum.Invoice,
        search: `?metadata.assigneeUserId=${userId}&metadata.invoiceStatus=["HOLD"]&sortBy=[{"id":"metadata.providerOrganizationName","desc":false},{"id":"metadata.createdAt","desc":false}]&pageSize=100`,
      },
    ],
  };

  const [jackie, linda, maria] =
    window.process.env.stage === 'prod'
      ? ['a3966511-554b-44a7-b05a-897e7c295b1c', 'c6702f63-2a0a-405d-8544-a16a320b5be3', 'dd0f16e4-0d21-4e77-b678-e94ce4ace306']
      : ['b0718336-74e7-4988-9309-fb2982a0d816', 'f824ca6f-e208-4837-baa5-aa6934c85195', '7cb96ac3-54d5-4b5c-99cb-9a1d33cee1d4'];

  const externalTask: Task = {
    taskId: generateId(),
    shortId: generateId(),
    name: 'External',
    slug: 'external',
    description: 'Get all invoices created by users outside of the invoicing team (e.g. customers, AMs)--oldest one appears first',
    feeds: [
      {
        taskFeedId: generateId(),
        shortId: generateId(),
        name: '',
        slug: '',
        description: '',
        entity: EntityLabelEnum.Invoice,
        search: `?metadata.invoiceStatus=["NEW","externalDataEntry"]&sortBy=[{"id":"metadata.createdAt","desc":false}]&metadata.not.createdByUserId=%5B"${jackie}","${linda}","${maria}"%5D&pageSize=100`,
      },
    ],
  };

  return await Promise.resolve([newTask, holdsTask, externalTask]);
}
