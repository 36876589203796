import { Maybe } from '@equips/entities-schema';
import { notEmpty } from '@equips/common-resources';

export function displayEquipmentOnOneLine(
  equipment?: Maybe<{
    specMetadata?: Maybe<{ model?: Maybe<string>; manufacter?: Maybe<string> }>;
    metadata?: Maybe<{ shortId?: Maybe<string>; serialNumber?: Maybe<string> }>;
  }>,
): string {
  return [
    equipment?.metadata?.shortId,
    equipment?.metadata?.serialNumber,
    equipment?.specMetadata?.model,
    equipment?.specMetadata?.manufacter,
  ]
    .filter(notEmpty)
    .join(' ');
}
