import {
  queryIncidents,
  fullIncidentsMetadataGraphqlNode,
  addressFields,
  ElasticsearchSearchTypesEnum,
  postToIncidentsEndpoint,
  gql,
  fullChangesGraphQLNode,
  Maybe,
  Incident,
  IncidentsSearchInput,
  IncidentsFiltersInput,
} from '@equips/entities-schema';
import Urls from '../../routes/Urls';
import { transformDateRangeSelectAndCustomDateIntoGraphQLQuery } from '../../common/components/Date/DateRangeFilter';
import { TableFetch, TableFetchArgs } from '../../common/components/DataTable';
import transformTableSortToGraphQLSort from '../transformTableSortToGraphQLSort';
import { buildQueryString } from '../../common/functions/buildQueryString';

export const displayAllIncidents = 'all';
export const displayIncidentsWithServiceRequests = 'hasServiceRequest';

export function getIncidentsFiltersInput(filterMap: TableFetchArgs['filterMap']): IncidentsFiltersInput[] {
  let serviceRequestFilter;
  if (filterMap['metadata.serviceRequestId'] === 'true') {
    serviceRequestFilter = { serviceRequestId: [{ keyword: [{ exists: true }] }] };
  } else if (filterMap['metadata.serviceRequestId'] === 'false') {
    serviceRequestFilter = { serviceRequestId: [{ keyword: [{ exists: false }] }] };
  } else if (filterMap['metadata.serviceRequestId']) {
    serviceRequestFilter = { serviceRequestId: [{ keyword: [{ term: filterMap['metadata.serviceRequestId'] }] }] };
  }

  return [
    {
      metadata: {
        ...(filterMap['metadata.deactivatedAt'] ? { deactivatedAt: [{ exists: true }] } : {}),
        ...(filterMap['metadata.organizationId']
          ? { organizationId: [{ keyword: [{ term: filterMap['metadata.organizationId'] }] }] }
          : {}),
        ...(filterMap['metadata.locationId'] ? { locationId: [{ keyword: [{ term: filterMap['metadata.locationId'] }] }] } : {}),
        ...(filterMap['metadata.equipmentId'] ? { equipmentId: [{ keyword: [{ term: filterMap['metadata.equipmentId'] }] }] } : {}),
        ...(filterMap['metadata.symptoms'] ? { symptoms: [{ keyword: [{ terms: filterMap['metadata.symptoms'] }] }] } : {}),
        ...(serviceRequestFilter ? serviceRequestFilter : {}),
        createdAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.createdAt']),
        modifiedAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.modifiedAt']),
        ...(filterMap['metadata.createdByUserId']
          ? { createdByUserId: [{ keyword: [{ term: filterMap['metadata.createdByUserId'] }] }] }
          : {}),
        ...(filterMap['metadata.modifiedByUserId']
          ? { modifiedByUserId: [{ keyword: [{ term: filterMap['metadata.modifiedByUserId'] }] }] }
          : {}),
      },
    },
  ];
}

function buildVariablesForTableQuery(
  sortBy,
  globalFilter = '',
  pageSize = 10,
  pageIndex = 0,
  filterMap,
  includeTotalCount = false,
): IncidentsSearchInput {
  const search: IncidentsSearchInput = {
    q: globalFilter,
    size: pageSize,
    from: pageSize * pageIndex,
    searchType: ElasticsearchSearchTypesEnum.PhrasePrefix,
    sortBy: transformTableSortToGraphQLSort(sortBy),
    includeTotalCount,
    filters: getIncidentsFiltersInput(filterMap),
  };

  return search;
}

export function buildIncidentQueryString(args): string {
  return buildQueryString(Urls.INCIDENTS, args);
}

export async function getIncidentsForMainTableCharts({ filterMap }) {
  const query = `
    metadata { createdAt symptoms }
  `;

  const sortBy = [{ id: 'metadata.createdAt', desc: false }];
  const globalFilter = '';
  const pageSize = 100;
  const pageIndex = 0;
  const includeTotalCount = true;

  const search = buildVariablesForTableQuery(sortBy, globalFilter, pageSize, pageIndex, filterMap, includeTotalCount);

  return queryIncidents({ search }, { query });
}

export const getIncidentsForMainTable: TableFetch<Maybe<Incident>> = async ({
  sortBy,
  globalFilter = '',
  pageSize = 10,
  pageIndex = 0,
  filterMap,
  includeTotalCount = false,
}) => {
  const query = `
    ${fullIncidentsMetadataGraphqlNode}
    metadata {
      modifiedByUser {
        metadata {
          fullName
        }
      }
      createdByUser {
        metadata {
          fullName
        }
      }
      serviceRequest {
          metadata {
              shortId
          }
      }
      organization {
          metadata {
              organizationName
          }
      }
      equipment {
          metadata {
              shortId
              serialNumber
          }
          specMetadata {
              model
              manufacturer
          }
      }
      location {
          address {
              ${addressFields}
          }
      }
    }
  `;

  const search = buildVariablesForTableQuery(sortBy, globalFilter, pageSize, pageIndex, filterMap, includeTotalCount);

  const { data } = await queryIncidents({ search }, { query });

  return { totalCount: data?.incidents?.totalCount, data: data?.incidents?.data ?? [] };
};

export const getIncidentChanges = async (incidentId) =>
  postToIncidentsEndpoint({
    query: gql`
      query($incidentId: String!) {
        incidents(incidentId: $incidentId) {
          data {
            ${fullChangesGraphQLNode}
          }
        }
      }
    `,
    variables: { incidentId },
  });

export const getIncidentAndServiceRequestForIHTInvoicePage = async ({ incidentId }) =>
  queryIncidents(
    {
      search: {
        size: 1,
        filters: [
          {
            metadata: {
              incidentId: [{ keyword: [{ term: incidentId }] }],
            },
          },
        ],
      },
    },
    {
      query: `
      metadata {
        incidentId
        serviceRequest {
            metadata {
                serviceRequestId
            }
        }
      }
  `,
    },
  );

export const getIncidentsForChargeEntryScreen = async ({ nonNullIncidentIds }) =>
  await queryIncidents(
    {
      search: {
        filters: [{ metadata: { incidentId: [{ keyword: [{ terms: nonNullIncidentIds }] }] } }],
      },
    },
    {
      query: `
    metadata {
      incidentId
      createdAt
      equipmentId
      fixes
      equipment {
        metadata {
          deactivatedAt
          shortId
        }
        coverages {
          metadata {
            coverageId
            coverageStatus
            onDate
            offDate
          }
        }
      }
      organization {
        metadata {
          organizationName
        }
      }
      serviceRequestId
      serviceRequest {
        metadata {
          title
          requestStatus
          serviceType
          shortId
          additionalPmCompleted
        }
      }
    }`,
    },
  );
