import { InvoiceStatusEnumType, Maybe } from '@equips/entities-schema';
import { getEnumDisplayName } from '../../graphql/enums';
import { invoiceStatuses } from './invoiceStatuses';

const InvoiceStatusTags = ({ invoiceStatus, classNames = '' }: { invoiceStatus: Maybe<InvoiceStatusEnumType>; classNames?: string }) => {
  let tagClassNames;

  switch (invoiceStatus) {
    case InvoiceStatusEnumType.Adjudicated:
      tagClassNames = 'text-white bg-purple-700';
      break;
    case InvoiceStatusEnumType.Approved:
      tagClassNames = 'text-white bg-green-700';
      break;
    case InvoiceStatusEnumType.Review:
      tagClassNames = 'text-white bg-blue-700';
      break;
    case InvoiceStatusEnumType.New:
      tagClassNames = 'text-blue-700 border border-blue-700 bg-white';
      break;
    case InvoiceStatusEnumType.Hold:
      tagClassNames = 'text-white bg-yellow-700';
      break;
    case InvoiceStatusEnumType.ExternalDataEntry:
      tagClassNames = 'text-yellow-700 border border-yellow-700 bg-white';
      break;
    case InvoiceStatusEnumType.ReadyForAdjudication:
      tagClassNames = 'text-purple-700 border border-purple-700 bg-white';
      break;
    case InvoiceStatusEnumType.Finalized:
      tagClassNames = 'text-white bg-pink-700';
      break;
    case InvoiceStatusEnumType.AasFinalized:
      tagClassNames = 'text-white bg-pink-700';
      break;
    case InvoiceStatusEnumType.Denied:
      tagClassNames = 'border border-red-400 bg-red-200 text-red-700';
      break;
    case InvoiceStatusEnumType.Imported:
    default:
      tagClassNames = 'text-white bg-red-700';
  }

  return (
    <div className="text-sm">
      <div className={`mr-2 rounded-lg px-4 py-1 uppercase tracking-wide md:mr-4 ${tagClassNames} ${classNames}`}>
        {getEnumDisplayName(invoiceStatus, invoiceStatuses)}
      </div>
    </div>
  );
};

export default InvoiceStatusTags;
