import {
  ClientOrganizationEnumType,
  EntityLabelEnum,
  Equipment,
  GraphQLPatchOutputType,
  Maybe,
  NoteAuthorizationLevel,
  OrganizationTagsEnum,
  postNote,
  postOrganization,
  ServiceRequest,
  ServiceRequestsMutationPatchArgs,
  ServiceRequestStatusType,
} from '@equips/entities-schema';
import { Alert } from '@mui/material';
import { UseMutateFunction, useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useAuth } from '../../../../common/auth/AuthContext';
import { AlertTypes, useAlert } from '../../../../common/components/Alerts/AlertContext';
import Button from '../../../../common/components/Buttons/Button';
import UnderlinedButton from '../../../../common/components/Buttons/UnderlinedButton';
import FormFields, { fieldTypes } from '../../../../common/components/Form/FormFields';
import FormGroup from '../../../../common/components/Form/FormGroup';
import Label from '../../../../common/components/Form/Label';
import Textarea from '../../../../common/components/Form/Textarea';
import { OrganizationSelectOption, OrganizationSelectOptionType } from '../../../../common/components/Selects/OrganizationsSelect';
import { hasAasTagOrOrganizationId } from '../../../../common/functions/aasHelpers';
import { getDomainFromEmailAddress } from '../../../../common/functions/isPublicEmail';
import validateEmail from '../../../../common/functions/validateEmail';
import { useDuplicateProviderChecker } from '../../../../common/hooks/useDuplicateProviderChecker';
import { getOrganizationById } from '../../../../graphql/queries/organizationGraphQLQueries';
import { paymentPreferenceEnum, paymentPreferenceOptions, providerSourceOptions } from '../../../aas/organizations/AasCreateEditProvider';
import CoreServiceProviderSelect, {
  CoreServiceProviderSelectOptionsEnum,
} from '../../../organizations/components/CoreServiceProviderSelect';

const defaultSelectedProvider = {
  value: '',
  label: '',
  address: {},
  provider: {},
};

export default function RedispatchModal({
  serviceRequest,
  equipment,
  clientOrganizationType,
  organizationTags,
  patchServiceRequest,
  loadingSRPatch,
  locationId,
}: {
  serviceRequest: Maybe<ServiceRequest>;
  equipment: Maybe<Equipment>;
  clientOrganizationType: Maybe<ClientOrganizationEnumType>;
  organizationTags: Maybe<OrganizationTagsEnum>[];
  patchServiceRequest: UseMutateFunction<GraphQLPatchOutputType<ServiceRequest>, unknown, ServiceRequestsMutationPatchArgs, unknown>;
  loadingSRPatch: boolean;
  locationId?: Maybe<string>;
}) {
  const showAlert = useAlert();
  const [selectedProvider, setSelectedProvider] = useState<OrganizationSelectOptionType>(defaultSelectedProvider);

  const [showPhoneDispatchNoteScreen, setShowPhoneDispatchNoteScreen] = useState(false);
  const [addNewProvider, setAddNewProvider] = useState(false);
  const [newProvider, setNewProvider] = useState({
    name: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    paymentPreference: null,
    providerSource: null,
  });
  const [note, setNote] = useState('');
  const { auth, isAasUser } = useAuth();
  const { isDuplicateProvider, checkAndSetDuplicateProvider } = useDuplicateProviderChecker();

  const selectedProviderId = selectedProvider?.value;
  const canBePhoneDispatched = addNewProvider ? !!newProvider.phone : !!selectedProvider?.provider?.dispatchPhone;
  const canBeEmailDispatched = addNewProvider ? !!newProvider.email : !!selectedProvider?.provider?.dispatchEmail;
  const canBeDispatched = addNewProvider ? !!newProvider.name : !!selectedProvider?.provider;

  const serviceRequestId = serviceRequest?.metadata?.serviceRequestId ?? '';

  const currentProvider = serviceRequest?.metadata?.requestedProvider;

  const { data: providerExtraData } = useQuery(
    ['getDispatchEntityDetails', selectedProviderId],
    () => getOrganizationById({ organizationId: selectedProviderId }),
    {
      enabled: !!selectedProviderId,
      select: (data) => data?.data?.organizations?.data?.[0],
    },
  );

  const providerDispatchNote = providerExtraData?.dispatchNote?.metadata?.message;
  const providerIsAcceptingDispatch = providerExtraData?.metadata?.autoForwardAllDispatchRequests === false;

  const possibleProviderIds = (equipment?.metadata?.possibleProviderIds as string[]) ?? [];

  const { mutate: addNote, isLoading: savingNote } = useMutation(async () => {
    await postNote({
      metadata: {
        parentId: serviceRequestId,
        parentLabel: EntityLabelEnum.ServiceRequest,
        authorizationLevel: NoteAuthorizationLevel.All,
        message: note,
      },
    });
  });

  async function dispatchSR() {
    try {
      let selectedProviderId = selectedProvider?.value || undefined;

      if (addNewProvider) {
        const provider = await postOrganization({
          metadata: {
            organizationName: newProvider.name,
            urlDomain: newProvider.email ? getDomainFromEmailAddress(newProvider.email) : '',
            autoForwardAllDispatchRequests: false,
            ...((hasAasTagOrOrganizationId(auth) || isAasUser) && { tags: [OrganizationTagsEnum.Aas] }),
          },
          provider: {
            isExternalProvider: true,
            dispatchEmail: newProvider.email,
            dispatchPhone: newProvider.phone,
            paymentPreference: newProvider?.paymentPreference ? newProvider?.paymentPreference : null,
            providerSource: newProvider?.providerSource ? newProvider?.providerSource : null,
          },
          billingAddress: {
            line1: newProvider.address,
            city: newProvider.city,
            stateUnabbreviated: newProvider.state,
            zip: newProvider.zip,
          },
        });

        selectedProviderId = provider.data?.post?.organizationId ?? undefined;
      }

      if (!selectedProviderId) {
        throw Error('Missing provider id');
      }

      patchServiceRequest({
        serviceRequestId,
        metadata: {
          ...(serviceRequest?.metadata?.potentiallyChronic
            ? { forceRequestStatus: ServiceRequestStatusType.ProviderDispatch }
            : { requestStatus: ServiceRequestStatusType.ProviderDispatch }),
          requestedProviderId: selectedProviderId,
        },
      });
    } catch (error) {
      console.error(error);
      showAlert({ type: AlertTypes.error, content: 'Error redispatching service request' });
    }
  }

  const fields = [
    {
      label: 'Service Provider Name',
      id: 'name',
      value: newProvider.name,
      placeholder: ' ',
      required: true,
      ...(isAasUser && {
        warning: isDuplicateProvider.organizationName ? 'It looks like a Provider with that name already exists' : undefined,
        duplicateCheckerFunction: ({ value }) => checkAndSetDuplicateProvider('organizationName', value),
      }),
    },
    {
      label: 'Service Provider Phone',
      id: 'phone',
      value: newProvider.phone,
      required: true,
      placeholder: ' ',
      type: fieldTypes.PHONE,
      ...(isAasUser && {
        warning: isDuplicateProvider.dispatchPhone ? 'It looks like a Provider with that phone already exists' : undefined,
        duplicateCheckerFunction: ({ value }) => checkAndSetDuplicateProvider('dispatchPhone', value),
      }),
    },
    {
      label: 'Service Provider Payment Preference',
      id: 'paymentPreference',
      value: paymentPreferenceOptions.filter((option) => newProvider.paymentPreference === option.value),
      required: true,
      type: fieldTypes.SIMPLE_SELECT,
      placeholder: 'Select payment preference',
      options: paymentPreferenceOptions,
    },
    {
      label: 'Service Provider Source',
      id: 'providerSource',
      value: providerSourceOptions.filter((option) => newProvider.providerSource === option.value),
      type: fieldTypes.SELECT,
      placeholder: 'Select provider source',
      isHalf: false,
      options: providerSourceOptions,
      isClearable: true,
    },
    {
      label: 'Service Provider Email',
      id: 'email',
      value: newProvider.email,
      placeholder: ' ',
      rule: 'Please enter a valid email address',
      valid: newProvider.email ? validateEmail(newProvider.email) : null,
      ...(isAasUser && {
        warning: isDuplicateProvider.dispatchEmail ? 'It looks like a Provider with that email already exists' : undefined,
        duplicateCheckerFunction: ({ value }) => checkAndSetDuplicateProvider('dispatchEmail', value),
      }),
      required: newProvider.paymentPreference === paymentPreferenceEnum?.creditCard?.value,
    },
    { label: "Provider's Address", id: 'address', value: newProvider.address, placeholder: ' ', required: true },
    { label: 'City', id: 'city', value: newProvider.city, placeholder: ' ', required: true },
    {
      label: 'State',
      id: 'state',
      value: newProvider.state || '',
      type: fieldTypes.STATE_SELECT,
      isHalf: true,
      required: true,
    },
    { label: 'Zip Code', id: 'zip', value: newProvider.zip, placeholder: ' ', isHalf: true, required: true },
  ];

  return (
    <>
      <div className="border-b border-gray-300 pt-4" />
      <form
        className="relative pt-4"
        onSubmit={async (event) => {
          event.preventDefault();
          await dispatchSR();
        }}
      >
        <p className="mb-2 text-sm font-semibold  text-gray-700">Currently dispatched to</p>
        <OrganizationSelectOption data={currentProvider} hoverStyle={false} />

        <div className="mt-4">
          <CoreServiceProviderSelect
            serviceRequestId={serviceRequestId}
            locationId={locationId}
            selectedProviderId={currentProvider?.metadata?.organizationId ?? ''}
            onCardChange={(value) => setAddNewProvider(value === CoreServiceProviderSelectOptionsEnum.NewProvider)}
            onProviderChange={(organization) => setSelectedProvider(organization)}
            providerOptions={{ possibleProviderIds, organizationTags, label: 'Where should we dispatch instead?' }}
            aasOptions={{ message: 'No providers found' }}
            form={
              <>
                {clientOrganizationType !== ClientOrganizationEnumType.EquipsCoverage && (
                  <Alert severity="info">
                    {isAasUser
                      ? 'Create a new provider or add a Thumbtack provider not in the list.'
                      : 'My preferred provider is not on the list. I would like to add my own provider.'}
                  </Alert>
                )}

                <FormFields
                  handleChange={({ target: { id, value } }) => {
                    setNewProvider((state) => {
                      return { ...state, [id]: value };
                    });
                  }}
                  fields={[
                    {
                      fields: fields,
                    },
                  ]}
                />
              </>
            }
          />
        </div>

        {!isAasUser && selectedProviderId && !providerIsAcceptingDispatch && providerExtraData && (
          <div className="text-sm text-gray-700">
            This provider is currently not accepting dispatch. Select another provider to dispatch to.
          </div>
        )}

        {selectedProviderId && (
          <>
            {providerDispatchNote && (
              <div className="w-full py-2 text-sm text-gray-800">
                <span className="font-semibold">Dispatch note:</span> {providerDispatchNote}
              </div>
            )}
          </>
        )}
        {!showPhoneDispatchNoteScreen && (
          <div className="flex items-center justify-between py-2">
            {isAasUser ? (
              <Button className="ml-auto block rounded-r-none" loading={loadingSRPatch} disabled={!canBeDispatched} blue type="submit">
                Update Provider
              </Button>
            ) : (
              <>
                <UnderlinedButton disabled={!canBePhoneDispatched} type="button" onClick={() => setShowPhoneDispatchNoteScreen(true)}>
                  Phone dispatch
                </UnderlinedButton>
                <Button className="ml-6 rounded-r-none" loading={loadingSRPatch} disabled={!canBeEmailDispatched} blue type="submit">
                  Email dispatch
                </Button>
              </>
            )}
          </div>
        )}
      </form>
      {showPhoneDispatchNoteScreen && (
        <div className="mt-1">
          <form
            className="bg-gray-200 px-4"
            onSubmit={async (event) => {
              event.preventDefault();
              await addNote();
              await dispatchSR();
            }}
          >
            <FormGroup fullWidth>
              <Label label="Phone call notes" id="phoneDispatchNotes" />
              <Textarea id="phoneDispatchNotes" required value={note} onChange={(event) => setNote(event.target.value)} />
            </FormGroup>
            <div className="flex justify-end py-2">
              <Button loading={savingNote} blue type="submit">
                Dispatch
              </Button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
