import { EntityLabelEnum } from '@equips/entities-schema';
import { useQuery } from '@tanstack/react-query';
import { lazy } from 'react';
import { useHistory } from 'react-router-dom';
import QueueProvider, { QueueFeed, QueueLayout, QueueSidebar } from '../../../../common/components/Queue';
import Outlet from '../../../../routes/RouteWithSubRoutes';
import { IncidentItem, NoteItem, RequestItem } from '../../../dispatch/DispatchItems';
import { ROOT_URL } from '../../config';
import { MOCK_TASKS } from './server';

const APP_TITLE = 'Dispatch';

export const URLS = {
  ROOT: `${ROOT_URL}/dispatch`,
  DETAILS: `${ROOT_URL}/dispatch/details`,
};

export const ROUTES = [
  {
    path: URLS.ROOT,
    component: lazy(() => import('./App')),
    routes: [
      {
        path: `${URLS.DETAILS}/:entity/:id`,
        component: lazy(() => import('./view-task/ViewTask')),
      },
    ],
  },
];

function WrappedSidebar() {
  const history = useHistory();

  function handleChange() {
    history.push(URLS.ROOT);
  }

  return (
    <QueueSidebar onChange={handleChange}>
      <QueueFeed
        renderItem={({ item, activeTaskItem }) => (
          <>
            {item?.__typename === 'ServiceRequest' ? (
              <RequestItem
                key={item?.metadata?.serviceRequestId}
                serviceRequest={item}
                active={item?.metadata?.serviceRequestId === activeTaskItem?.metadata?.serviceRequestId}
                onClick={() => {
                  history.push(`${URLS.DETAILS}/${EntityLabelEnum.ServiceRequest}/${item.metadata[`${EntityLabelEnum.ServiceRequest}Id`]}`);
                }}
              />
            ) : null}

            {item?.__typename === 'Incident' ? (
              <IncidentItem
                key={item?.metadata?.incidentId}
                incident={item}
                active={item?.metadata?.incidentId === activeTaskItem?.metadata?.incidentId}
                onClick={() => {
                  history.push(`${URLS.DETAILS}/${EntityLabelEnum.Incident}/${item.metadata[`${EntityLabelEnum.Incident}Id`]}`);
                }}
              />
            ) : null}

            {item?.__typename === 'Note' ? (
              <NoteItem
                key={item?.metadata?.noteId}
                note={item}
                active={item?.metadata?.noteId === activeTaskItem?.metadata?.noteId}
                onClick={() => {
                  history.push(`${URLS.DETAILS}/${EntityLabelEnum.Note}/${item.metadata[`${EntityLabelEnum.Note}Id`]}`);
                }}
              />
            ) : null}
          </>
        )}
      />
    </QueueSidebar>
  );
}

export default function DispatchScreen({ routes }) {
  const { data: tasks = [], status } = useQuery(['tasks', 'dispatch'], () => MOCK_TASKS);

  return (
    <>
      {status === 'success' ? (
        <QueueProvider tasks={tasks}>
          <QueueLayout
            slots={{
              appTitle: APP_TITLE,
              toolbarActions: [],
              sidebar: <WrappedSidebar />,
            }}
          >
            <Outlet routes={routes} />
          </QueueLayout>
        </QueueProvider>
      ) : null}
    </>
  );
}
