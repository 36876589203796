import { Switch, useLocation } from 'react-router-dom';
import { useAuth } from '../common/auth/AuthContext';
import { AuthenticatedRoute } from './MainRouter';

// https://v5.reactrouter.com/web/example/route-config
export default function Outlet({ routes }) {
  const { auth } = useAuth();
  const params = useLocation();

  return (
    <div>
      <Switch>
        {routes.map((route) => {
          const key = route?.key ? route.key : params.pathname;
          return <AuthenticatedRoute {...route} key={key} auth={auth} />;
        })}
      </Switch>
    </div>
  );
}
