import {
  ElasticsearchSearchTypesEnum,
  NotesSearchInput,
  ServiceRequestsQueryServiceRequestsArgs,
  ServiceTypeEnum,
  SortByElasticSearchOptions,
  ServiceRequestStatusType,
  queryServiceRequests,
  fullChangesGraphQLNode,
  RequestOriginationType,
  NotesTagsEnum,
  queryNotes,
  ServiceRequest,
  ServiceRequestsSearchInput,
  Maybe,
  NoteAuthorizationLevel,
  EquipmentStatusType,
  GraphQLQueryOutputType,
  ServiceRequestsQuery,
  EntityLabelEnum,
  ClientOrganizationEnumType,
  addressFields,
  UserAuthorizationRoleEnum,
  ServiceRequestsFiltersInput,
} from '@equips/entities-schema';
import dayjs from 'dayjs';
import { gql } from '../graphqlHelperFunctions';
import { NoteListFields, NoteListFragment } from '../../common/components/ViewPage/NoteListFragment';
import { getEnumValue, serviceRequestDispatchStatuses, escalatedSeverityNumber, enumSelectOptionToGraphQLFilter } from '../enums';
import { postToServiceRequestsEndpoint as untypedPostToServiceRequestsEndpoint } from '../postToEndpoint';
import { transformDateRangeSelectAndCustomDateIntoGraphQLQuery } from '../../common/components/Date/DateRangeFilter';
import transformTableSortToGraphQLSort from '../transformTableSortToGraphQLSort';
import { toTerms } from '../../common/functions/elasticSearchHelpers';
import { TableFetch, TableFetchArgs } from '../../common/components/DataTable';
import { transformCurrencyFilterAnnualizedCentsIntoDailyPrice } from '../../common/components/Form/CurrencyRangeFilter';
import { womStatusToAllStatusMap } from '../../screens/service-requests/businessLogic/serviceRequestStatusOptions';
import { FiltersInputType } from '../../screens/home/components/CoverageDashboardFilters';
import { categoryGraphQlNode } from './categoryQueries';

export const approvalGraphqlNode = `
approvalId
metadata {
  approvalId
  approved
  attachmentIds
  attachments {
    metadata {
      attachmentId
      location
      name
      contentType
      presignedGetUrl
    }
  }
  cost
  description
  reason
  resolvedAt
  resolvedByUser {
    metadata {
      fullName
    }
  }
  createdAt
  createdByUser {
    metadata {
      fullName
    }
  }
}`;

export const severityOptions = { any: '', escalated: 'escalated', notEscalated: 'notEscalated' };

export const getSeverityGraphQLFilter = (option) => {
  if (option === severityOptions.escalated) {
    return { severity: [{ range: { gte: escalatedSeverityNumber } }] };
  } else if (option === severityOptions.notEscalated) {
    return { severity: [{ range: { lt: escalatedSeverityNumber } }] };
  }

  return {};
};

const postToServiceRequestsEndpoint = async ({
  query,
  variables,
}: {
  query: string;
  variables: ServiceRequestsQueryServiceRequestsArgs;
}) => {
  return untypedPostToServiceRequestsEndpoint({ query, variables });
};

export const getServiceRequestById = async ({ serviceRequestId }) => queryServiceRequests({ serviceRequestId });

export const getServiceRequestsForEquipment = async ({ equipmentId, dateRange }: { equipmentId: string; dateRange?: string }) =>
  queryServiceRequests(
    {
      search: {
        size: !dateRange ? 100 : undefined,
        includeTotalCount: true,
        sortBy: [{ metadata: { createdAt: SortByElasticSearchOptions.Desc } }],
        filters: [
          {
            metadata: {
              equipmentId: [{ keyword: [{ term: equipmentId }] }],
              createdAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(dateRange),
            },
          },
        ],
      },
    },
    {
      query: `
      serviceRequestId
      metadata {
        closedAt
        completedAt
        createdAt
        ignoreUntil
        recurrenceOption
        nonCoverageInvoiceTotal
      }`,
    },
  );

export const getAllPMServiceRequestsByEquipmentId = async ({ equipmentId }) =>
  queryServiceRequests(
    {
      search: {
        size: 50,
        sortBy: [{ metadata: { createdAt: SortByElasticSearchOptions.Desc } }],
        filters: [
          {
            metadata: {
              ...(equipmentId ? { equipmentId: [{ keyword: [{ term: equipmentId }] }] } : {}),
              serviceType: [
                {
                  keyword: [{ term: ServiceTypeEnum.Preventive }],
                },
              ],
            },
          },
        ],
      },
    },
    {
      query: `
      serviceRequestId
      metadata {
        shortId
        createdAt
        closedAt
        completedAt
        scheduledDate
        recurrenceInterval
        recurrenceOption
      }
    `,
    },
  );

export const getAllServiceRequestNotCompleteOrClosed = async ({ organizationId }) =>
  queryServiceRequests(
    {
      search: {
        size: 50,
        sortBy: [{ metadata: { createdAt: SortByElasticSearchOptions.Desc } }],
        filters: [
          {
            metadata: {
              ...(organizationId ? { organizationId: [{ keyword: [{ term: organizationId }] }] } : {}),
              requestStatus: [
                {
                  keyword: [
                    { notTerm: getEnumValue(serviceRequestDispatchStatuses.closed) },
                    { notTerm: getEnumValue(serviceRequestDispatchStatuses.serviceComplete) },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
    {
      query: `
      notes {
        metadata {
          tags
          createdAt
        }
      }
      metadata {
        serviceType
        serviceRequestId
        locationId
        serviceRequestDisplayName
        assignedInternalUserId
        assignedProviderUserId
        createdAt
        location {
          metadata {
            locationDisplayName
          }
        }
      }
    `,
    },
  );

export const getServiceRequestNotes = async ({ entityId, noteSearch }: { entityId: string; noteSearch?: NotesSearchInput }) =>
  postToServiceRequestsEndpoint({
    query: gql`
      query ($serviceRequestId: String!, $noteSearch: NotesSearchInput) {
        serviceRequests(serviceRequestId: $serviceRequestId) {
          data {
            notes(search: $noteSearch) {
              ...NoteComponentData
            }
          }
        }
      }
      ${NoteListFragment.fragments.noteComponentData}
    `,
    // @ts-ignore Unknown reason. Please investigate me.
    variables: { serviceRequestId: entityId, noteSearch },
  });

/** For notes without internalNote tag */
export const getServiceRequestNoteList = async ({ entityId }: { entityId: string; noteSearch?: NotesSearchInput }) => {
  const data = await getServiceRequestNotes({
    entityId: entityId,
    noteSearch: {
      filters: [
        {
          metadata: {
            tags: [{ keyword: [{ notTerms: [NotesTagsEnum.InternalNote] }] }],
          },
        },
      ],
      sortBy: [{ metadata: { createdAt: SortByElasticSearchOptions.Asc } }],
    },
  });
  return data.data?.serviceRequests?.data?.[0]?.notes ?? [];
};

export const getServiceRequestInternalNoteList = async ({ entityId }: { entityId: string; noteSearch?: NotesSearchInput }) => {
  const data = await getServiceRequestNotes({
    entityId: entityId,
    noteSearch: {
      filters: [
        {
          metadata: {
            tags: [{ keyword: [{ terms: [NotesTagsEnum.InternalNote] }] }],
          },
        },
      ],
    },
  });
  return data.data?.serviceRequests?.data?.[0]?.notes ?? [];
};

export const getServiceRequestAllNoteList = async ({ entityId }: { entityId: string; noteSearch?: NotesSearchInput }) => {
  const data = await getServiceRequestNotes({
    entityId: entityId,
    noteSearch: {},
  });
  return data.data?.serviceRequests?.data?.[0]?.notes ?? [];
};

export const getServiceRequestWithEscalationAndSeverity = async ({ startDateTimestamp, endDateTimestamp }) =>
  postToServiceRequestsEndpoint({
    query: gql`
      query ($search: ServiceRequestsSearchInput) {
        serviceRequests(search: $search) {
          data {
            metadata {
              severity
              cancelationRequested
            }
          }
        }
      }
    `,
    variables: {
      search: {
        size: 10000,
        filters: [{ metadata: { createdAt: [{ range: { gt: startDateTimestamp, lt: endDateTimestamp } }] } }],
      },
    },
  });

export const getServiceRequestReportByProvider = async ({ startDateTimestamp, endDateTimestamp }) =>
  postToServiceRequestsEndpoint({
    query: gql`
      query ($search: ServiceRequestsSearchInput) {
        serviceRequests(search: $search) {
          data {
            metadata {
              provider {
                metadata {
                  organizationName
                  organizationId
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      search: {
        size: 10000,
        filters: [
          {
            metadata: {
              providerId: [{ keyword: [{ exists: true }] }],
              createdAt: [{ range: { gt: startDateTimestamp, lt: endDateTimestamp } }],
            },
          },
        ],
      },
    },
  });

export const getServiceRequestReportByAccount = async ({ startDateTimestamp, endDateTimestamp }) =>
  postToServiceRequestsEndpoint({
    query: gql`
      query ($search: ServiceRequestsSearchInput) {
        serviceRequests(search: $search) {
          data {
            metadata {
              organization {
                metadata {
                  organizationName
                  organizationId
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      search: {
        size: 10000,
        filters: [
          {
            metadata: {
              createdAt: [{ range: { gt: startDateTimestamp, lt: endDateTimestamp } }],
            },
          },
        ],
      },
    },
  });

export const getServiceRequestForInvoiceEntry = async ({
  q = '',
  size = 20,
  page = 1,
  organizationId,
  providerId,
  locationId,
  equipmentId,
}) =>
  queryServiceRequests(
    {
      search: {
        sortBy: q ? undefined : [{ metadata: { createdAt: SortByElasticSearchOptions.Desc } }],
        q,
        searchType: ElasticsearchSearchTypesEnum.PhrasePrefix,
        size: size * page,
        from: 0,
        filters: [
          {
            metadata: {
              ...(organizationId ? { organizationId: [{ keyword: [{ term: organizationId }] }] } : {}),
              ...(providerId ? { providerId: [{ keyword: [{ term: providerId }] }] } : {}),
              ...(locationId ? { locationId: [{ keyword: [{ term: locationId }] }] } : {}),
              ...(equipmentId ? { equipmentId: [{ keyword: [{ term: equipmentId }] }] } : {}),
            },
            organizationMetadata: {
              clientOrganizationType: [{ keyword: [{ term: ClientOrganizationEnumType.EquipsCoverage }] }],
            },
          },
        ],
      },
    },
    {
      query: `
    metadata {
      shortId
      title
      description
      serviceRequestId
      providerId
      requestStatus
      createdAt
      providerRefId
    }
    locationMetadata {
      locationDisplayName
    }
    organizationMetadata {
      organizationName
      organizationId
    }
    providerMetadata {
      organizationName
      organizationId
    }
    incidents {
      metadata {
        symptoms
        incidentId
        organizationId
        equipment {
          metadata {
            shortId
            serialNumber
            subclasses
          }
          specMetadata {
            ${categoryGraphQlNode}
            model
            manufacturer
          }
        }
      }
    }
  `,
    },
  );

type ServiceRequestTypeArgs = {
  requestStatus?: ServiceRequestStatusType[];
  serviceType?: ServiceTypeEnum[];
  searchQuery?: string;
  locationId?: string;
  organizationIds?: string[];
  equipmentId?: string;
  providerId?: string;
  showDeactivated?: boolean;
  perPage?: number;
  page?: number;
  tags?: string;
  severity?: string;
  createdAt?: string;
  createdByUserId?: string;
  scheduledDate?: string;
  modifiedAt?: string;
  modifiedByUserId?: string;
  followUpDate?: string;
  sorted?: any;
  categoryIds?: string[];
  subclasses?: string[];
  requestOrigination?: RequestOriginationType[];
  startDateTimestamp?: number;
  endDateTimestamp?: number;
  serviceRequestIds?: string[];
};

export const getNotesThatIndicateCheckedInServiceRequest = async ({ startDateTimestamp, endDateTimestamp }) =>
  queryNotes(
    {
      search: {
        size: 500,
        filters: [
          {
            metadata: {
              tags: [{ keyword: [{ terms: [NotesTagsEnum.OnsiteCheckin] }] }],
              createdAt: [{ range: { gt: startDateTimestamp, lt: endDateTimestamp } }],
            },
          },
        ],
      },
    },
    {
      query: `
        metadata {
          parentId
        }
      `,
    },
  );

export const getNotesAndCreateDateThatIndicateCheckedInServiceRequest = async ({ startDateTimestamp, endDateTimestamp }) =>
  queryNotes(
    {
      search: {
        size: 500,
        filters: [
          {
            metadata: {
              tags: [{ keyword: [{ terms: [NotesTagsEnum.OnsiteCheckin] }] }],
              createdAt: [{ range: { gt: startDateTimestamp, lt: endDateTimestamp } }],
            },
          },
        ],
      },
    },
    {
      query: `
        metadata {
          parentId
          createdAt
        }
      `,
    },
  );

export const getAllServiceRequests = async ({ startDateTimestamp, endDateTimestamp }) =>
  queryServiceRequests(
    {
      search: {
        size: 1, // This is for reporting and all we need is the total count
        filters: [
          {
            metadata: {
              createdAt: [{ range: { gt: startDateTimestamp, lt: endDateTimestamp } }],
            },
          },
        ],
      },
    },
    {
      query: `serviceRequestId`,
    },
  );

export const getServiceRequestsForHandleTimeReport = async ({ startDateTimestamp, endDateTimestamp, organizationId, locationId }) =>
  queryServiceRequests(
    {
      search: {
        filters: [
          {
            metadata: {
              requestStatus: [
                {
                  keyword: [
                    {
                      terms: [
                        ServiceRequestStatusType.ProviderDispatch,
                        ServiceRequestStatusType.Closed,
                        ServiceRequestStatusType.Invoiced,
                      ],
                    },
                  ],
                },
              ],
              createdAt: [{ range: { gt: startDateTimestamp, lt: endDateTimestamp } }],
              ...(organizationId ? { organizationId: [{ keyword: [{ term: organizationId }] }] } : {}),
              ...(locationId ? { locationId: [{ keyword: [{ term: locationId }] }] } : {}),
            },
          },
        ],
      },
    },
    {
      query: `
      metadata {
        serviceRequestId
        createdAt
        dispatchedInternallyAt
        dispatchedToEquipsAt
        dispatchedToProviderAt
      }
    `,
    },
  );

export const newGetAllCheckedInServiceRequests: TableFetch<Maybe<ServiceRequest>> = async ({
  sortBy,
  globalFilter,
  pageSize = 10,
  pageIndex = 0,
  filterMap,
  includeTotalCount = false,
}) => {
  const query = gql`
  metadata {
    createdAt
    shortId
    tags
    serviceRequestId
    requestStatus
    serviceType
    severity
    modifiedAt
    providerRefId
    title
    description
    equipmentId
    mainContactUser {
      metadata {
        fullName
      }
    }
    modifiedByUser {
      metadata {
        fullName
      }
    }
    equipment {
      specMetadata {
        ${categoryGraphQlNode}
        model
        manufacturer
      }
    }
  }
  notes {
    metadata {
      createdAt
      tags
    }
  }
  locationMetadata {
    locationName
    locationId
    locationDisplayName
  }
  organizationMetadata {
    organizationName
    organizationId
  }
  providerMetadata {
    organizationName
    organizationId
  }
  equipmentMetadata {
    serialNumber
    subclasses
    shortId
    externalId
  }
  `;

  const search: ServiceRequestsSearchInput = {
    q: globalFilter,
    size: pageSize,
    from: pageSize * pageIndex,
    searchType: ElasticsearchSearchTypesEnum.PhrasePrefix,
    sortBy: transformTableSortToGraphQLSort(sortBy),
    includeTotalCount,
    filters: filterMap
      ? [
          {
            metadata: {
              ...((filterMap['metadata.serviceRequestId']?.length || 0) > 0
                ? { serviceRequestId: [{ keyword: [{ terms: filterMap['metadata.serviceRequestId'] }] }] }
                : {}),
              ...(filterMap['metadata.deactivatedAt'] ? { deactivatedAt: [{ exists: true }] } : {}),
              createdAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.createdAt']),
              ...(filterMap['metadata.createdByUserId']
                ? { createdByUserId: [{ keyword: [{ term: filterMap['metadata.createdAt'] }] }] }
                : {}),
              modifiedAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.modifiedAt']),
              ...(filterMap['metadata.modifiedByUserId']
                ? { modifiedByUserId: [{ keyword: [{ term: filterMap['metadata.modifiedByUserId'] }] }] }
                : {}),
              ...((filterMap['metadata.organizationId']?.length || 0) > 0
                ? { organizationId: [{ keyword: [{ terms: filterMap['metadata.organizationId'] }] }] }
                : {}),
              ...(filterMap['metadata.providerId'] ? { providerId: [{ keyword: [{ term: filterMap['metadata.providerId'] }] }] } : {}),
            },
            specMetadata: {
              categoryId: toTerms<string>(filterMap['specMetadata.categoryId']),
            },
            locationMetadata: {
              ...(filterMap['locationMetadata.locationDisplayName']
                ? { locationId: [{ keyword: [{ term: filterMap['locationMetadata.locationDisplayName'] }] }] }
                : {}),
            },
          },
        ]
      : [],
  };

  const { data } = await queryServiceRequests({ search }, { query });

  return { totalCount: data?.serviceRequests?.totalCount, data: data?.serviceRequests?.data ?? [] };
};

export const getAllCheckedInServiceRequests = async ({
  searchQuery = '',
  startDateTimestamp,
  locationId,
  organizationIds,
  providerId,
  perPage = 25,
  page = 0,
  endDateTimestamp,
  serviceRequestIds,
  sorted,
  categoryIds,
}: ServiceRequestTypeArgs) =>
  queryServiceRequests(
    {
      search: {
        q: searchQuery,
        size: perPage,
        from: perPage * page,
        searchType: ElasticsearchSearchTypesEnum.PhrasePrefix,
        sortBy: transformTableSortToGraphQLSort(sorted),
        filters: [
          {
            metadata: {
              serviceRequestId: [{ keyword: [{ terms: serviceRequestIds }] }],
              createdAt: [{ range: { gt: startDateTimestamp, lt: endDateTimestamp } }],
              ...(locationId ? { locationId: [{ keyword: [{ term: locationId }] }] } : {}),
              ...((organizationIds?.length || 0) > 0 ? { organizationId: [{ keyword: [{ terms: organizationIds }] }] } : {}),
              ...(providerId ? { providerId: [{ keyword: [{ term: providerId }] }] } : {}),
            },
            specMetadata: {
              categoryId: toTerms(categoryIds),
            },
          },
        ],
      },
    },
    {
      query: `
        metadata {
          createdAt
          shortId
          tags
          serviceRequestId
          requestStatus
          serviceType
          severity
          modifiedAt
          providerRefId
          title
          description
          equipmentId
          mainContactUser {
            metadata {
              fullName
            }
          }
          modifiedByUser {
            metadata {
              fullName
            }
          }
          equipment {
            specMetadata {
              ${categoryGraphQlNode}
              model
              manufacturer
            }
          }
        }
        notes {
          metadata {
            createdAt
            tags
          }
        }
        locationMetadata {
          locationName
          locationId
          locationDisplayName
        }
        organizationMetadata {
          organizationName
          organizationId
        }
        providerMetadata {
          organizationName
          organizationId
        }
        equipmentMetadata {
          serialNumber
          subclasses
          shortId
          externalId
        }
      `,
    },
  );

export function getServiceRequestsFiltersInput(
  filterMap: TableFetchArgs['filterMap'],
  clientOrganizationType: ClientOrganizationEnumType | null = ClientOrganizationEnumType.EquipsCoverage,
) {
  let referredByOrgFilter;
  if (filterMap['referredByOrg']?.substring(0, 5) === 'false') {
    referredByOrgFilter = { referringOrganizationId: [{ keyword: [{ notTerm: filterMap['referredByOrg'].substring(5) }] }] };
  } else if (filterMap['referredByOrg']?.substring(0, 4) === 'true') {
    referredByOrgFilter = { referringOrganizationId: [{ keyword: [{ term: filterMap['referredByOrg'].substring(4) }] }] };
  }

  const locationIds = Array.isArray(filterMap['metadata.locationId'])
    ? filterMap['metadata.locationId']
    : filterMap['metadata.locationId']?.includes(',')
    ? filterMap['metadata.locationId']?.split(',')
    : [filterMap['metadata.locationId']];

  const requestStatusMapper = (statuses: string[]): ServiceRequestStatusType[] => {
    if (!statuses || statuses?.length === 0) return [];
    return Object.values(womStatusToAllStatusMap)
      .filter((womStatus) => statuses?.includes(womStatus?.womValue))
      .map((status) => status.value as ServiceRequestStatusType);
  };

  const requestStatusFilter =
    clientOrganizationType &&
    [ClientOrganizationEnumType.EquipsCoverage, ClientOrganizationEnumType.EquipsBasic].includes(clientOrganizationType)
      ? toTerms<ServiceRequestStatusType>(filterMap['metadata.requestStatus'])
      : toTerms<ServiceRequestStatusType>(requestStatusMapper(filterMap['metadata.requestStatus']));

  let approvalFilter;
  switch (filterMap['approvals']) {
    case 'open':
      approvalFilter = { approved: [{ notExists: true }] };
      break;
    case 'completed':
      approvalFilter = { approved: [{ exists: true }] };
      break;
    case 'approved':
      approvalFilter = { approved: [{ term: true }] };
      break;
    case 'denied':
      approvalFilter = { approved: [{ term: false }] };
      break;
  }

  const initFilters = [
    {
      metadata: {
        ...(filterMap['serviceRequestId'] && filterMap['serviceRequestId'].split(',').length > 0
          ? { serviceRequestId: [{ keyword: [{ terms: filterMap['serviceRequestId'].split(',') }] }] }
          : {}),
        ...(filterMap['metadata.shortId'] && filterMap['metadata.shortId'].split(',').length > 0
          ? { shortId: [{ keyword: [{ terms: filterMap['metadata.shortId'].split(',') }] }] }
          : {}),
        ...(filterMap['metadata.equipmentId'] && filterMap['metadata.equipmentId'].split(',').length > 0
          ? { equipmentId: [{ keyword: [{ terms: filterMap['metadata.equipmentId'].split(',') }] }] }
          : {}),
        ...(filterMap['metadata.upcoming'] !== 'show'
          ? { ignoreUntil: [{ range: { lte: dayjs().startOf('d').valueOf() } }, { term: null }] }
          : {}),
        ...getSeverityGraphQLFilter(filterMap['metadata.severity']),
        ...(filterMap['metadata.deactivatedAt'] ? { deactivatedAt: [{ exists: true }] } : {}),
        requestStatus: requestStatusFilter,
        serviceType: toTerms<ServiceTypeEnum>(filterMap['metadata.serviceType']),
        requestOrigination: toTerms<RequestOriginationType>(filterMap['metadata.requestOrigination']),
        ...(filterMap['metadata.locationId'] ? { locationId: [{ keyword: [{ terms: locationIds }] }] } : {}),
        size: enumSelectOptionToGraphQLFilter(filterMap['metadata.size']),
        priority: enumSelectOptionToGraphQLFilter(filterMap['metadata.priority']),
        ...((filterMap['metadata.organizationId']?.length || 0) > 0
          ? { organizationId: [{ keyword: [{ terms: filterMap['metadata.organizationId'] }] }] }
          : {}),
        ...((filterMap['metadata.not.organizationId']?.length || 0) > 0
          ? { organizationId: [{ keyword: [{ notTerms: filterMap['metadata.not.organizationId'] }] }] }
          : {}),
        providerId: enumSelectOptionToGraphQLFilter(filterMap['metadata.providerId']),
        ...(filterMap['metadata.tags'] && filterMap['metadata.tags'].split(',').length > 0
          ? { tags: [{ keyword: [{ terms: filterMap['metadata.tags'].split(',') }] }] }
          : {}),
        ...(filterMap['metadata.potentiallyChronic']
          ? { potentiallyChronic: [{ term: filterMap['metadata.potentiallyChronic'] === 'true' }] }
          : {}),
        ...(filterMap['metadata.cancelationRequested']
          ? { cancelationRequested: [{ term: filterMap['metadata.cancelationRequested'] === 'true' }] }
          : {}),
        createdAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.createdAt']),
        dueDate: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.dueDate']),
        ignoreUntil: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.ignoreUntil'], {
          filters: [{ exists: false }],
        }),
        completedAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.completedAt']),
        closedAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.closedAt']),
        ...(filterMap['metadata.assignedInternalUserId']
          ? filterMap['metadata.assignedInternalUserId'] === 'unassigned'
            ? { assignedInternalUserId: [{ keyword: [{ term: null }] }] }
            : { assignedInternalUserId: [{ keyword: [{ term: filterMap['metadata.assignedInternalUserId'] }] }] }
          : {}),
        ...(filterMap['metadata.mainContactUserId']
          ? filterMap['metadata.mainContactUserId'] === 'unassigned'
            ? { mainContactUserId: [{ keyword: [{ term: null }] }] }
            : { mainContactUserId: [{ keyword: [{ term: filterMap['metadata.mainContactUserId'] }] }] }
          : {}),
        ...(filterMap['metadata.createdByUserId']
          ? { createdByUserId: [{ keyword: [{ term: filterMap['metadata.createdByUserId'] }] }] }
          : {}),
        scheduledDate:
          filterMap['metadata.scheduledDate'] !== 'none'
            ? transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.scheduledDate'])
            : [{ exists: false }],
        ...(filterMap['metadata.recurrenceInterval'] === 'all'
          ? { recurrenceInterval: [{ terms: [90, 180, 360] }] }
          : filterMap['metadata.recurrenceInterval']
          ? { recurrenceInterval: [{ term: parseInt(filterMap['metadata.recurrenceInterval']) }] }
          : {}),
        ...(filterMap['metadata.recurrenceOption']
          ? { recurrenceOption: [{ keyword: [{ term: filterMap['metadata.recurrenceOption'] }] }] }
          : {}),
        modifiedAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.modifiedAt']),
        ...(filterMap['metadata.modifiedByUserId']
          ? { modifiedByUserId: [{ keyword: [{ term: filterMap['metadata.modifiedByUserId'] }] }] }
          : {}),
        failureDate: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.failureDate']),
        followUpDate: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.followUpDate']),
        ...(filterMap['metadata.billable'] === 'true'
          ? { billable: [{ term: true }] }
          : filterMap['metadata.billable'] === 'false'
          ? { billable: [{ term: false }, { exists: false }] }
          : {}),
        ...(filterMap['metadata.additionalPmCompleted']
          ? { additionalPmCompleted: [{ term: filterMap['metadata.additionalPmCompleted'] === 'true' }] }
          : {}),
        ...(filterMap['metadata.nonCoverageInvoiceNumber'] && filterMap['metadata.nonCoverageInvoiceNumber'] !== 'all'
          ? {
              nonCoverageInvoiceNumber: [
                { keyword: filterMap['metadata.nonCoverageInvoiceNumber'] === 'blank' ? [{ term: null }] : [{ exists: true }] },
              ],
            }
          : {}),
        ...(filterMap['metadata.nonCoverageInvoiceTotal'] && filterMap['metadata.nonCoverageInvoiceTotal'] !== 'all'
          ? { nonCoverageInvoiceTotal: [{ exists: filterMap['metadata.nonCoverageInvoiceTotal'] === 'blank' ? false : true }] }
          : {}),
        ...(filterMap['closed'] && filterMap['closed'] !== 'all'
          ? { closedAt: [{ exists: filterMap['closed'] === 'closed' ? true : false }] }
          : {}),
        poAmount: transformCurrencyFilterAnnualizedCentsIntoDailyPrice(filterMap['metadata.poAmount']),
        ...(filterMap['dispatchStatus'] && filterMap['dispatchStatus'] !== 'all'
          ? { providerId: [{ keyword: [{ exists: filterMap['dispatchStatus'] === 'dispatchedExternally' ? true : false }] }] }
          : {}),
      },
      specMetadata: {
        categoryId: toTerms<string>(filterMap['specMetadata.categoryId']),
        manufacturerEOL: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['specMetadata.manufacturerEOL']),
        vendorLimitedSupportDate: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['specMetadata.vendorLimitedSupportDate']),
        ...(filterMap['specMetadata.specId'] ? { specId: [{ keyword: [{ term: filterMap['specMetadata.specId'] }] }] } : {}),
      },
      organizationMetadata: {
        ...(filterMap['referredByOrg'] ? referredByOrgFilter : {}),
        ...(filterMap['organizationMetadata.clientOrganizationType']
          ? { clientOrganizationType: [{ keyword: [{ term: filterMap['organizationMetadata.clientOrganizationType'] }] }] }
          : {}),
        ...(filterMap['organizationMetadata.industry']
          ? { industry: [{ keyword: [{ term: filterMap['organizationMetadata.industry'] }] }] }
          : {}),
      },
      equipmentMetadata: {
        ...(filterMap['equipmentMetadata.shortId'] && filterMap['equipmentMetadata.shortId'].split(',').length > 0
          ? { shortId: [{ keyword: [{ terms: filterMap['equipmentMetadata.shortId'].split(',') }] }] }
          : {}),
        ...(filterMap['equipmentMetadata.hasChronicIssues']
          ? { hasChronicIssues: [{ term: filterMap['equipmentMetadata.hasChronicIssues'] === 'true' }] }
          : {}),
        ...(filterMap['equipmentMetadata.deactivated']
          ? { deactivatedAt: [{ exists: filterMap['equipmentMetadata.deactivated'] === 'true' }] }
          : {}),
        subclasses: toTerms<string>(filterMap['equipmentMetadata.subclasses']),
        createdAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['equipmentMetadata.createdAt']),
      },
      equipmentCoveragesMetadata: {
        ...(filterMap['coverageMetadata.coverageStatus']
          ? {
              coverageStatus: [{ keyword: [{ term: filterMap['coverageMetadata.coverageStatus'] }] }],
            }
          : {}),
      },
      tagMetadata: {
        tagId: !filterMap['tagMetadata.tagId']?.includes('none')
          ? toTerms<string>(filterMap['tagMetadata.tagId'])
          : [{ keyword: [{ exists: false }] }],
      },
      approvalMetadata: {
        ...(filterMap['approvals'] ? approvalFilter : {}),
      },
      noteMetadata: {
        ...(filterMap['note.attachment']
          ? filterMap['note.attachment'] === 'true'
            ? { attachmentIds: [{ keyword: [{ exists: true }] }] }
            : { attachmentIds: [{ keyword: [{ exists: false }] }] }
          : {}),
        ...(filterMap['note.fromExternalUser']
          ? filterMap['note.fromExternalUser'] === 'true'
            ? { tags: [{ keyword: [{ terms: [NotesTagsEnum.ExternalUser] }] }] }
            : { tags: [{ keyword: [{ notTerms: [NotesTagsEnum.ExternalUser] }] }] }
          : {}),
      },
    },
  ];

  const filters = filterMap['sublocationIds']
    ? [...initFilters, { metadata: { locationId: [{ keyword: [{ terms: filterMap['sublocationIds'] }] }] } }]
    : initFilters;

  return filters as ServiceRequestsFiltersInput[];
}

export const getServiceRequestsForMainTable = async (
  { sortBy, globalFilter = '', pageSize = 10, pageIndex = 0, filterMap, includeTotalCount = false }: TableFetchArgs,
  {
    clientOrganizationType,
  }: {
    clientOrganizationType?: Maybe<ClientOrganizationEnumType>;
    userId?: string;
    srUserVisibility?: boolean;
    authorizationRole?: UserAuthorizationRoleEnum;
  } = {},
) => {
  const query = `
  metadata {
    clientTags {
      tagId
      entityLabel
      name
      style
      organizationId
    }
    aasContractId
    aasContract{
      metadata{
        homeContractNo
      }
    }
    ignoreUntil
    shortId
    tags
    size
    priority
    dueDate
    serviceRequestId
    requestStatus
    serviceType
    severity
    cancelationRequested
    createdAt
    completedAt
    completedByUser{
      metadata{
        fullName
      }
    }
    closedAt
    closedByUser{
      metadata{
        fullName
      }
    }
    scheduledDate
    recurrenceInterval
    recurrenceOption
    requestOrigination
    failureDate
    followUpDate
    clientFollowUpDate
    modifiedAt
    providerRefId
    title
    description
    equipmentId
    dispatchedInternallyAt
    dispatchedToEquipsAt
    dispatchedToProviderAt
    potentiallyChronic
    additionalPmCompleted
    checkedInDuration
    billable
    externalWorkOrderNo
    poAmount
    nonCoverageInvoiceNumber
    nonCoverageInvoiceTotal
    createdByUserId
    variance
    providerId
    assignedInternalUser {
      metadata {
        fullName
        profilePictureUrl
      }
    }
    createdByUser {
      metadata {
        fullName
      }
    }
    modifiedByUser {
      metadata {
        fullName
      }
    }
    mainContactUser {
      metadata {
        fullName
        phoneNumberWithCountryCode
        email
      }
    }
    checklist {
      metadata {
        checklistId
        completedPercentage
      }
    }
    equipment {
      metadata{
        equipmentId
        equipmentName
        equipmentStatus
        equipmentHealth
        shortId
        serialNumber
      }
      specMetadata {
        ${categoryGraphQlNode}
        model
        manufacturer
        manufacturerEOL
        vendorLimitedSupportDate
      }
    }
    location {
      metadata {
        locationDisplayName
        parentLocation { 
          metadata {
            locationDisplayName
          }
        }
      }
      address{
        line1
        line2
        city
        stateUnabbreviated
        zip
      }
    }
    provider{
      provider{
        dispatchEmail
        dispatchPhone
      }
    }
  }
  notes(search: $noteSearch) {
    metadata {
      noteId
      message
      createdAt
      tags
    }
  }
  incidents {
    metadata {
      symptoms
    }
    charges {
      metadata {
        invoice {
          metadata {
            repairDescription
          }
        }
      }
    }
  }
  locationMetadata {
    shortId
    locationName
    locationId
    locationDisplayName
    
  }
  organizationMetadata {
    organizationName
    organizationId
    autoForwardAllDispatchRequests
    clientOrganizationType
    tier
    industry
  }
  providerMetadata {
    organizationName
    organizationId
  }
  equipmentMetadata {
    serialNumber
    subclasses
    shortId
    externalId
    hasChronicIssues
  }
  invoiceMetadata {
    invoiceId
    shortId
    invoiceTotal
    invoiceStatus
  }
`;

  const search: ServiceRequestsSearchInput = {
    q: globalFilter,
    size: pageSize,
    from: pageSize * pageIndex,
    searchType: ElasticsearchSearchTypesEnum.PhrasePrefix,
    sortBy: transformTableSortToGraphQLSort(sortBy),
    includeTotalCount,
    filters: getServiceRequestsFiltersInput(filterMap, clientOrganizationType),
  };

  const { data } = await queryServiceRequests(
    { search, noteSearch: { sortBy: [{ metadata: { createdAt: SortByElasticSearchOptions.Desc } }] } },
    { query },
  );

  return { totalCount: data?.serviceRequests?.totalCount, data: data?.serviceRequests?.data ?? [] };
};

export const getServiceRequestWithAutomatedFromQueueOriginationType = async ({ equipmentId }: { equipmentId: string }) => {
  return queryServiceRequests(
    {
      search: {
        size: 1,
        filters: [
          {
            metadata: {
              equipmentId: [{ keyword: [{ term: equipmentId }] }],
              requestOrigination: [{ keyword: [{ term: RequestOriginationType.AutomatedFromEmail }] }],
            },
          },
        ],
      },
    },
    {
      query: `serviceRequestId`,
    },
  );
};

export const getServiceRequestTimeTracking = async ({ serviceRequestId }: { serviceRequestId: string }) =>
  queryServiceRequests(
    {
      serviceRequestId,
    },
    {
      query: `
        metadata {
          actions {
            metadata {
              actionId
              startedAt
              completedAt
              status
              type
              createdByUserId
              serviceRequestId
              modifiedAt
              user {
                userId
                metadata {
                  userId
                  fullName
                  profilePicture {
                    presignedGetUrl
                  }
                }
              }
            }
          }
        }
      `,
    },
  );

export const getFullServiceRequestByEquipmentId = async ({ equipmentId }: { equipmentId: string }) => {
  return queryServiceRequests(
    {
      search: {
        filters: [{ metadata: { equipmentId: [{ keyword: [{ term: equipmentId }] }] } }],
        sortBy: [{ metadata: { createdAt: SortByElasticSearchOptions.Desc } }],
      },
      noteSearch: {
        filters: [
          {
            metadata: {
              authorizationLevel: [{ keyword: [{ terms: [NoteAuthorizationLevel.ProviderAndEquips, NoteAuthorizationLevel.All] }] }],
            },
          },
        ],
      },
    },
    {
      query: `
      metadata {
        serviceRequestId
        shortId
        title
        description
        serviceRequestDisplayName
        createdAt
        requestStatus
        checkedInDuration
        createdByUser{
          metadata{
            fullName
          }
        }
      }
      notes(search: $noteSearch) {
        metadata {
          authorizationLevel
          message
          createdAt
          createdByUser {
            metadata {
              fullName
              userId
              profilePictureUrl
            }
          }
          noteId
        }
      }
      incidents {
        metadata {
          symptoms
          incidentId
        }
      }
      `,
    },
  );
};

export const getDispatchLogicForServiceRequest = async (serviceRequestId) =>
  postToServiceRequestsEndpoint({
    query: gql`
      query ($serviceRequestId: String) {
        serviceRequests(serviceRequestId: $serviceRequestId) {
          data {
            incidents {
              metadata {
                equipment {
                  equipmentId
                }
              }
            }
            metadata {
              equipment {
                metadata {
                  provider {
                    metadata {
                      autoForwardAllDispatchRequests
                    }
                    provider {
                      dispatchEmail
                    }
                  }
                }
              }
              requestedProviderId
              requestedProvider {
                metadata {
                  autoForwardAllDispatchRequests
                }
                provider {
                  dispatchEmail
                }
              }
              organization {
                metadata {
                  autoForwardAllDispatchRequests
                }
                provider {
                  dispatchEmail
                }
              }
            }
          }
        }
      }
    `,
    variables: { serviceRequestId },
  });

export const getServiceRequestForView = ({ serviceRequestId }): Promise<GraphQLQueryOutputType<ServiceRequestsQuery>> =>
  queryServiceRequests(
    {
      serviceRequestId,
    },
    {
      query: `
      organizationMetadata {
        allowedClientTags {
          tagId
          entityLabel
          name
          organizationId
          style
        }
      }
      invoiceMetadata {
        assignee {
          metadata {
            fullName
          }
        }
        invoiceId
        shortId
        invoiceTotal
        invoiceStatus
        externalInvoiceNumber
        invoiceDisplayStatus
        quickbooksInvoice {
          DocNumber
          Id
        }
        file {
          metadata {
            presignedGetUrl
          }
        }
      }
      metadata {
        approvals {
          ${approvalGraphqlNode}
        }
        assignedSquad {
          squadId
          metadata {
            name
            description
            leaders {
              userId
            }
            members {
              userId
            }
          }
        }
        clientTags {
          tagId
          entityLabel
          name
          style
          organizationId
        }
        geolocation{
          lat
          lng
          displayName
        }
        serviceWorkflowToServiceStatusId
        serviceWorkflow {
          serviceWorkflowId
        }
        serviceStatus {
          serviceStatusId
        }
        createdAt
        scheduledDate
        recurrenceInterval
        recurrenceOption
        previousRequestId
        priority
        qrCodeId
        dueDate
        modifiedAt
        serviceRequestId
        shortId
        contacts
        failureDate
        followUpDate
        clientFollowUpDate
        ignoreUntil
        title
        description
        cancelationRequested
        requestStatus
        forceRequestStatus
        severity
        serviceType
        tags
        providerRefId
        requestOrigination
        dispatchedInternallyAt
        dispatchedToEquipsAt
        dispatchedToProviderAt
        potentiallyChronic
        billingStatus
        dispatchEmail
        additionalPmCompleted
        checkedInDuration
        billable
        externalWorkOrderNo
        poAmount
        nonCoverageInvoiceNumber
        nonCoverageInvoiceTotal
        assignedInternalUserId
        variance
        assignedInternalUser {
          metadata {
            fullName
          }
        }
        requestedProviderId
        requestedProvider {
          provider {
            isExternalProvider
            dispatchEmail
            dispatchPhone
            dispatchWebsite
          }
          metadata {
            organizationId
            organizationName
            autoForwardAllDispatchRequests
          }
          billingAddress{
            ${addressFields}
          }
        }
        organization {
          metadata {
            optInWorkOrderStatuses
            organizationName
            organizationId
            autoForwardAllDispatchRequests
            emailRouting
            clientOrganizationType
            tags
            tier
          }
          provider {
            dispatchEmail
          }
          squads {
            squadId
            metadata {
              name
              description
            }
          }
        }
        provider {
          metadata {
            organizationName
            organizationId
          }
          provider {
            dispatchEmail
            dispatchPhone
          }
        }
        assignedProviderUserId
        deactivatedAt
        mainContactUserId
        mainContactUser {
          metadata {
            fullName
            phoneNumberWithCountryCode
            email
          }
        }
        createdByUser {
          metadata {
            fullName
          }
        }
        modifiedByUser {
          metadata {
            fullName
          }
        }
        completedAt
        completedByUser {
          metadata {
            fullName
          }
        }
        closedAt
        closedByUser {
          metadata {
            fullName
          }
        }
        equipment {
          clientToProviderDispatchNote {
            metadata {
              message
            }
          }
        }
        location {
          clientToProviderDispatchNote {
            metadata {
              message
            }
          }
          metadata {
            locationDisplayName
            parentLocation{
              address {
                line1
                line2
                city
                stateUnabbreviated
                zip
              }
            }
          }
          address {
            line1
            line2
            city
            stateUnabbreviated
            zip
          }
        }
      }
      incidents {
        metadata {
          incidentId
          originApiId
          createdAt
          modifiedAt
          modifiedByUser {
            metadata {
              fullName
            }
          }
          equipmentId
          equipment {
            metadata {
              equipmentId
              equipmentName
              externalId
              shortId
              serialNumber
              equipmentStatus
              equipmentHealth
              subclasses
              possibleProviderIds
              provider {
                metadata {
                  organizationId
                  organizationName
                  autoForwardAllDispatchRequests
                }
                billingAddress{
                  ${addressFields}
                }
                provider {
                  isExternalProvider
                  dispatchEmail
                  dispatchPhone
                  dispatchWebsite
                }
              }
              tid
            }
            specMetadata {
              model
              manufacturer
              manufacturerEOL
              vendorLimitedSupportDate
              ${categoryGraphQlNode}
            }
          }
          symptoms
          location {
            metadata {
              locationId
              locationDisplayName
              sublocations{
                metadata{
                  locationId
                  locationDisplayName
                }
              }
              parentLocation{
                metadata{
                  locationId
                  locationDisplayName
                }
              }
            }
            address {
              line1
              line2
              city
              stateUnabbreviated
              zip
            }
          }
          organizationId
          serviceRequestId
        }
      }`,
    },
  );

export const getServiceRequestForTechnicianViewAuth = (
  serviceRequestIdOrShortId: string,
): Promise<GraphQLQueryOutputType<ServiceRequestsQuery>> =>
  queryServiceRequests(
    {
      search: {
        filters: [
          {
            metadata: {
              ...(serviceRequestIdOrShortId.length < 32
                ? { shortId: [{ keyword: [{ term: serviceRequestIdOrShortId }] }] }
                : { serviceRequestId: [{ keyword: [{ term: serviceRequestIdOrShortId }] }] }),
            },
          },
        ],
      },
    },
    {
      query: `
      serviceRequestId
      organizationMetadata {
        organizationId
        featureFlags
        clientOrganizationType
        optInWorkOrderStatuses
      }
      notes {
        metadata {
          tags
          message
        }
      }
      equipmentMetadata {
          equipmentId 
          shortId 
          equipmentName
          serialNumber
          installationDate
          subclasses
          equipmentHealth
      }
      specMetadata {
        ${categoryGraphQlNode}
        model
        manufacturer
        primaryClassId
      }
      invoiceMetadata {
        quoteFileId
      }
      locationMetadata {
        shortId
        locationDisplayName
        parentLocation {
          metadata {
            shortId
            locationDisplayName
          }
        }

      }
      metadata {
        approvals {
          ${approvalGraphqlNode}
        }
        equipment {
          notes { ${NoteListFields} }
        }
        shortId
        title
        requestStatus
        description
        checkedInDuration
        contacts
        geolocation {
          lat
          lng
          displayName
        }
       location {
          address {
            city
            countryUnabbreviated
            line1
            line2
            stateUnabbreviated
            zip
          }
        }
        mainContactUser {
          metadata {
            fullName
            email
            phoneNumberWithCountryCode
          }
        }
        serviceWorkflowToServiceStatusId
        serviceStatus {
          serviceStatusId
        }
        serviceWorkflow {
          serviceWorkflowId
        }
      }
      `,
    },
  );

export const getServiceRequestsByLocationId = async (locationId: Maybe<string>): Promise<GraphQLQueryOutputType<ServiceRequestsQuery>> =>
  queryServiceRequests(
    {
      search: {
        size: 2,
        sortBy: [{ metadata: { createdAt: SortByElasticSearchOptions.Desc } }],
        filters: [
          {
            metadata: {
              locationId: [{ keyword: [{ term: locationId }] }],
            },
          },
        ],
      },
    },
    {
      query: `
      metadata{
        serviceRequestId
        shortId
        failureDate
        requestStatus 
        clientTags {
          name,
        }
        aasClaimStatus
        aasFailCodes{
          metadata {
          aasFailCodeId
          code
          group
          description
          }
        }  
      }
    `,
    },
  );

export const getServiceRequestChanges = async (serviceRequestId) =>
  postToServiceRequestsEndpoint({
    query: gql`
      query($serviceRequestId: String!) {
        serviceRequests(serviceRequestId: $serviceRequestId) {
          data {
            incidents {
              ${fullChangesGraphQLNode}
            }
            ${fullChangesGraphQLNode}
          }
        }
      }
    `,
    variables: { serviceRequestId },
  });

export const getServiceRequestsForHome = async ({
  startTimestamp,
  organizationId,
  providerOrganizationId,
}: {
  startTimestamp: number;
  organizationId?: string | null;
  providerOrganizationId?: string | null;
}) =>
  queryServiceRequests(
    {
      search: {
        size: 1000,
        sortBy: [
          {
            metadata: {
              createdAt: SortByElasticSearchOptions.Desc,
            },
          },
        ],
        filters: [
          {
            metadata: {
              ...(organizationId ? { organizationId: [{ keyword: [{ term: organizationId }] }] } : {}),
              ...(providerOrganizationId ? { providerId: [{ keyword: [{ term: providerOrganizationId }] }] } : {}),
              createdAt: [{ range: { gt: startTimestamp } }],
            },
          },
        ],
      },
    },
    {
      query: `
        metadata {
          createdAt
          serviceRequestId
          serviceType
          requestStatus
          organizationId
          equipment {
            specMetadata {
              ${categoryGraphQlNode}
            }
          }
        }
        locationMetadata {
          locationId
          locationDisplayName
        }
      `,
    },
  );

export const getServiceRequestsForCoverageHome = async (filters: FiltersInputType) =>
  queryServiceRequests(
    {
      search: {
        size: 1000,
        sortBy: [
          {
            metadata: {
              createdAt: SortByElasticSearchOptions.Desc,
            },
          },
        ],
        filters: [
          {
            ...filters.serviceRequestFilters,
            equipmentMetadata: filters.equipmentFilters.metadata,
            equipmentCoveragesMetadata: filters.equipmentFilters.coveragesMetadata,
            specMetadata: filters.equipmentFilters.specMetadata,
          },
        ],
      },
    },
    {
      query: `
          metadata {
            createdAt
            serviceRequestId
            serviceType
            requestStatus
            organizationId
            equipment {
              specMetadata {
                ${categoryGraphQlNode}
              }
            }
          }
          locationMetadata {
            locationId
            locationDisplayName
          }
        `,
    },
  );

export const getServiceRequestsCountForCEHome = async (startTimestamp, createdByUserId) =>
  queryServiceRequests(
    {
      search: {
        size: 1,
        includeTotalCount: true,
        filters: [
          {
            metadata: {
              createdByUserId: [{ keyword: [{ term: createdByUserId }] }],
              createdAt: [{ range: { gt: startTimestamp } }],
            },
          },
        ],
      },
    },
    {
      query: `serviceRequestId`,
    },
  );

export const getServiceRequestUsersForTagging = ({ serviceRequestId }): Promise<GraphQLQueryOutputType<ServiceRequestsQuery>> =>
  queryServiceRequests(
    {
      serviceRequestId,
    },
    {
      query: `metadata {
        organizationId
        provider {
          metadata {
            organizationId
          }
        }   
        clientUsersEmailAndIds {
          userId
          fullName
        }
        providerUsersEmailAndIds {
          userId
          fullName
        }
      }`,
    },
  );

export const getOpenServiceRequestsCountAtLocation = async (locationId) =>
  queryServiceRequests(
    {
      search: {
        size: 1,
        includeTotalCount: true,
        filters: [
          {
            metadata: {
              locationId: [{ keyword: [{ term: locationId }] }],
              requestStatus: [
                {
                  keyword: [
                    {
                      notTerms: [
                        ServiceRequestStatusType.Closed,
                        ServiceRequestStatusType.Invoiced,
                        ServiceRequestStatusType.Proposed,
                        ServiceRequestStatusType.ServiceComplete,
                      ],
                    },
                  ],
                },
              ],
              deactivatedAt: [{ exists: false }],
            },
          },
        ],
      },
    },
    {
      query: `serviceRequestId`,
    },
  );

export const getServiceRequestForProposedEquipmentByEquipmentId = async ({
  equipmentId,
  excludedSRId,
}: {
  equipmentId: string;
  excludedSRId?: string;
}) => {
  return queryServiceRequests(
    {
      search: {
        filters: [
          {
            equipmentMetadata: {
              equipmentId: [{ keyword: [{ term: equipmentId }] }],
              equipmentStatus: [{ keyword: [{ term: EquipmentStatusType.Proposed }] }],
            },
            metadata: {
              ...(excludedSRId ? { serviceRequestId: [{ keyword: [{ notTerm: excludedSRId }] }] } : {}),
              requestStatus: [{ keyword: [{ term: ServiceRequestStatusType.Proposed }] }],
            },
          },
        ],
        sortBy: [{ metadata: { createdAt: SortByElasticSearchOptions.Desc } }],
      },
    },
    {
      query: `
        metadata {
    serviceRequestId
          shortId
          title
          description
          serviceRequestDisplayName
          createdAt
          requestStatus
          createdByUser{
    metadata{
    fullName
  }
          }
        }
        incidents {
          metadata {
    symptoms
    incidentId
    createdAt
  }
}
        organizationMetadata{
  autoForwardAllDispatchRequests
}
`,
    },
  );
};

export const getServiceRequestForProposedEquipmentCount = async ({ equipmentId }: { equipmentId: string }) => {
  return queryServiceRequests(
    {
      search: {
        includeTotalCount: true,
        filters: [
          {
            equipmentMetadata: {
              equipmentId: [{ keyword: [{ term: equipmentId }] }],
              equipmentStatus: [{ keyword: [{ term: EquipmentStatusType.Proposed }] }],
            },
            metadata: {
              requestStatus: [{ keyword: [{ term: ServiceRequestStatusType.Proposed }] }],
            },
          },
        ],
        sortBy: [{ metadata: { createdAt: SortByElasticSearchOptions.Desc } }],
      },
    },
    {
      query: `
        metadata {
  serviceRequestId
}
`,
    },
  );
};

export const getProposedServiceRequestCount = async (createdByUserId: Maybe<string> = null) =>
  queryServiceRequests(
    {
      search: {
        size: 1,
        includeTotalCount: true,
        filters: [
          {
            metadata: { requestStatus: [{ keyword: [{ term: ServiceRequestStatusType.Proposed }] }] },
            ...(createdByUserId ? { createdByUserId: [{ keyword: [{ term: createdByUserId }] }] } : {}),
          },
        ],
      },
    },
    { query: `metadata { serviceRequestId } ` },
  );

export const getPartApprovalRequestsCount = async (createdByUserId: Maybe<string> = null) => {
  return queryNotes(
    {
      search: {
        size: 1,
        includeTotalCount: true,
        filters: [
          {
            metadata: {
              tags: [
                {
                  keyword: [
                    {
                      terms: [NotesTagsEnum.PartRequest],
                    },
                  ],
                },
              ],
              parentLabel: [{ keyword: [{ terms: [EntityLabelEnum.ServiceRequest] }] }],
              ...(createdByUserId ? { createdByUserId: [{ keyword: [{ term: createdByUserId }] }] } : {}),
            },
          },
        ],
      },
    },
    { query: `metadata { noteId } ` },
  );
};

export const getServiceRequestsForRating = async ({ organizationId }) =>
  queryServiceRequests(
    {
      search: {
        size: 1,
        sortBy: [
          {
            metadata: {
              createdAt: SortByElasticSearchOptions.Desc,
            },
          },
        ],
        filters: [
          {
            metadata: {
              organizationId: [{ keyword: [{ term: organizationId }] }],
              providerId: [{ keyword: [{ exists: true }] }],
              requestStatus: [{ keyword: [{ term: ServiceRequestStatusType.Closed }] }],
              providerRating: [{ exists: false }],
            },
          },
        ],
      },
    },
    {
      query: `
        metadata {
  createdAt
  serviceRequestId
  shortId
  requestStatus
  serviceType
}
        providerMetadata {
  organizationName
  organizationId
}
`,
    },
  );

export const getLastNoteByServiceRequestId = async (serviceRequestId: string) => {
  return queryNotes(
    {
      search: {
        size: 1,
        sortBy: [{ metadata: { createdAt: SortByElasticSearchOptions.Desc } }],
        filters: [
          {
            metadata: {
              parentLabel: [{ keyword: [{ terms: [EntityLabelEnum.ServiceRequest] }] }],
              parentId: [{ keyword: [{ term: serviceRequestId }] }],
            },
          },
        ],
      },
    },
    { query: `metadata { tags message } ` },
  );
};

export const getServiceRequestCompletionNote = async ({ entityId }) => {
  const noteSearch: NotesSearchInput = {
    filters: [
      {
        metadata: {
          tags: [{ keyword: [{ terms: [NotesTagsEnum.ClosingNotes] }] }],
        },
      },
    ],
  };

  return postToServiceRequestsEndpoint({
    query: gql`
      query ($serviceRequestId: String!, $noteSearch: NotesSearchInput) {
        serviceRequests(serviceRequestId: $serviceRequestId) {
          data {
            notes(search: $noteSearch) {
              ...NoteComponentData
            }
          }
        }
      }
      ${NoteListFragment.fragments.noteComponentData}
    `,
    // @ts-expect-error Type is not correct. Note search is an option the function but not in the type
    variables: { serviceRequestId: entityId, noteSearch },
  });
};

export const getServiceRequestDataForInvoice = async (serviceRequestId) =>
  await queryServiceRequests(
    { serviceRequestId },
    {
      query: `
      metadata {
        location {
          address {
            city
            line1
            line2
            countryUnabbreviated
            stateUnabbreviated
            zip
          }
          metadata {
            locationDisplayName
          }
        }
        equipment {
          equipmentId
          metadata {
            shortId
            equipmentName
            serialNumber
            location {
              metadata {
                locationId
                locationName
                locationDisplayName
                organization {
                  metadata {
                    organizationName
                    organizationId
                  }
                }
              }
              address {
                line1
                line2
                city
                stateUnabbreviated
                zip
                phone
              }
            }
          }
          specMetadata {
            ${categoryGraphQlNode}
          }
        }
        provider {
          metadata {
            organizationId
            organizationName
          }
          provider {
            dispatchEmail
            dispatchPhone
          }
        }
        assignedProviderUserId
        providerRefId
        requestedProvider {
          metadata {
            organizationName
          }
        }
      }`,
    },
  );

export const getServiceRequestByShortIds = async ({ shortIds }: { shortIds: string[] }) =>
  await queryServiceRequests(
    {
      search: {
        sortBy: [
          {
            metadata: {
              createdAt: SortByElasticSearchOptions.Desc,
            },
          },
        ],
        filters: [
          {
            metadata: {
              shortId: [{ keyword: [{ terms: shortIds }] }],
            },
          },
        ],
      },
    },
    {
      query: `
      metadata {
        serviceRequestId
        shortId
      }
      incidents {
        incidentId
      }
      equipmentMetadata{
        shortId
      }`,
    },
  );

export const getServiceRequestByShortId = async ({ shortId }: { shortId: string }) =>
  await queryServiceRequests(
    {
      search: {
        sortBy: [
          {
            metadata: {
              createdAt: SortByElasticSearchOptions.Desc,
            },
          },
        ],
        filters: [
          {
            metadata: {
              shortId: [{ keyword: [{ term: shortId }] }],
            },
          },
        ],
      },
    },
    {
      query: `
        metadata {
          serviceRequestId
        }
        `,
    },
  );
export const getServiceRequestsForLocationChart = async (startTimestamp, locationId) =>
  queryServiceRequests(
    {
      search: {
        filters: [
          {
            metadata: {
              locationId: [{ keyword: [{ term: locationId }] }],
              createdAt: [{ range: { gt: startTimestamp } }],
            },
          },
        ],
      },
    },
    {
      query: `serviceRequestId metadata { createdAt }`,
    },
  );

export const getNextServiceRequestForRecurrence = async (serviceRequestId: string) =>
  queryServiceRequests(
    {
      search: {
        size: 1,
        filters: [
          {
            metadata: {
              previousRequestId: [
                {
                  keyword: [
                    {
                      term: serviceRequestId,
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
    {
      query: `serviceRequestId metadata { serviceRequestId }`,
    },
  );

export const findDuplicateQRCodeServiceRequest = async ({ qrCodeId, organizationId }: { qrCodeId: string; organizationId: string }) =>
  queryServiceRequests(
    {
      search: {
        size: 1,
        filters: [
          {
            metadata: {
              organizationId: [{ keyword: [{ term: organizationId }] }],
              qrCodeId: [{ keyword: [{ term: qrCodeId }] }],
            },
          },
        ],
      },
    },
    {
      query: `serviceRequestId`,
    },
  );
