import React, { useState } from 'react';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import {
  ClientOrganizationEnumType,
  EntityLabelEnum,
  Equipment,
  GraphQLPatchOutputType,
  Maybe,
  NoteAuthorizationLevel,
  Organization,
  OrganizationTagsEnum,
  ServiceRequest,
  ServiceRequestStatusType,
  ServiceRequestsMutationPatchArgs,
  patchEquipment,
  postNote,
  postOrganization,
} from '@equips/entities-schema';
import OrganizationsSelect, {
  OrganizationSelectOptionType,
  formatOrganizationForReactSelect,
} from '../../../../common/components/Selects/OrganizationsSelect';
import Checkbox from '../../../../common/components/Form/Checkbox';
import FormFields, { fieldTypes } from '../../../../common/components/Form/FormFields';
import validateEmail from '../../../../common/functions/validateEmail';
import UnderlinedButton from '../../../../common/components/Buttons/UnderlinedButton';
import Button from '../../../../common/components/Buttons/Button';
import FormGroup from '../../../../common/components/Form/FormGroup';
import Label from '../../../../common/components/Form/Label';
import Textarea from '../../../../common/components/Form/Textarea';
import { getDomainFromEmailAddress } from '../../../../common/functions/isPublicEmail';
import { AlertTypes, useAlert } from '../../../../common/components/Alerts/AlertContext';
import SimpleModal, { SimpleModalProps } from '../../../../common/components/Modal/SimpleModal';
import { useAuth } from '../../../../common/auth/AuthContext';
import { hasAasTagOrOrganizationId } from '../../../../common/functions/aasHelpers';
import { useDuplicateProviderChecker } from '../../../../common/hooks/useDuplicateProviderChecker';
import AasProvidersByDistanceSelect from '../../../aas/components/AasProvidersByDistanceSelect';
import { paymentPreferenceEnum, paymentPreferenceOptions, providerSourceOptions } from '../../../aas/organizations/AasCreateEditProvider';
import { useTarpProvider } from '../../../../common/hooks/useTarpProvider';
import { providerValueForFilter } from '../../../../graphql/queries/organizationGraphQLQueries';
import useGroupedProviderOptions from './useGroupedProviderOptions';

const DispatchModal = (props: {
  serviceRequestId: string;
  clientOrganizationType: Maybe<ClientOrganizationEnumType>;
  equipment: Maybe<Equipment>;
  provider: Maybe<Organization>;
  organizationTags: Maybe<OrganizationTagsEnum>[];
  patchServiceRequest: UseMutateFunction<GraphQLPatchOutputType<ServiceRequest>, unknown, ServiceRequestsMutationPatchArgs, unknown>;
  loadingSRPatch: boolean;
  modalProps: SimpleModalProps & { confirmationButtonText: string };
  locationId?: Maybe<string>;
}) => {
  const {
    serviceRequestId,
    clientOrganizationType,
    equipment,
    provider,
    organizationTags,
    patchServiceRequest,
    loadingSRPatch,
    modalProps,
    locationId,
  } = props;
  const [isDefaultProvider, setIsDefaultProvider] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState<OrganizationSelectOptionType>(formatOrganizationForReactSelect(provider));
  const [showPhoneDispatchNoteScreen, setShowPhoneDispatchNoteScreen] = useState(false);

  const [addNewProvider, setAddNewProvider] = useState(false);
  const [newProvider, setNewProvider] = useState({
    name: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    paymentPreference: null,
    providerSource: null,
  });

  const [note, setNote] = useState('');

  const showAlert = useAlert();
  const tarpProvider = useTarpProvider(locationId);

  const { isAasUser, auth } = useAuth();
  const { isDuplicateProvider, checkAndSetDuplicateProvider } = useDuplicateProviderChecker();

  const providerOrganizationId = provider?.metadata?.organizationId ?? '';

  const tarpProviderId = tarpProvider?.metadata?.organizationId ?? '';

  //autoForward false means provider is accepting dispatch (we're not forwarding requests for them)
  const providerIsAcceptingDispatch = provider?.metadata?.autoForwardAllDispatchRequests === false;

  const possibleProviderIds = (equipment?.metadata?.possibleProviderIds as string[]) ?? [];

  const defaultProviderIds = [tarpProviderId].filter((o) => o);

  const equipmentId = equipment?.metadata?.equipmentId;

  const providerChanged = selectedProvider && providerOrganizationId !== selectedProvider.value;

  const canBePhoneDispatched = addNewProvider ? !!newProvider.phone : !!selectedProvider?.provider?.dispatchPhone;
  const canBeEmailDispatched = addNewProvider ? !!newProvider.email : !!selectedProvider?.provider?.dispatchEmail;
  const canBeDispatched = addNewProvider ? !!newProvider.name : !!selectedProvider?.provider;

  const previewPhoneNumber = addNewProvider ? newProvider?.phone : selectedProvider?.provider?.dispatchPhone;
  const previewPhoneNumberStripped = addNewProvider
    ? `tel:${newProvider?.phone?.replace(/[-()\s]/gm, '')}`
    : `tel:${(selectedProvider?.provider?.dispatchPhone || '').replace(/[-()\s]/gm, '')}`;

  const { mutate: addNote, isLoading: savingNote } = useMutation(async () => {
    await postNote({
      metadata: {
        parentId: serviceRequestId,
        parentLabel: EntityLabelEnum.ServiceRequest,
        authorizationLevel: NoteAuthorizationLevel.All,
        message: note,
      },
    });
  });

  const { mutate: patchEquipmentMutation } = useMutation(patchEquipment, {
    onSuccess: async () => {
      showAlert({ type: AlertTypes.success, content: 'Equipment provider successfully updated' });
    },
  });

  const groupedOptions = useGroupedProviderOptions({
    queryKey: serviceRequestId,
    possibleProviderIds,
    defaultProviderIds,
  });

  async function dispatchSR() {
    try {
      let selectedProviderId = selectedProvider?.value || undefined;

      if (addNewProvider) {
        const provider = await postOrganization({
          metadata: {
            organizationName: newProvider.name,
            urlDomain: newProvider.email ? getDomainFromEmailAddress(newProvider.email) : '',
            autoForwardAllDispatchRequests: false,
            ...((hasAasTagOrOrganizationId(auth) || isAasUser) && { tags: [OrganizationTagsEnum.Aas] }),
          },
          provider: {
            isExternalProvider: true,
            dispatchEmail: newProvider.email,
            dispatchPhone: newProvider.phone,
            paymentPreference: newProvider?.paymentPreference ? newProvider?.paymentPreference : null,
            providerSource: newProvider?.providerSource ? newProvider?.providerSource : null,
          },
          billingAddress: {
            line1: newProvider.address,
            city: newProvider.city,
            stateUnabbreviated: newProvider.state,
            zip: newProvider.zip,
          },
        });

        selectedProviderId = provider.data?.post?.organizationId ?? undefined;
      }

      patchServiceRequest({
        serviceRequestId,
        metadata: {
          requestStatus: ServiceRequestStatusType.ProviderDispatch,
          requestedProviderId: selectedProviderId,
        },
      });

      if (equipmentId) {
        if (selectedProviderId && !possibleProviderIds?.includes(selectedProviderId)) {
          patchEquipmentMutation({
            equipmentId,
            metadata: {
              possibleProviderIds: [...possibleProviderIds, selectedProviderId],
            },
          });
        }

        if (isDefaultProvider) {
          patchEquipmentMutation({
            equipmentId,
            metadata: {
              providerId: selectedProviderId,
            },
          });
        }
      }
    } catch (error) {
      console.error(error);
      showAlert({ type: AlertTypes.error, content: 'Error dispatching service request' });
    }
  }

  const fields = [
    {
      label: 'Service Provider Name',
      id: 'name',
      value: newProvider.name,
      placeholder: ' ',
      required: true,
      ...(isAasUser && {
        warning: isDuplicateProvider.organizationName ? 'It looks like a Provider with that name already exists' : undefined,
        duplicateCheckerFunction: ({ value }) => checkAndSetDuplicateProvider('organizationName', value),
      }),
    },
    {
      label: 'Service Provider Phone',
      id: 'phone',
      value: newProvider.phone,
      placeholder: ' ',
      required: true,
      type: fieldTypes.PHONE,
      ...(isAasUser && {
        warning: isDuplicateProvider.dispatchPhone ? 'It looks like a Provider with that phone already exists' : undefined,
        duplicateCheckerFunction: ({ value }) => checkAndSetDuplicateProvider('dispatchPhone', value),
      }),
    },
    {
      label: 'Service Provider Payment Preference',
      id: 'paymentPreference',
      value: paymentPreferenceOptions.filter((option) => newProvider.paymentPreference === option.value),
      type: fieldTypes.SIMPLE_SELECT,
      placeholder: 'Select payment preference',
      required: true,
      options: paymentPreferenceOptions,
    },
    {
      label: 'Service Provider Source',
      id: 'providerSource',
      value: providerSourceOptions.filter((option) => newProvider.providerSource === option.value),
      type: fieldTypes.SELECT,
      placeholder: 'Select provider source',
      isHalf: false,
      options: providerSourceOptions,
      isClearable: true,
    },
    {
      label: 'Service Provider Email',
      id: 'email',
      value: newProvider.email,
      placeholder: ' ',
      rule: 'Please enter a valid email address',
      valid: newProvider.email ? validateEmail(newProvider.email) : null,
      ...(isAasUser && {
        warning: isDuplicateProvider.dispatchEmail ? 'It looks like a Provider with that email already exists' : undefined,
        duplicateCheckerFunction: ({ value }) => checkAndSetDuplicateProvider('dispatchEmail', value),
      }),
      required: newProvider.paymentPreference === paymentPreferenceEnum?.creditCard?.value,
    },
    { label: "Provider's Address", id: 'address', value: newProvider.address, placeholder: ' ', required: true },
    { label: 'City', id: 'city', value: newProvider.city, placeholder: ' ', required: true },
    {
      label: 'State',
      id: 'state',
      value: newProvider.state || '',
      type: fieldTypes.STATE_SELECT,
      isHalf: true,
      required: true,
    },
    { label: 'Zip Code', id: 'zip', value: newProvider.zip, placeholder: ' ', isHalf: true, required: true },
  ];

  return (
    <SimpleModal
      title={
        isAasUser
          ? `Select Service Provider`
          : addNewProvider
          ? `Dispatch request to ${newProvider.name}`
          : selectedProvider
          ? `Dispatch service request to ${selectedProvider.label}`
          : providerOrganizationId
          ? `Dispatch service request to ${equipment?.metadata?.provider?.metadata?.organizationName}`
          : modalProps.title
      }
      subtitle={
        isAasUser
          ? `Choose from a  list of existing providers, find a new provider through Thumbtack or add a new provider.`
          : modalProps.subtitle
      }
      modalType={modalProps.modalType}
      isOpen={modalProps.isOpen}
      handleClose={modalProps.handleClose}
    >
      <div className="border-b border-gray-300 pt-4" />
      <form
        className="pt-4"
        data-testid="statusChangeServiceRequestForm"
        onSubmit={async (event) => {
          event.preventDefault();
          await dispatchSR();
        }}
      >
        {!isAasUser && (
          <p className="mb-4 text-gray-700">
            {selectedProvider
              ? 'This service request will be dispatched to the selected provider below: '
              : providerOrganizationId
              ? providerIsAcceptingDispatch
                ? "This service request will be dispatched to the equipment's current provider: "
                : "Equipment's current provider is not accepting dispatch. Select another provider to dispatch to below: "
              : 'Equipment in this service request does not have a current provider set up. Select provider to dispatch below: '}
          </p>
        )}

        {isAasUser && (
          <>
            <a
              className="block text-sm underline"
              href={`https://www.thumbtack.com/content/homesafe-accountmanager`}
              target="_blank"
              rel="noreferrer"
            >
              Click here to find a provider through Thumbtack
            </a>
            <div className="mb-2 mt-2" />
          </>
        )}
        {!addNewProvider &&
          (isAasUser ? (
            <AasProvidersByDistanceSelect
              className={'mb-2'}
              fullWidth
              locationId={locationId}
              onChange={(organization) => setSelectedProvider(organization)}
              message="No providers found"
            />
          ) : (
            <OrganizationsSelect
              fullWidth
              organizationId={providerOrganizationId}
              organizationTags={organizationTags}
              defaultOrganizationIds={defaultProviderIds}
              typeOfOrganizationToFind={providerValueForFilter}
              id="serviceRequestDispatchExternally"
              changeOrganization={(organization) => setSelectedProvider(organization)}
              options={groupedOptions}
              showDetails={true}
            />
          ))}

        {clientOrganizationType !== ClientOrganizationEnumType.EquipsCoverage && (
          <Checkbox
            className={'-ml-1'}
            id="addNewProvider"
            checked={addNewProvider}
            label={
              isAasUser
                ? 'Check this box to create a new provider, or add a Thumbtack provider not in the list above.'
                : 'My preferred provider is not on the list. I would like to add my own provider.'
            }
            onChange={(event) => {
              setAddNewProvider(event?.currentTarget?.checked);
            }}
          />
        )}

        {addNewProvider && (
          <>
            <FormFields
              handleChange={({ target: { id, value } }) => {
                setNewProvider((state) => {
                  return { ...state, [id]: value };
                });
              }}
              fields={[
                {
                  fields: fields,
                },
              ]}
            />
          </>
        )}
        {(!providerOrganizationId || providerChanged || addNewProvider) && !isAasUser && (
          <Checkbox
            className={'-ml-1'}
            id="setAs"
            checked={isDefaultProvider}
            label={'Set as default provider for equipment in this service request'}
            onChange={(event) => {
              setIsDefaultProvider(event?.currentTarget?.checked);
            }}
          />
        )}

        {!showPhoneDispatchNoteScreen && (
          <div className="mt-4 flex justify-between">
            {isAasUser ? (
              <Button blue disabled={!canBeDispatched} loading={loadingSRPatch} className="ml-auto block" type="submit">
                Update Provider
              </Button>
            ) : (
              <>
                <UnderlinedButton
                  disabled={!canBePhoneDispatched}
                  className="block"
                  type="button"
                  onClick={() => setShowPhoneDispatchNoteScreen(true)}
                >
                  Phone dispatch
                </UnderlinedButton>
                <Button blue disabled={!canBeEmailDispatched} loading={loadingSRPatch} className="block" type="submit">
                  {addNewProvider
                    ? `Dispatch request to ${newProvider.name}`
                    : selectedProvider
                    ? `Dispatch request to ${selectedProvider.label}`
                    : providerOrganizationId
                    ? `Dispatch request to ${equipment?.metadata?.provider?.metadata?.organizationName}`
                    : modalProps.confirmationButtonText}
                </Button>
              </>
            )}
          </div>
        )}
      </form>
      {showPhoneDispatchNoteScreen && (
        <div className="mt-1">
          <form
            className="bg-gray-200 px-4"
            onSubmit={async (event) => {
              event.preventDefault();
              await addNote();
              await dispatchSR();
            }}
          >
            <FormGroup fullWidth>
              <a href={previewPhoneNumberStripped}>{previewPhoneNumber}</a>
              <Label label="Phone call notes" id="phoneDispatchNotes" />
              <Textarea id="phoneDispatchNotes" required value={note} onChange={(event) => setNote(event.target.value)} />
            </FormGroup>
            <div className="flex justify-end py-2">
              <Button loading={savingNote} blue type="submit">
                Dispatch
              </Button>
            </div>
          </form>
        </div>
      )}
    </SimpleModal>
  );
};

export default DispatchModal;
