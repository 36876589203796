import { SortByElasticSearchOptions } from '@equips/entities-schema';

// We should not need to do this. There is probably a way to unify sorting
const itemsThatAlsoGetNestedBehindKeyword = {
  title: true,
  line1: true,
  stateUnabbreviated: true,
  city: true,
  zip: true,
  equipmentServiced: true,
  shortId: true,
  parentLocationName: true,
  locationName: true,
  categoryId: true,
  manufacturer: true,
  model: true,
  requestStatus: true,
  locationDisplayName: true,
  externalInvoiceNumber: true,
  createdByUserId: true,
  invoiceStatus: true,
  organizationName: true,
  serialNumber: true,
  industry: true,
  authorizationRole: true,
  fullName: true,
  chargeId: true,
  chargeStatus: true,
  coverageStatus: true,
  equipmentId: true,
  chargeLifecycle: true,
  dispatchPreference: true,
  equipmentStatus: true,
  equipmentHealth: true,
  externalId: true,
  serviceType: true,
  invoiceId: true,
  providerRefId: true,
  chargeType: true,
  urlDomain: true,
  EIN: true,
  contractStatus: true,
  contractId: true,
  organizationId: true,
  locationId: true,
  contractType: true,
  description: true,
  adjudicationResult: true,
  primaryAdjudicationReason: true,
  adjustedByUserName: true,
  adjudicatedByUserName: true,
  size: true,
  priority: true,
  name: true,
  providerOrganizationName: true,
};

function formatSortedKey(keyToBeSortedBy, isDesc) {
  const sortValue = isDesc ? 'desc' : 'asc';

  return keyToBeSortedBy.includes('Id') || itemsThatAlsoGetNestedBehindKeyword[keyToBeSortedBy]
    ? { [keyToBeSortedBy]: { keyword: sortValue } }
    : { [keyToBeSortedBy]: sortValue };
}

export default function transformTableSortToGraphQLSort(sorted = []) {
  if (!sorted || sorted?.length === 0) {
    return [{ metadata: { createdAt: SortByElasticSearchOptions.Desc } }];
  }

  return sorted.map(({ id, desc }) => {
    const [keyToBeSortedBy, ...restOfArray] = id.split('.').reverse();

    return restOfArray.reduce((acc, cur) => ({ [cur]: { ...acc } }), formatSortedKey(keyToBeSortedBy, desc));
  });
}
