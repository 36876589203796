import React from 'react';
import useHead from '../../common/hooks/useHead';
import { supportEmailAddress } from '../../common/constants/contact';
import { ReactComponent as Four } from './notFoundImage.svg';

interface ErrorScreenProps {
  title?: string;
}

export default function ErrorScreen({ title = 'Page not found' }: ErrorScreenProps) {
  useHead('404');

  return (
    <div className="my-12 flex flex-wrap items-center justify-center text-center">
      <div className="w-8/12 p-4">
        <div data-testid="fourOhFour">
          <h1 className="text-3xl font-bold">{title}</h1>
          <p className="mb-6">
            Need Help? Please email us at <a href={`mailto:${supportEmailAddress}`}>{supportEmailAddress}</a>
          </p>
          <Four className="m-auto h-64 w-full" />
        </div>
      </div>
    </div>
  );
}
