import { EntityLabelEnum } from '@equips/entities-schema';
import { AuthContextValue } from '../../../common/auth/AuthContext';
import { TableFetchArgs } from '../../../common/components/DataTable';
import { getIncidentsFiltersInput } from '../../../graphql/queries/incidentGraphQLQueries';
import { getServiceRequestsFiltersInput } from '../../../graphql/queries/serviceRequestGraphQLQueries';
import { getNotesFiltersInput } from '../../../graphql/queries/notesGraphQLQueries';
import { getCaptureFiltersInput } from '../../../graphql/queries/captureGraphQLQueries';
import { getInvoiceFiltersInput, InvoiceQueryOptions } from '../../../graphql/queries/invoiceGraphQLQueries';
import { isUhwOrganizationId } from '../../../common/functions/uhwHelpers';

function getEntityFiltersInput(entity: EntityLabelEnum, filterMap: TableFetchArgs['filterMap'], authContext: AuthContextValue) {
  const { clientOrganizationType, isAasUser, auth } = authContext;

  const isUhwUser = isUhwOrganizationId(auth?.organizationId);

  const invoiceQueryOptions: InvoiceQueryOptions = {
    hideAASInvoices: !isAasUser,
    hideUHWInvoices: !isUhwUser,
  };

  switch (entity) {
    case EntityLabelEnum.ServiceRequest:
      return getServiceRequestsFiltersInput(filterMap, clientOrganizationType);
    case EntityLabelEnum.Incident:
      return getIncidentsFiltersInput(filterMap);
    case EntityLabelEnum.Note:
      return getNotesFiltersInput(filterMap);
    case EntityLabelEnum.Capture:
      return getCaptureFiltersInput(filterMap);
    case EntityLabelEnum.Invoice:
      return getInvoiceFiltersInput(filterMap, invoiceQueryOptions);
    default:
      return [];
  }
}

export { getEntityFiltersInput };
