import {
  EntityLabelEnum,
  InvoiceMetadata,
  InvoiceStatusEnumType,
  Maybe,
  queryInvoices,
  ServiceRequestMetadata,
} from '@equips/entities-schema';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UpdateIcon from '@mui/icons-material/Update';
import { LoadingButton } from '@mui/lab';
import { ButtonProps, Card, CardContent } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { lazy } from 'react';
import { Link, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { AuthorizationData, useAuth } from '../../../../common/auth/AuthContext';
import DisplayTime from '../../../../common/components/Date/DisplayTime';
import Queue, { QueueFeed, QueueLayout, QueueSidebar } from '../../../../common/components/Queue';
import { isAasOrganizationId } from '../../../../common/functions/aasHelpers';
import { useURLSearchParams } from '../../../../common/hooks/useURLSearchParams';
import { useFliptBoolean } from '../../../../common/providers/flipt/useFliptClient';
import Outlet from '../../../../routes/RouteWithSubRoutes';
import ErrorScreen from '../../../error-screen/ErrorScreen';
import InvoiceStatusTags from '../../../invoices/InvoiceStatusTags';
import { ROOT_URL } from '../../config';
import { fetchMockTasks } from './server';

const APP_TITLE = 'Invoices';

export const URLS = {
  ROOT: `${ROOT_URL}/aas/adjudication`,
  DETAILS: `${ROOT_URL}/aas/adjudication/details`,
};

export const ROUTES = [
  {
    key: 'aasAdjudication',
    path: URLS.ROOT,
    component: lazy(() => import('./App')),
    routes: [
      {
        path: `${URLS.DETAILS}/:entity/:id`,
        component: lazy(() => import('./ViewAasInvoice')),
      },
    ],
  },
];

function WrappedSidebar() {
  const { auth } = useAuth();

  const history = useHistory();

  function handleChange() {
    history.push(URLS.ROOT);
  }

  return (
    <QueueSidebar onChange={handleChange} tabWidth="w-1/6">
      <QueueFeed
        disableRefetchInterval
        disableRefetchOnWindowFocus
        renderItem={({ item, activeTaskItem, sidebarOpen }) => (
          <>
            {item?.__typename === 'Invoice' ? (
              <Card
                className={`rounded-none border-b-2 border-gray-400 ${
                  item?.metadata?.invoiceId === activeTaskItem?.metadata?.invoiceId
                    ? 'border-2 border-gray-600 bg-gray-400 shadow-inner'
                    : ''
                }`}
                onClick={() => {
                  history.push(`${URLS.DETAILS}/${EntityLabelEnum.Invoice}/${item.metadata[`${EntityLabelEnum.Invoice}Id`]}`);
                }}
              >
                <CardContent>
                  <InvoiceStatusTags
                    invoiceStatus={item?.metadata?.invoiceStatus}
                    classNames={`w-fit ${!sidebarOpen ? 'text-4xs' : 'text-xs'}`}
                  />
                  <h2 className={!sidebarOpen ? 'text-md mt-2 font-semibold' : 'mt-2 text-xl font-semibold'}>{item?.metadata?.shortId}</h2>
                  <div className={`text-gray-700 ${!sidebarOpen ? 'text-xs' : 'text-sm'}`}>
                    {item?.metadata?.serviceRequest?.metadata?.assignedInternalUserId === auth?.userId ? 'Assigned to you' : 'Unassigned'}
                  </div>
                  <div className={`mt-2 text-gray-700 ${!sidebarOpen ? 'text-2xs' : 'text-xs'}`}>
                    <span>
                      {item?.metadata?.followUpDate ? (
                        <>
                          Follow-up <DisplayTime timestamp={item?.metadata?.followUpDate} />
                        </>
                      ) : (
                        <>
                          Created <DisplayTime timestamp={item?.metadata?.createdAt} />
                        </>
                      )}
                    </span>
                  </div>
                </CardContent>
              </Card>
            ) : null}
          </>
        )}
      />
    </QueueSidebar>
  );
}

export enum AasAdjudicationToolbarActionTypes {
  ASSIGN_TO_ME = 'assign-to-me',
  SET_INVOICE_FOLLOW_UP_DATE = 'set-follow-up-date',
  AUTHORIZE_ESTIMATE = 'authorize-estimate',
}

interface AasAdjudicationActionPayload {
  assignedInternalUserId?: ServiceRequestMetadata['assignedInternalUserId'];
  invoiceStatus?: InvoiceMetadata['invoiceStatus'];
  followUpDate?: InvoiceMetadata['followUpDate'];
}

export type AasAdjudicationLocationState = {
  action: {
    type: AasAdjudicationToolbarActionTypes;
    payload: AasAdjudicationActionPayload;
  };
};

export function useToolbarAction() {
  const match = useRouteMatch(`${URLS.DETAILS}/:entity/:id`);
  const { entity, id } = useParams<{ entity: EntityLabelEnum; id: string }>();
  const { state = null } = useLocation<AasAdjudicationLocationState | null>();
  const action = state?.action;
  const param = useURLSearchParams('action');
  const isValid = !!state && !!param && match?.isExact && entity === EntityLabelEnum.Invoice && !!id;

  return { action, param, isValid };
}

export function useIsAssignedInternalUserId(invoiceId: Maybe<string>) {
  const { auth } = useAuth();
  const { action } = useToolbarAction();
  const { data, status } = useQuery({
    queryKey: ['tasks', 'invoice', { invoiceId, action }],
    queryFn: () => queryInvoices({ invoiceId }, { query: `invoiceId metadata { serviceRequest { metadata { assignedInternalUserId } } }` }),
    enabled: !!invoiceId,
    select: (data) => {
      const invoice = data?.data?.invoices?.data?.[0];
      const assignedInternalUserId = invoice?.metadata?.serviceRequest?.metadata?.assignedInternalUserId;
      const isUnassigned = !assignedInternalUserId;
      const isAssignedInternalUser = assignedInternalUserId === auth?.userId;
      return { isAssignedInternalUser, isUnassigned };
    },
  });

  return { ...data, status };
}

function ToolbarActions() {
  const { auth } = useAuth();
  const { pathname } = useLocation();
  const match = useRouteMatch<{ entity: EntityLabelEnum; id: string }>(`${URLS.DETAILS}/:entity/:id`);
  const { status, isAssignedInternalUser, isUnassigned } = useIsAssignedInternalUserId(match?.params.id);
  const isStatus = status === 'loading' || status === 'error';

  const actionProps: Array<ButtonProps & { payload: AasAdjudicationActionPayload }> = [
    {
      id: AasAdjudicationToolbarActionTypes.ASSIGN_TO_ME,
      value: AasAdjudicationToolbarActionTypes.ASSIGN_TO_ME,
      children: 'assign to me',
      startIcon: <AssignmentIndIcon />,
      disabled: !isUnassigned || isAssignedInternalUser || isStatus,
      hidden: isAssignedInternalUser,
      'aria-description': 'Assigns internal user to the claim, not the invoice',
      payload: {
        assignedInternalUserId: auth?.userId,
      },
    },
    {
      id: AasAdjudicationToolbarActionTypes.SET_INVOICE_FOLLOW_UP_DATE,
      value: AasAdjudicationToolbarActionTypes.SET_INVOICE_FOLLOW_UP_DATE,
      children: 'set follow-up date',
      startIcon: <UpdateIcon />,
      disabled: !isAssignedInternalUser || isStatus,
      'aria-description': 'Set the follow-up date on the claim to indicate that the user is done with this task, for now',
      payload: {
        followUpDate: undefined,
      },
    },
    {
      id: AasAdjudicationToolbarActionTypes.AUTHORIZE_ESTIMATE,
      value: AasAdjudicationToolbarActionTypes.AUTHORIZE_ESTIMATE,
      children: 'authorize estimate',
      startIcon: <CheckCircleIcon />,
      disabled: !isAssignedInternalUser || isStatus,
      'aria-description': 'Authorize invoice (Approved) to indicate that the user is done with this task, as the final action',
      payload: {
        invoiceStatus: InvoiceStatusEnumType.Approved,
      },
    },
  ];

  const defaultButtonProps: ButtonProps = {
    className: 'capitalize disabled:cursor-not-allowed hover:bg-equipsNavyBlue hover:text-white',
    color: 'primary',
    size: 'small',
    sx: { textTransform: 'none' },
    variant: 'outlined',
  };

  return (
    <>
      {match?.isExact ? (
        <nav className="flex flex-row gap-4">
          {actionProps.map(({ disabled, payload, ...props }) => (
            <div key={props.id}>
              {!disabled ? (
                <Link
                  to={{
                    pathname,
                    search: `?action=${props.value}`,
                    state: {
                      action: {
                        type: props.id,
                        payload,
                      },
                    },
                  }}
                >
                  <LoadingButton {...defaultButtonProps} {...props} />
                </Link>
              ) : (
                <LoadingButton
                  {...defaultButtonProps}
                  {...props}
                  disabled
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  loadingPosition="start"
                  sx={{
                    cursor: 'not-allowed',
                  }}
                />
              )}
            </div>
          ))}
        </nav>
      ) : null}
    </>
  );
}

function useMockTasks() {
  const { auth } = useAuth();
  const aasTaskAdjudication = useFliptBoolean('RD-665-task-view-adjudication-armis', false, auth);

  return useQuery({
    queryKey: ['tasks', 'adjudication', { auth }],
    queryFn: () => fetchMockTasks(auth as AuthorizationData),
    enabled: aasTaskAdjudication && isAasOrganizationId(auth?.organizationId),
  });
}

export default function App({ routes }) {
  const { data: tasks = [], status } = useMockTasks();
  const { auth } = useAuth();
  const aasTaskAdjudication = useFliptBoolean('RD-665-task-view-adjudication-armis', false, auth);

  if (!aasTaskAdjudication || !isAasOrganizationId(auth?.organizationId)) {
    return <ErrorScreen />;
  }

  return (
    <>
      {aasTaskAdjudication && status === 'success' ? (
        <Queue tasks={tasks}>
          <QueueLayout
            size="w-58"
            slots={{
              appTitle: APP_TITLE,
              toolbarActions: <ToolbarActions />,
              sidebar: <WrappedSidebar />,
            }}
          >
            <Outlet routes={routes} />
          </QueueLayout>
        </Queue>
      ) : null}
    </>
  );
}
