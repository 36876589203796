import { useCallback } from 'react';

interface InAppTwoColumnContainerProps {
  size?: 'xs' | 'small' | 'medium' | 'large' | string;
  position?: 'left' | 'right';
  header?: React.ReactNode;
  sidebar: React.ReactNode;
  children: React.ReactNode;
}

function InAppTwoColumnContainer({
  size = 'small',
  position = 'left',
  header = null,
  sidebar = null,
  children = null,
}: InAppTwoColumnContainerProps) {
  const getSize = useCallback((size: InAppTwoColumnContainerProps['size']) => {
    switch (size) {
      case 'large':
        return 'w-[48rem]';
      case 'medium':
        return 'w-[32rem]';
      case 'xs':
        return 'w-24';
      case 'small':
        return 'w-96';
      default:
        return size;
    }
  }, []);

  return (
    <div className="h-[calc(100vh-60px)]">
      {header ? <header className="max-h-12">{header}</header> : null}
      <div className="flex">
        {position === 'left' ? <aside className={`bg-white ${getSize(size)}`}>{sidebar}</aside> : null}
        <main className="flex-1">{children}</main>
        {position === 'right' ? <aside className={`bg-white ${getSize(size)}`}>{sidebar}</aside> : null}
      </div>
    </div>
  );
}

export { InAppTwoColumnContainer };
