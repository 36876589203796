import React from 'react';
import { Incident, Note, ServiceRequest, ServiceRequestStatusType } from '@equips/entities-schema';
import { toDateStringFromUnixMillisecondTimestamp } from '../../common/functions/dateFunctions';
import { escalatedSeverityNumber } from '../../graphql/enums';
import CheckForRecentReboots from '../equipment/CheckForRecentReboots';

interface DispatchItemProps {
  title: string;
  detail?: string;
  active: boolean;
  onClick(): void;
  timestamp?: number;
  tag?: string;
  tagStyle?: string;
  children?: React.ReactNode;
}

export function DispatchItem(props: DispatchItemProps) {
  return (
    <button
      type="button"
      onClick={props.onClick}
      data-testid="dispatchItem"
      className={`${
        props.active ? 'border border-gray-600 bg-gray-400 shadow-inner' : 'hover:bg-gray-200'
      } block w-full border-b border-gray-200 p-2 text-left outline-none`}
    >
      <div className="flex flex-wrap justify-between text-3xs">
        <div>{props.detail}</div>
        <div>{props.timestamp && toDateStringFromUnixMillisecondTimestamp(props.timestamp)}</div>
      </div>
      <div className="truncate text-xs">{props.title}</div>
      {props.tag && <div className={`truncate text-xs text-red-500 ${props.tagStyle}`}>{props.tag}</div>}
      {props.children}
    </button>
  );
}

interface RequestItemProps {
  serviceRequest: ServiceRequest;
  active: boolean;
  onClick: (...args: any[]) => any;
}

export function RequestItem({ serviceRequest, active, onClick }: RequestItemProps) {
  const sr = serviceRequest?.metadata;
  if (!sr) return null;

  const { ProviderDispatch } = ServiceRequestStatusType;
  const escalated = (sr.severity ?? 0) >= escalatedSeverityNumber;
  const orgName = serviceRequest?.organizationMetadata?.organizationName;
  const categoryName = serviceRequest?.specMetadata?.category?.metadata?.name;

  const dispatchStatus = sr.requestStatus === ProviderDispatch ? 'Dispatched' : 'Not Dispatched';
  let title: string;
  if (sr.cancelationRequested) {
    title = 'Cancelation Request';
    +' - ' + dispatchStatus;
  } else if (escalated) {
    title = 'Escalation Request' + ' - ' + dispatchStatus;
  } else {
    title = sr.title ?? '[Untitled SR]';
  }

  let tagProps: Pick<DispatchItemProps, 'tag' | 'tagStyle'> = {};
  if (sr.equipment?.metadata?.hasChronicIssues) {
    tagProps = { tag: 'Confirmed Chronic', tagStyle: 'text-red-500' };
  } else if (sr.potentiallyChronic) {
    tagProps = { tag: 'Potentially Chronic', tagStyle: 'text-orange-500' };
  }

  return (
    <DispatchItem
      active={active}
      onClick={onClick}
      title={title}
      detail={`${sr.shortId} - ${orgName} - ${categoryName}`}
      timestamp={sr.createdAt ?? undefined}
      {...tagProps}
    />
  );
}

interface IncidentItemProps {
  incident: Incident;
  active: boolean;
  onClick(): void;
}

export function IncidentItem({ incident: incidentData, active, onClick }: IncidentItemProps) {
  const incident = incidentData?.metadata;
  if (!incident) return null;
  const { cardReaderLastRebootedAt, dispenserLastRebootedAt, journalLastRebootedAt, pinpadLastRebootedAt, receiptLastRebootedAt } =
    incident?.equipment?.metadata || {};
  const orgName = incident?.organization?.metadata?.organizationName;

  return (
    <DispatchItem
      active={active}
      onClick={onClick}
      title={incident?.faultDescription ?? '[Unknown Fault]'}
      detail={`Incident - ${orgName}`}
      timestamp={incident?.createdAt ?? undefined}
    >
      <CheckForRecentReboots
        cardReaderLastRebootedAt={String(cardReaderLastRebootedAt)}
        dispenserLastRebootedAt={String(dispenserLastRebootedAt)}
        journalLastRebootedAt={String(journalLastRebootedAt)}
        pinpadLastRebootedAt={String(pinpadLastRebootedAt)}
        receiptLastRebootedAt={String(receiptLastRebootedAt)}
        machineLastRebootedAt={null}
      />
    </DispatchItem>
  );
}

interface NoteItemProps {
  note: Note;
  active: boolean;
  onClick: (...args: any[]) => any;
}

export function NoteItem({ note: noteData, active, onClick }: NoteItemProps) {
  const note = noteData?.metadata;
  if (!note) return null;

  const parent = note.serviceRequest?.metadata ?? note.equipment?.metadata;
  const hasAttachment = note?.attachmentIds?.length;

  return (
    <DispatchItem
      active={active}
      onClick={onClick}
      title={`${hasAttachment ? '📎' : ''} ${note.message ?? '[Empty Note]'}`}
      detail={`${parent?.shortId} - ${note.createdByUser?.metadata?.fullName ?? 'Unknown User'}`}
      timestamp={note.createdAt ?? undefined}
    />
  );
}
