interface CookieOptions {
  expires?: number | Date;
  path?: string;
  domain?: string;
  secure?: boolean;
  sameSite?: 'Strict' | 'Lax' | 'None';
}

function set(name: string, value: string, options: CookieOptions = {}) {
  let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)};`;

  if (options.expires) {
    let expires;
    if (typeof options.expires === 'number') {
      const date = new Date();
      date.setDate(date.getDate() + options.expires);
      expires = date.toUTCString();
    } else if (options.expires instanceof Date) {
      expires = options.expires.toUTCString();
    } else {
      throw new Error('`expires` must be a number (days) or a Date object.');
    }

    cookieString += `expires=${expires};`;
  }
  if (options.path) {
    cookieString += `path=${options.path};`;
  }
  if (options.domain) {
    cookieString += `domain=${options.domain};`;
  }
  if (options.secure) {
    cookieString += `secure;`;
  }
  if (options.sameSite) {
    cookieString += `samesite=${options.sameSite};`;
  }

  document.cookie = cookieString;
}

function get(name: string) {
  const cookies = document.cookie.split('; ');
  for (const cookie of cookies) {
    const [key, value] = cookie.split('=');
    if (decodeURIComponent(key) === name) {
      return decodeURIComponent(value);
    }
  }
  return null;
}

function remove(name: string, options: CookieOptions = {}) {
  set(name, '', {
    ...options,
    expires: new Date(0),
  });
}

function getAll() {
  const cookies = document.cookie.split('; ');
  const cookieObject = {};
  cookies.forEach((cookie) => {
    const [key, value] = cookie.split('=');
    cookieObject[decodeURIComponent(key)] = decodeURIComponent(value);
  });
  return cookieObject;
}

export const cookie = {
  get,
  getAll,
  set,
  remove,
};
