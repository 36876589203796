import React, { useState } from 'react';
import {
  ElasticsearchSearchTypesEnum,
  Equipment,
  generateStringTermFilters,
  queryEquipment,
  SortByElasticSearchOptions,
} from '@equips/entities-schema';
import { useQuery } from '@tanstack/react-query';
import Skeleton from '@mui/material/Skeleton';
import Close from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import Add from '@mui/icons-material/Add';
import Input from '../Form/Input';
import Button from '../Buttons/Button';
import EmptyComponent from '../EmptyComponent';
import Urls from '../../../routes/Urls';

export interface EquipmentSearchableProps {
  organizationId?: string;
  locationId?: string;
  handleSelect: (equipmentId: string) => void;
  id?: string;
  /** The selected equipmentId. */
  equipmentId?: string;
  /** Whether the component should output and "any" option. If empty, no option is shown. */
  includeAnyText?: string;
}

type EquipmentOptionProps = {
  item?: Equipment;
  handleSelect: (string) => void;
  text?: string;
};

function EquipmentOption({ item, handleSelect, text }: EquipmentOptionProps) {
  const equipmentId = item?.metadata?.equipmentId;
  const equipmentName = item?.metadata?.equipmentName;
  const shortId = item?.metadata?.shortId;
  const model = item?.specMetadata?.model;
  const manufacturer = item?.specMetadata?.manufacturer;
  const serialNumber = item?.metadata?.serialNumber;

  return (
    <button
      data-testid="serviceRequestEquipmentSelect"
      data-equipmentid={equipmentId}
      className="my-1 flex w-full items-center space-x-2 rounded-lg border border-gray-400 px-3 py-2 text-left text-gray-800 hover:bg-gray-50"
      type="button"
      onClick={() => handleSelect(equipmentId)}
    >
      <div className="flex w-full flex-wrap">
        {text ? (
          text
        ) : (
          <>
            <div className="flex w-full justify-between gap-2">
              <div>{equipmentName}</div>
              <div>{shortId}</div>
            </div>
            <div className="w-full text-xs">
              {model && (
                <span className="pr-2">
                  <span className="font-semibold text-gray-700">Model:</span> {model}
                </span>
              )}
              {manufacturer && (
                <span className="pr-2">
                  <span className="font-semibold text-gray-700">Manufacturer:</span> {manufacturer}
                </span>
              )}
              {serialNumber && (
                <span className="pr-2">
                  <span className="font-semibold text-gray-700">Serial #:</span> {serialNumber}
                </span>
              )}
            </div>
          </>
        )}
      </div>
    </button>
  );
}

export function EquipmentSearchableSelection({
  equipmentId = '',
  locationId = '',
  organizationId = '',
  handleSelect,
  id,
  includeAnyText = '',
}: EquipmentSearchableProps) {
  const { t } = useTranslation();
  const [q, setQ] = useState('');

  const { data, isLoading } = useQuery(
    ['QueryEquipmentForEquipmentSelection', locationId, organizationId, q],
    () =>
      queryEquipment(
        {
          search: {
            q,
            searchType: ElasticsearchSearchTypesEnum.PhrasePrefix,
            filters: [{ metadata: generateStringTermFilters({ locationId, organizationId }) }],
            sortBy: [{ metadata: { equipmentName: { keyword: SortByElasticSearchOptions.Asc } } }],
            size: 50,
          },
        },
        { query: 'metadata { equipmentId shortId equipmentName serialNumber } specMetadata { model manufacturer }' },
      ),
    {
      enabled: !!organizationId,
      select: (data) => data?.data,
    },
  );

  const equipmentForSelection = data?.equipment?.data || [];

  return (
    <>
      <div>
        <div className="flex w-full justify-between gap-3 pb-2">
          <div className="flex w-full">
            <Input
              className="no-drag-zone flex-auto"
              autoFocus
              id="searchQuery"
              value={q}
              onChange={(event) => setQ(event.target.value)}
              placeholder="Search"
            />
            {q && (
              <button tabIndex={-1} className="ml-2 text-sm text-gray-700" type="button" onClick={() => setQ('')}>
                <Close />
              </button>
            )}
          </div>
          <Button gray className="whitespace-nowrap" to={Urls.EQUIPMENT_CREATE}>
            <Add /> {t('addWithNoun', { noun: t('equipment') })}
          </Button>
        </div>
        <div
          aria-labelledby={id || 'selectEquipmentForRequest'}
          className="scrolling-touch scrollbar-w-2 scrollbar-track-gray-lighter scrollbar-thumb-rounded scrollbar-thumb-gray max-h-md w-full overflow-y-auto"
        >
          {includeAnyText && <EquipmentOption text={includeAnyText} handleSelect={handleSelect} />}
          {isLoading && <Skeleton variant="rectangular" height={50} />}
          {!isLoading && equipmentForSelection.length === 0 && <EmptyComponent small />}
          {equipmentForSelection.map((item) => (
            <EquipmentOption key={item?.metadata?.equipmentId || ''} item={item || {}} handleSelect={handleSelect} />
          ))}
        </div>
      </div>
    </>
  );
}
