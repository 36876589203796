import {
  AasContractsMetadata,
  AasFailGroupEnumType,
  EquipmentMetadata,
  IncidentMetadata,
  Maybe,
  OrganizationTagsEnum,
  ServiceRequestMetadata,
} from '@equips/entities-schema';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../common/auth/AuthContext';
import RadioCard from '../../../common/components/Form/RadioCards';
import OrganizationsSelect, {
  formatOrganizationForReactSelect,
  OrganizationSelectOptionType,
} from '../../../common/components/Selects/OrganizationsSelect';
import { isAasOrganizationId } from '../../../common/functions/aasHelpers';
import { isUhwOrganizationId } from '../../../common/functions/uhwHelpers';
import { useTarpProvider } from '../../../common/hooks/useTarpProvider';
import AasProvidersByDistanceSelect from '../../aas/components/AasProvidersByDistanceSelect';
import useGroupedProviderOptions from '../../service-requests/components/ModifyServiceRequest/useGroupedProviderOptions';
import NationalServiceAlliance from './NationalServiceAlliance';

export enum CoreServiceProviderSelectOptionsEnum {
  Recommended = 'Recommended',
  Additional = 'Additional',
  NoProvider = 'No provider',
  NewProvider = 'New provider',
}

interface CoreServiceProviderSelectProps {
  serviceRequestId?: ServiceRequestMetadata['serviceRequestId'];
  locationId?: AasContractsMetadata['locationId'] | IncidentMetadata['locationId'];
  selectedProviderId?: string | undefined;
  onCardChange?: (value: CoreServiceProviderSelectOptionsEnum) => void;
  onProviderChange?: (value: OrganizationSelectOptionType) => void;
  form?: React.ReactNode;
  providerOptions?: {
    possibleProviderIds?: EquipmentMetadata['possibleProviderIds'];
    organizationTags?: Maybe<OrganizationTagsEnum>[];
    label?: string;
  };
  aasOptions?: { selectedFailGroups?: AasFailGroupEnumType[]; message?: string };
}

function CoreServiceProviderSelect({
  serviceRequestId = undefined,
  locationId = undefined,
  selectedProviderId = undefined,
  onCardChange,
  onProviderChange,
  form = undefined,
  providerOptions = { possibleProviderIds: [], organizationTags: [], label: ' ' },
  aasOptions = { selectedFailGroups: [], message: 'Select a contract to get list of providers' },
}: CoreServiceProviderSelectProps) {
  const { auth } = useAuth();
  const [selectedCard, setSelectedCard] = useState(CoreServiceProviderSelectOptionsEnum.Recommended);

  useEffect(() => {
    if (onProviderChange && selectedCard === CoreServiceProviderSelectOptionsEnum.NoProvider) {
      onProviderChange({
        value: '',
        label: '',
        address: {},
        provider: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCard]);

  const tarpProvider = useTarpProvider(locationId);
  const additionalNetworkOptions = [...(tarpProvider ? [formatOrganizationForReactSelect(tarpProvider)] : [])];
  const defaultProviderIds = [];

  const groupedOptions = useGroupedProviderOptions({
    queryKey: 'CoreServiceProviderSelect',
    possibleProviderIds: providerOptions.possibleProviderIds as string[],
  });

  if (!auth?.organizationId) throw new Error('Unauthorized');
  const organizationId = auth?.organizationId;

  function handleCardChange(newSelection: CoreServiceProviderSelectOptionsEnum) {
    setSelectedCard(newSelection);
    if (onCardChange) onCardChange(newSelection);
  }

  const config = function getConfig() {
    switch (Boolean(organizationId)) {
      case isAasOrganizationId(organizationId):
        return {
          [CoreServiceProviderSelectOptionsEnum.Recommended]: (
            <AasProvidersByDistanceSelect
              className={'mb-2'}
              fullWidth
              locationId={locationId}
              onChange={onProviderChange}
              message={aasOptions.message}
              selectedFailGroups={aasOptions.selectedFailGroups}
            />
          ),
          [CoreServiceProviderSelectOptionsEnum.Additional]: (
            <AasProvidersByDistanceSelect
              className={'mb-2'}
              fullWidth
              locationId={locationId}
              onChange={onProviderChange}
              message={aasOptions.message}
              selectedFailGroups={aasOptions.selectedFailGroups}
              defaultOptions={additionalNetworkOptions}
            />
          ),
          [CoreServiceProviderSelectOptionsEnum.NoProvider]: null,
          [CoreServiceProviderSelectOptionsEnum.NewProvider]: form,
        };
      case isUhwOrganizationId(organizationId):
        return {
          [CoreServiceProviderSelectOptionsEnum.Recommended]: (
            <OrganizationsSelect
              id="organizationsSelect"
              fullWidth
              label={providerOptions.label}
              organizationId={selectedProviderId as string}
              changeOrganization={onProviderChange}
              showDetails={true}
              organizationTags={providerOptions.organizationTags}
              defaultOrganizationIds={defaultProviderIds}
              options={groupedOptions}
            />
          ),
          [CoreServiceProviderSelectOptionsEnum.Additional]: serviceRequestId ? (
            <NationalServiceAlliance serviceRequestId={serviceRequestId} selectedProviderId={selectedProviderId} />
          ) : null,
          [CoreServiceProviderSelectOptionsEnum.NoProvider]: null,
          [CoreServiceProviderSelectOptionsEnum.NewProvider]: form,
        };
      default:
        return {
          [CoreServiceProviderSelectOptionsEnum.Recommended]: (
            <OrganizationsSelect
              id="organizationsSelect"
              fullWidth
              label={providerOptions.label}
              organizationId={organizationId as string}
              changeOrganization={onProviderChange}
              showDetails={true}
              organizationTags={providerOptions.organizationTags}
              defaultOrganizationIds={defaultProviderIds}
              options={groupedOptions}
            />
          ),
          [CoreServiceProviderSelectOptionsEnum.Additional]: (
            <OrganizationsSelect
              id="organizationsSelect"
              fullWidth
              label={providerOptions.label}
              organizationId={organizationId as string}
              changeOrganization={onProviderChange}
              showDetails={true}
              organizationTags={providerOptions.organizationTags}
              defaultOrganizationIds={defaultProviderIds}
              options={additionalNetworkOptions}
            />
          ),
          [CoreServiceProviderSelectOptionsEnum.NoProvider]: null,
          [CoreServiceProviderSelectOptionsEnum.NewProvider]: form,
        };
    }
  };

  const options = [
    { label: CoreServiceProviderSelectOptionsEnum.Recommended, value: CoreServiceProviderSelectOptionsEnum.Recommended },
    { label: CoreServiceProviderSelectOptionsEnum.Additional, value: CoreServiceProviderSelectOptionsEnum.Additional },
    { label: CoreServiceProviderSelectOptionsEnum.NoProvider, value: CoreServiceProviderSelectOptionsEnum.NoProvider },
    { label: CoreServiceProviderSelectOptionsEnum.NewProvider, value: CoreServiceProviderSelectOptionsEnum.NewProvider },
  ];

  return (
    <section className="w-full">
      <RadioCard
        id="CoreServiceProviderSelection"
        label=""
        cardSize="flex-grow flex-wrap"
        options={options}
        onChange={handleCardChange}
        value={selectedCard}
      />
      <div className="py-4">{config()[selectedCard]}</div>
    </section>
  );
}

export default CoreServiceProviderSelect;
