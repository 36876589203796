import {
  ElasticsearchSearchTypesEnum,
  GraphQLQueryOutputType,
  ServiceRequestStatusType,
  ServiceRequestsQuery,
  ServiceRequestsSearchInput,
  SortByElasticSearchOptions,
  addressFields,
  queryServiceRequests,
} from '@equips/entities-schema';
import { TableFetchArgs } from '../../../common/components/DataTable';
import transformTableSortToGraphQLSort from '../../../graphql/transformTableSortToGraphQLSort';
import { enumSelectOptionToGraphQLFilter } from '../../../graphql/enums';
import { transformDateRangeSelectAndCustomDateIntoGraphQLQuery } from '../../../common/components/Date/DateRangeFilter';
import { toTerms } from '../../../common/functions/elasticSearchHelpers';
import { approvalGraphqlNode } from '../../../graphql/queries/serviceRequestGraphQLQueries';

export const getClaimsForList = async ({
  sortBy,
  globalFilter = '',
  pageSize = 10,
  pageIndex = 0,
  filterMap,
  includeTotalCount = false,
}: TableFetchArgs) => {
  const query = `
    metadata {
      serviceContract {
        metadata{
          shortId
          serviceContractId
          status
          limitOfLiability
          sequence
          effectiveDate
          expirationDate
          location{
            metadata{
              locationName
            }
            address{
              ${addressFields}
            }
          }
        }
      }
      serviceCategories {
        serviceCategoryId
        metadata {
          name
        }
      }
      assignedInternalUser {
        metadata {
          fullName
          profilePictureUrl
        }
      }
      checklist {
        metadata {
          checklistId
          completedPercentage
        }
      }
      clientFollowUpDate
      clientTags {
        tagId
        entityLabel
        name
        style
        organizationId
      }
      closedAt
      closedByUser {
        metadata {
          fullName
        }
      }
      completedAt
      completedByUser {
        metadata {
          fullName
        }
      }
      createdAt
      createdByUserId
      createdByUser {
        metadata {
          fullName
        }
      }
      description
      dueDate
      externalWorkOrderNo
      failureDate
      modifiedAt
      modifiedByUser {
        metadata {
          fullName
        }
      }
      requestedProvider {
        metadata {
          organizationId
          organizationName
        }
      }
      requestedProviderId
      requestStatus
      scheduledDate
      serviceRequestId
      shortId
    }
    notes(search: $noteSearch) {
      metadata {
        noteId
        message
        createdAt
        tags
      }
    }
    invoiceMetadata {
      invoiceId
      invoiceStatus
      invoiceTotal
      shortId
      fileId
      quoteFileId
    }
  `;

  const search: ServiceRequestsSearchInput = {
    q: globalFilter,
    size: pageSize,
    from: pageSize * pageIndex,
    searchType: ElasticsearchSearchTypesEnum.PhrasePrefix,
    sortBy: transformTableSortToGraphQLSort(sortBy),
    includeTotalCount,
    filters: [
      {
        organizationMetadata: {
          // TO-DO: Turn on when live/data is available for testing
          // clientOrganizationType: [{ keyword: [{ term: ClientOrganizationEnumType.EquipsHomeWarranty }] }],
        },
        metadata: {
          ...(filterMap['metadata.locationId'] ? { locationId: [{ keyword: [{ terms: filterMap['metadata.locationId'] }] }] } : {}),
          ...(filterMap['metadata.deactivatedAt'] ? { deactivatedAt: [{ exists: true }] } : {}),
          requestedProviderId: enumSelectOptionToGraphQLFilter(filterMap['metadata.requestedProviderId']),
          createdAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.createdAt']),
          completedAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.completedAt']),
          closedAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.closedAt']),
          ...(filterMap['metadata.assignedInternalUserId']
            ? filterMap['metadata.assignedInternalUserId'] === 'unassigned'
              ? { assignedInternalUserId: [{ keyword: [{ term: null }] }] }
              : { assignedInternalUserId: [{ keyword: [{ term: filterMap['metadata.assignedInternalUserId'] }] }] }
            : {}),
          ...(filterMap['metadata.createdByUserId']
            ? { createdByUserId: [{ keyword: [{ term: filterMap['metadata.createdByUserId'] }] }] }
            : {}),
          scheduledDate:
            filterMap['metadata.scheduledDate'] !== 'none'
              ? transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.scheduledDate'])
              : [{ exists: false }],
          modifiedAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.modifiedAt']),
          ...(filterMap['metadata.modifiedByUserId']
            ? { modifiedByUserId: [{ keyword: [{ term: filterMap['metadata.modifiedByUserId'] }] }] }
            : {}),
          failureDate: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.failureDate']),
          ...(filterMap['closed'] && filterMap['closed'] !== 'all'
            ? { closedAt: [{ exists: filterMap['closed'] === 'closed' ? true : false }] }
            : {}),
          clientFollowUpDate: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.clientFollowUpDate']),
          requestStatus: toTerms<ServiceRequestStatusType>(filterMap['metadata.requestStatus']),
        },
        tagMetadata: {
          tagId: !filterMap['tagMetadata.tagId']?.includes('none')
            ? toTerms<string>(filterMap['tagMetadata.tagId'])
            : [{ keyword: [{ exists: false }] }],
        },
        invoiceMetadata: {
          invoiceStatus: enumSelectOptionToGraphQLFilter(filterMap['invoiceMetadata.invoiceStatus']),
          ...(filterMap['invoiceMetadata.quoteFileId'] && filterMap['invoiceMetadata.quoteFileId'] !== 'all'
            ? { quoteFileId: [{ keyword: [filterMap['invoiceMetadata.quoteFileId'] === 'true' ? { notTerm: null } : { term: null }] }] }
            : {}),
          ...(filterMap['invoiceMetadata.fileId'] && filterMap['invoiceMetadata.fileId'] !== 'all'
            ? { quoteFileId: [{ keyword: [filterMap['invoiceMetadata.fileId'] === 'true' ? { notTerm: null } : { term: null }] }] }
            : {}),
        },
      },
    ],
  };

  const { data } = await queryServiceRequests(
    { search, noteSearch: { sortBy: [{ metadata: { createdAt: SortByElasticSearchOptions.Desc } }] } },
    { query },
  );

  return { totalCount: data?.serviceRequests?.totalCount, data: data?.serviceRequests?.data ?? [] };
};

export const getClaimForView = ({ serviceRequestId }): Promise<GraphQLQueryOutputType<ServiceRequestsQuery>> =>
  queryServiceRequests(
    {
      serviceRequestId,
    },
    {
      query: `
      organizationMetadata {
        allowedClientTags {
          tagId
          entityLabel
          name
          style
          organizationId
        }
      }
      invoices {
        deactivatedAt
        assignee {
          metadata {
            fullName
          }
        }
        invoiceId
        shortId
        title
        invoiceTotal
        invoiceStatus
        externalInvoiceNumber
        invoiceDisplayStatus
        quickbooksInvoice {
          DocNumber
          Id
        }
        fileId
        file {
          metadata {
            fileId
            presignedGetUrl
          }
        }
        quoteFileId
        quoteFile {
          metadata {
            fileId
            presignedGetUrl
          }
        }
        payables {
          payableId
          metadata {
            authorizationNumber
            paidAmount
            paidAt
            paymentAmount
            paymentAt
            payableStatus
            paymentStatus
            paymentType
            payeeType
            shortId
          }
        }
        providerOrganization {
          metadata {
            organizationName
          }
        }
      }
      metadata {
        approvals {
          ${approvalGraphqlNode}
        }
        clientTags {
          tagId
          entityLabel
          name
          style
          organizationId
        }
        serviceCategories {
          serviceCategoryId
          metadata {
            name
          }
        }
        serviceSymptoms
        createdAt
        scheduledDate
        modifiedAt
        serviceRequestId
        shortId
        contacts
        failureDate
        title
        description
        cancelationRequested
        requestStatus
        severity
        providerRefId
        requestOrigination
        dispatchedInternallyAt
        dispatchedToEquipsAt
        dispatchedToProviderAt
        externalWorkOrderNo
        potentiallyChronic
        billingStatus
        dispatchEmail
        additionalPmCompleted
        checkedInDuration
        billable
        clientFollowUpDate
        assignedInternalUserId
        assignedInternalUser {
          metadata {
            fullName
          }
        }
        requestedProviderId
        requestedProvider {
          provider {
            dispatchEmail
            dispatchPhone
          }
          metadata {
            organizationId
            organizationName
          }
          billingAddress {
            ${addressFields}
          }
        }
        organization {
          metadata {
            optInWorkOrderStatuses
            organizationName
            organizationId
            autoForwardAllDispatchRequests
            emailRouting
            clientOrganizationType
            tags
          }
          provider {
            dispatchEmail
          }
        }
        provider {
          metadata {
            organizationName
            organizationId
          }
          provider {
            dispatchEmail
            dispatchPhone
          }
        }
        assignedProviderUserId
        deactivatedAt
        createdByUser {
          metadata {
            fullName
          }
        }
        modifiedByUser {
          metadata {
            fullName
          }
        }
        completedAt
        completedByUser {
          metadata {
            fullName
          }
        }
        closedAt
        closedByUser {
          metadata {
            fullName
          }
        }
        serviceContract {
          metadata{
            shortId
            serviceContractId
            status
            limitOfLiability
            sequence
            effectiveDate
            expirationDate

            location{
              metadata{
                locationName
              }
              address{
                ${addressFields}
              }
            }
          }
        }
      }
      incidents {
        metadata {
          incidentId
          createdAt
          modifiedAt
          modifiedByUser {
            metadata {
              fullName
            }
          }
          organizationId
          serviceRequestId
        }
      }`,
    },
  );

export const getClaimInforForNSAForm = ({ serviceRequestId }): Promise<GraphQLQueryOutputType<ServiceRequestsQuery>> =>
  queryServiceRequests(
    {
      serviceRequestId,
    },
    {
      query: `metadata {
        shortId
        serviceContract {
          metadata {
            shortId
            serviceCallFee
            location {
              metadata {
                locationName
              }
              address {
                ${addressFields}
              }
            }
          }
        }
      }`,
    },
  );
