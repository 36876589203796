import React, { lazy, useState, useContext, useEffect } from 'react';
import {
  ServiceTypeEnum,
  queryIncidents,
  postEquipment,
  OrganizationFeatureFlagEnum,
  queryServiceRequests,
  Equipment,
  Location,
  ServiceRequest,
  RequestOriginationType,
  ChecklistBlueprint,
  GeolocationType,
  RecurrenceOptionsType,
  ServiceRequestMetadataPostInput,
  Maybe,
  ClientOrganizationEnumType,
} from '@equips/entities-schema';
import { useStackState } from 'rooks';
import { waitSynchronously } from '@equips/common-resources';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Close from '@mui/icons-material/Close';
import { FileWithPath } from 'react-dropzone';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AlertTypes, useAlert } from '../Alerts/AlertContext';
import { useAuth } from '../../auth/AuthContext';
import { saveOneEquipment } from '../../../screens/equipment/EquipmentAddScreenLegacy';
import { ModalContext } from '../../providers/ModalProvider';
import { AddEquipmentFormContent, EditEquipmentType } from '../../../screens/equipment/components/AddEquipmentFormEquipmentModal';
import { DuplicateRequestModalContent } from '../../../screens/service-requests/components/DuplicateRequestModal';
import { getUnixMillisecondTimestampXDaysAgo } from '../../functions/dateFunctions';
import { TrialMessage } from '../TrialMessage';
import Spinner from '../Loaders/Spinner';
import LocationForm from '../../../screens/locations/LocationForm';
import { cookie } from '../../functions/localStorage';
import Urls from '../../../routes/Urls';
import { useFliptBoolean } from '../../providers/flipt/useFliptClient';
import ChangeOrganization from './ChangeOrganization';
import { SubmitRequestSelectEquipment, SubmitRequestSelectEquipmentQueryKey } from './SubmitRequestSelectEquipment';
import { SubmitRequestPinnedInfo } from './SubmitRequestPinnedInfo';
import { RequestForm } from './RequestForm';
import { submitRequestMutation } from './submitRequestMutations';
import { SubmitRequestGeolocation } from './SubmitRequestGeolocation';

const ConfirmationScreen = lazy(() => import('./ConfirmationScreen'));
const MobileClaimFormScreen = lazy(() => import('../../../screens/home-warranty/mobile-app/MobileClaimFormScreen'));

const getDuplicateSR = async (equipmentId: string[]) =>
  queryIncidents(
    {
      search: {
        size: 1,
        filters: [
          {
            metadata: {
              createdAt: [{ range: { gt: getUnixMillisecondTimestampXDaysAgo(1) } }],
              equipmentId: [{ keyword: [{ terms: equipmentId }] }],
            },
          },
        ],
      },
    },
    {
      query: `
      metadata {
        serviceRequest {
          metadata {
            serviceRequestId
            serviceRequestDisplayName
          }
        }
      }
      `,
    },
  );

export type RequestFormData = Partial<ServiceRequestMetadataPostInput> & {
  serviceRequests?: ServiceRequest[];
  equipment?: Maybe<Equipment>;
  createAsClosed?: boolean;
  attachments?: FileWithPath[];
  partNumber?: string;
  partQuantity?: number;
  symptoms?: { [key: string]: boolean };
  checklistBlueprint?: ChecklistBlueprint;
  clientTags?: { tagId: string }[];
};

export interface SubmitRequestProps {
  serviceType: ServiceTypeEnum;

  onSuccess?: (requests?: ServiceRequest[]) => unknown;
  onClose?: () => unknown;

  organizationId?: string;
  incidentId?: string;
  equipment?: Equipment[];
  location?: Location;
  scheduledDate?: number;
  dueDate?: number;

  createAsClosed?: boolean;
  requestOrigin?: RequestOriginationType;

  /** Request is made from PM schedule page */
  pmView?: boolean;
}

type Stage =
  | 'selectEquipment'
  | 'submitRequest'
  | 'completed'
  | 'addEquipment'
  | 'duplicateWarning'
  | 'pinnedInfo'
  | 'geolocation'
  | 'addLocation';

export default function SubmitRequest(props: SubmitRequestProps) {
  const { serviceType } = props;
  const { t } = useTranslation();

  const showAlert = useAlert();
  const queryClient = useQueryClient();
  const { auth, isTrial, userCan, internalUsers, determineFeatureFlagVisibility, clientOrganizationType } = useAuth();
  const modal = useContext(ModalContext);
  const history = useHistory();

  const titles: { [serviceType in ServiceTypeEnum]: string } = {
    Consumables: t('orderConsumables'),
    Corrective: t('submitServiceRequest'),
    Preventive: t('schedulePreventiveMaintenance'),
    Other: t('submitServiceRequest'),
  };

  const pmFrequency = props?.equipment?.[0]?.metadata?.pmFrequency;
  const initialRecurrenceInterval = pmFrequency || serviceType === ServiceTypeEnum.Preventive ? 0 : null;

  const initialRequestData = {
    clientTags: [],
    createAsClosed: props.createAsClosed ?? false,
    serviceType,
    assignedInternalUserId: null,
    mainContactUserId: auth?.userId || null,
    symptoms: {},
    dueDate: props?.dueDate ?? null,
    scheduledDate: props?.scheduledDate ?? null,
    recurrenceInterval: initialRecurrenceInterval,
    recurrenceOption: RecurrenceOptionsType.Once,
    contacts: null,
    title: '',
    description: '',
    requestOrigination: props.requestOrigin
      ? props.requestOrigin
      : userCan(internalUsers)
      ? RequestOriginationType.EquipsOnBehalfOfCustomer
      : RequestOriginationType.CustomerRequest,
  };

  const [requestData, setRequestData] = useState<RequestFormData>(initialRequestData);
  const [organizationId, setOrganizationId] = useState<string>(props.organizationId ?? auth?.organizationId ?? '');
  const [equipment, setEquipment] = useState<Equipment[]>(props.equipment || []);
  const [location, setLocation] = useState<undefined | Location>(props.location);
  const [geolocation, setGeolocation] = useState<GeolocationType>();
  const [duplicateRequest, setDuplicateRequest] = useState<ServiceRequest | null>(null);
  const [, stageHistory] = useStackState<Stage>([equipment.length > 0 ? 'submitRequest' : 'selectEquipment']);
  const [addEquipmentLocationId, setAddEquipmentLocationId] = useState<string>('');

  const isVerifiedTrial = auth?.organization?.metadata?.featureFlags?.includes(OrganizationFeatureFlagEnum.VerifiedTrial);
  const { data: trialServiceRequestCount } = useQuery(
    ['ServiceRequestFormTrialCount'],
    async () => {
      const { data } = await queryServiceRequests({
        search: {
          includeTotalCount: true,
          filters: [{ metadata: { organizationId: [{ keyword: [{ term: auth?.organizationId }] }] } }],
        },
      });
      return data?.serviceRequests?.totalCount ?? 0;
    },
    { enabled: isVerifiedTrial },
  );
  const trialLimitExceeded = isVerifiedTrial && (trialServiceRequestCount ?? 0) >= 10;

  const equipmentIds = equipment?.map((eq) => eq?.equipmentId || '')?.filter((equipmentId) => !!equipmentId) || [];
  const locationId = addEquipmentLocationId;
  const symptoms = equipment?.[0]?.specMetadata?.category?.metadata?.symptoms || [];

  async function hasDuplicateRequest(equipmentIds) {
    if (equipmentIds.length === 0) {
      return false;
    }

    const data = await getDuplicateSR(equipmentIds);

    if (data?.data?.incidents?.data?.[0]?.metadata?.serviceRequest) {
      const duplicateRequest = data?.data?.incidents?.data?.[0]?.metadata?.serviceRequest;
      setDuplicateRequest(duplicateRequest);
      return true;
    }

    return false;
  }

  useEffect(
    () => setRequestData({ ...requestData, symptoms: symptoms && symptoms.length === 1 ? { [symptoms[0] as string]: true } : {} }),
    [equipment],
  );

  useEffect(() => {
    if (equipment?.some((eq) => eq?.metadata?.pinnedNote || eq?.metadata?.pinnedImageId)) {
      stageHistory.push('pinnedInfo');
    } else {
      // if there is no pinned info we need to show we check here for duplicate SRs
      const redeclareHasDuplicateRequest = async () => {
        const hasDupeRequest = await hasDuplicateRequest(equipmentIds);
        if (hasDupeRequest) {
          stageHistory.push('duplicateWarning');
        }
      };

      redeclareHasDuplicateRequest().catch(console.error);
    }
  }, [equipment]);

  const { mutateAsync: submitRequest, isLoading: isSavingRequest } = useMutation(
    () =>
      submitRequestMutation({
        location,
        equipment,
        incidentId: props.incidentId,
        organizationId,
        requestData,
        serviceType,
        geolocation,
        determineFeatureFlagVisibility,
      }),
    {
      onError: (error: Error) => {
        showAlert({ type: AlertTypes.error, content: error.message });
      },
      onSuccess: (data) => {
        showAlert({ type: AlertTypes.success, content: t('successfullyOpenedServiceRequest') });
        setRequestData((state) => ({ ...state, serviceRequests: data }));
        stageHistory.push('completed');
        props.onSuccess?.(data);
      },
    },
  );

  const { mutate: addEquipment, isLoading: isAddingEquipment } = useMutation(
    async (equipment: EditEquipmentType) => {
      await saveOneEquipment(equipment, postEquipment, organizationId, locationId, userCan);
      await waitSynchronously(5000); // TODO Remove after migrating equipment to postgres
      queryClient.refetchQueries({ queryKey: [SubmitRequestSelectEquipmentQueryKey] });
    },
    {
      onSuccess: () => {
        showAlert({ type: AlertTypes.success, content: t('equipmentAdded') });
        stageHistory.pop();
        stageHistory.push('selectEquipment');
      },
    },
  );

  const handleClose = () => {
    modal.handleClose();
    props.onClose?.();
  };

  const switchToNewVersion = () => {
    cookie.set('NEW_SR_PROCESS', 'true');
    handleClose();
    history.push(Urls.SERVICE_REQUESTS_WORKFLOW);
  };

  const allowNewSrProcess = useFliptBoolean('sr-create-with-workflow', false, auth);

  if ((isTrial && !isVerifiedTrial) || trialLimitExceeded) return <TrialMessage onClose={() => handleClose()} />;

  let CurrentStage: React.ReactNode;
  switch (stageHistory.peek()) {
    case 'selectEquipment':
      CurrentStage = isAddingEquipment ? (
        <div className="flex justify-center py-8">
          <Spinner></Spinner>
        </div>
      ) : (
        <div>
          <ChangeOrganization organizationId={organizationId} setOrganizationId={setOrganizationId} />
          <SubmitRequestSelectEquipment
            location={location}
            organizationId={organizationId ?? ''}
            onChange={({ location, equipment }) => {
              setEquipment(equipment);
              setLocation(location);
              setGeolocation(undefined);
              stageHistory.push('submitRequest');
            }}
            setAddEquipmentLocationId={setAddEquipmentLocationId}
            addEquipment={() => stageHistory.push('addEquipment')}
            addLocation={() => stageHistory.push('addLocation')}
            onUseGeolocation={() => stageHistory.push('geolocation')}
          />
        </div>
      );
      break;

    case 'geolocation':
      CurrentStage = (
        <SubmitRequestGeolocation
          organizationId={organizationId ?? ''}
          onChange={({ equipment, geolocation }) => {
            setEquipment(equipment);
            setLocation(undefined);
            setGeolocation(geolocation);
            stageHistory.push('submitRequest');
          }}
          addEquipment={() => stageHistory.push('addEquipment')}
          onSelectLocation={() => stageHistory.push('selectEquipment')}
        />
      );
      break;

    case 'pinnedInfo':
      CurrentStage = (
        <SubmitRequestPinnedInfo
          equipment={equipment}
          onCancel={() => stageHistory.push('selectEquipment')}
          onContinue={async () => {
            // If there is a pinned note we check here for duplicate SRs
            const hasDupeRequest = await hasDuplicateRequest(equipmentIds);
            if (hasDupeRequest) {
              stageHistory.push('duplicateWarning');
            } else {
              stageHistory.push('submitRequest');
            }
          }}
        />
      );
      break;

    case 'duplicateWarning':
      CurrentStage = (
        <DuplicateRequestModalContent
          serviceRequest={duplicateRequest}
          onClickContinue={() => {
            stageHistory.pop();
            stageHistory.push('submitRequest');
          }}
        />
      );
      break;

    case 'addEquipment':
      CurrentStage = (
        <div>
          <h3 className="mb-2 text-xl font-semibold text-gray-800">{t('createWithNoun', { noun: t('equipment') })}</h3>
          <AddEquipmentFormContent
            handleClose={() => stageHistory.push('selectEquipment')}
            addPieceOfEquipment={(newEquipment) => addEquipment(newEquipment)}
          />
        </div>
      );
      break;

    case 'addLocation':
      CurrentStage = (
        <div>
          <h3 className="mb-2 text-xl font-semibold text-gray-800">{t('createWithNoun', { noun: t('location') })}</h3>
          <LocationForm
            onSuccess={() => {
              queryClient.refetchQueries({ queryKey: [SubmitRequestSelectEquipmentQueryKey] });
              stageHistory.push('selectEquipment');
            }}
          />
        </div>
      );
      break;

    case 'submitRequest':
      CurrentStage =
        clientOrganizationType === ClientOrganizationEnumType.EquipsHomeWarranty ? (
          <MobileClaimFormScreen
            organizationId={organizationId}
            location={location}
            onSuccess={(value) => {
              const data = [value];
              setRequestData((state) => ({ ...state, serviceRequests: data }));
              stageHistory.push('completed');
              props.onSuccess?.(data);
            }}
          />
        ) : (
          <RequestForm
            equipment={equipment}
            location={location}
            geolocation={geolocation}
            value={requestData}
            organizationId={organizationId}
            onChange={(v) => setRequestData(v)}
            onSubmit={submitRequest}
            isSaving={isSavingRequest}
            pmView={!!props?.pmView}
          />
        );
      break;

    case 'completed':
      CurrentStage = (
        <div className="p-4">
          <ConfirmationScreen
            serviceRequestIds={requestData.serviceRequests?.map((sr) => sr.serviceRequestId || '')}
            serviceType={serviceType}
            onClickOpenAnotherRequest={() => {
              setRequestData(initialRequestData);
              stageHistory.clear();
              stageHistory.push('selectEquipment');
            }}
          />
        </div>
      );
      break;
    default:
      break;
  }

  return (
    <div>
      <div className="sticky top-0 z-10 flex items-center justify-between border-b bg-white p-2">
        <div className="flex items-center gap-2">
          <IconButton
            title="Go Back"
            type="button"
            onClick={() => stageHistory.pop()}
            disabled={stageHistory.length <= 1 || stageHistory.peek() === 'completed'}
            size="large"
          >
            <ArrowBack />
          </IconButton>

          <h2 className="whitespace-nowrap text-2xl font-semibold text-blue-800">{titles[serviceType]}</h2>
          {allowNewSrProcess && (
            <button className="text-xs text-gray-700" type="button" onClick={() => switchToNewVersion()}>
              Try the new Service Request experience!
            </button>
          )}
        </div>

        <IconButton type="button" onClick={handleClose} title="Close" size="large">
          <Close />
        </IconButton>
      </div>

      <div className="p-6 pt-3">{CurrentStage}</div>
    </div>
  );
}
