import React, { useMemo } from 'react';
import { ModuleNameEnum } from '@equips/entities-schema';

type CheckForRecentRebootsPropTypes = {
  cardReaderLastRebootedAt: string | null;
  dispenserLastRebootedAt: string | null;
  journalLastRebootedAt: string | null;
  machineLastRebootedAt: string | null;
  pinpadLastRebootedAt: string | null;
  receiptLastRebootedAt: string | null;
};

export const moduleRebootToNameLookup = {
  receiptLastRebootedAt: ModuleNameEnum.Receipt,
  cardReaderLastRebootedAt: ModuleNameEnum.CardReader,
  dispenserLastRebootedAt: ModuleNameEnum.Dispenser,
  journalLastRebootedAt: ModuleNameEnum.Journal,
  pinpadLastRebootedAt: ModuleNameEnum.Pinpad,
};

export default function CheckForRecentReboots({
  machineLastRebootedAt,
  cardReaderLastRebootedAt,
  dispenserLastRebootedAt,
  journalLastRebootedAt,
  pinpadLastRebootedAt,
  receiptLastRebootedAt,
}: CheckForRecentRebootsPropTypes) {
  const moduleTimestamps = {
    cardReaderLastRebootedAt,
    dispenserLastRebootedAt,
    journalLastRebootedAt,
    pinpadLastRebootedAt,
    receiptLastRebootedAt,
  };
  const tenMinutesAgo = new Date().getTime() - 60 * 10 * 1000; // subtract 10 minutes
  const thirtyMinutesAgo = new Date().getTime() - 3 * 60 * 10 * 1000; // subtract 30 minutes

  const modulesBeingRebooted = useMemo(() => {
    const modulesThatAreBeingRebooted: Array<ModuleNameEnum> = [];
    Object.keys(moduleTimestamps).forEach((moduleReboot) => {
      if (moduleReboot !== null && parseInt(moduleTimestamps[moduleReboot]) > tenMinutesAgo) {
        modulesThatAreBeingRebooted.push(moduleRebootToNameLookup[moduleReboot]);
      }
    });

    return modulesThatAreBeingRebooted;
  }, [cardReaderLastRebootedAt, dispenserLastRebootedAt, journalLastRebootedAt, pinpadLastRebootedAt, receiptLastRebootedAt]);

  const machineBeingRebooted = useMemo(() => {
    if (machineLastRebootedAt !== null && parseInt(machineLastRebootedAt) >= thirtyMinutesAgo) {
      return true;
    }

    return false;
  }, [machineLastRebootedAt]);

  return (
    <>
      {machineBeingRebooted && <p className="text-xs text-red-500 truncate">ATM reboot in progress</p>}
      {modulesBeingRebooted.map((item, idx) => (
        <p className="text-xs truncate text-red-500" key={idx}>
          {item} reboot in progress
        </p>
      ))}
      {!machineBeingRebooted && modulesBeingRebooted.length === 0 && (
        <p className="text-xs truncate text-green-500"> No reboots in progress</p>
      )}
    </>
  );
}
