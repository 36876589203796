import ReactGA from 'react-ga4';
import config from '../../config';

export const configureGA = () => {
  ReactGA.initialize(config.googleAnalytics.trackingID);
};

export const setGA = (context: object) => {
  ReactGA.set(context);
};

export const pageViewGA = (path: string, title?: string) => {
  ReactGA.send({ hitType: 'pageview', page: path, title });
};

export const eventGA = (category: string, action: string, label?: string, value?: number) => {
  ReactGA.send({ hitType: 'event', event_category: category, event_action: action, event_label: label, value });
};
