import { defaultServiceRequestSeverity } from '@equips/common-resources';
import {
  AttachmentMetadata,
  AttachmentType,
  createServiceRequestForIncident,
  EntityLabelEnum,
  Equipment,
  GeolocationType,
  Location,
  NoteAuthorizationLevel,
  NoteMetadataPostInput,
  patchAttachment,
  patchServiceRequest,
  postNote,
  postServiceRequest,
  ServiceRequest,
  ServiceRequestStatusType,
  ServiceTypeEnum,
} from '@equips/entities-schema';
import { GraphQLError } from 'graphql';
import { FileWithPath } from 'react-dropzone';
import { categoryGraphQlNode } from '../../../graphql/queries/categoryQueries';
import { arrayToGrammaticalList } from '../../functions/arrayToGrammaticalList';
import { createAttachment } from '../../functions/createAttachment';
import waitSynchronously from '../../functions/waitSynchronously';
import { createChecklistFromBlueprint } from '../Checklists/checklistMutations';
import { NoteListFields } from '../ViewPage/NoteListFragment';
import { generateId } from '../../functions/id';
import { AuthContextValue } from '../../auth/AuthContext';
import { RequestFormData } from './SubmitRequest';

const createServiceRequestQuery = `
serviceRequestId
metadata {
  assignedInternalUserId
  cancelationRequested
  contacts
  createdAt
  createdByUserId
  deactivatedAt
  deactivatedByUserId
  description
  dispatchedInternallyAt
  dispatchedToEquipsAt
  dispatchedToProviderAt
  followUpDate
  ignoreUntil
  locationId
  mainContactUserId
  modifiedAt
  modifiedByUserId
  organizationId
  potentiallyChronic
  providerId
  providerRefId
  recurrenceInterval 
  requestOrigination
  requestStatus
  requestedProviderId
  scheduledDate
  serviceRequestId
  serviceType
  severity
  shortId
  tags
  title
}
locationMetadata {
  locationDisplayName
}
organizationMetadata {
  organizationName
  organizationId
}
providerMetadata {
  organizationName
  organizationId
}
incidents {
  metadata {
    symptoms
    incidentId
    organizationId
    equipment {
      metadata {
        shortId
        serialNumber
        subclasses
      }
      specMetadata {
        ${categoryGraphQlNode}
        model
        manufacturer
      }
    }
  }
}
`;

export const appendPartInformationToRequestTitle = (title = '', { partQuantity = '', partNumber = '' }) => {
  let resultingTitle = title.trim();

  if (partQuantity) {
    resultingTitle += `\n\nQuantity: ${partQuantity}`;
  }

  if (partNumber) {
    resultingTitle += `\n\nPart number: ${partNumber}`;
  }

  return resultingTitle;
};

export const generateDefaultTitle = (data: Partial<RequestFormData> & { categoryName?: string | null }) => {
  const { symptoms = {}, serviceType, equipment, categoryName } = data || {};

  const category = categoryName ? categoryName : equipment ? 'equipment' : 'location';

  const symptomArr = Object.keys(symptoms).filter((key: string) => symptoms[key]) || [];

  if (symptomArr.length === 0) return `Issue with ${category}`;
  if (symptoms['Other'] && symptomArr.length === 1) return `Other issue with ${category}`;
  if (serviceType === ServiceTypeEnum.Preventive) return `Preventive maintenance for ${category}`;

  return `${symptomArr[0]} ${symptomArr.length >= 2 ? 'and other issues' : 'issue'} with ${category}`;
};

type SubmitRequestMutationArgs = {
  location?: Location;
  geolocation?: GeolocationType;
  equipment?: Equipment[];
  incidentId?: string;
  organizationId: string;
  requestData: RequestFormData;
  serviceType: ServiceTypeEnum;
  createSRQuery?: string;
  determineFeatureFlagVisibility?: AuthContextValue['determineFeatureFlagVisibility'];
};

async function createServiceRequestWithAttachment(
  postServiceRequestFn: () => ReturnType<typeof createServiceRequestForIncident> | ReturnType<typeof postServiceRequest>,
  organizationId,
  attachments: FileWithPath[] | undefined,
) {
  let response;
  let attachmentMetadata: AttachmentMetadata[] = [];

  if (attachments && attachments?.length > 0) {
    if (!organizationId) throw new Error('Missing organizationId');

    try {
      attachmentMetadata = await Promise.all(
        attachments.map((attachment: File) => {
          return createAttachment(attachment, {
            organizationId: organizationId || '',
            parentId: generateId(), // Placeholder. Works as attachment location does not rely on parentId
            parentLabel: EntityLabelEnum.ServiceRequest,
            type: AttachmentType.NoteAttachment,
          });
        }),
      );
    } catch (error) {
      console.error(error);
      throw new Error(
        'An error occurred uploading attachment to the Service Request.  Please check that the file meets the requirements and try again.',
      );
    }

    // Once attachment creation is successful, create SR
    response = await postServiceRequestFn();

    const serviceRequestId =
      response?.data?.createServiceRequestForIncident?.metadata?.serviceRequestId ?? response?.data?.post?.serviceRequestId;

    try {
      // Patch all attachments with created SR id
      await Promise.all(
        attachmentMetadata.map((attachment) => {
          const attachmentId = attachment?.attachmentId;
          const name = attachment?.name;
          return patchAttachment({
            attachmentId,
            metadata: {
              name,
              organizationId,
              parentId: serviceRequestId,
              parentLabel: EntityLabelEnum.ServiceRequest,
              type: AttachmentType.NoteAttachment,
            },
          });
        }),
      );

      // Post note to SR
      const metadata: NoteMetadataPostInput = {
        parentId: serviceRequestId,
        parentLabel: EntityLabelEnum.ServiceRequest,
        authorizationLevel: NoteAuthorizationLevel.All,
        message: 'Attachment(s) has been added to the service request.',
        attachmentIds: attachmentMetadata.map((metadata) => metadata?.attachmentId),
      };

      await postNote({ metadata }, { query: NoteListFields });
    } catch (error) {
      console.error('An error occurred while patching', error);
    }
  } else {
    response = await postServiceRequestFn();
  }
  return response;
}

export async function submitRequestMutation(args: SubmitRequestMutationArgs) {
  const { location, equipment, geolocation, requestData, serviceType, organizationId, createSRQuery } = args;
  const locationId = location?.locationId;
  const { createAsClosed, attachments, partNumber, partQuantity, symptoms, checklistBlueprint, assignedInternalUserId, ...metadataValues } =
    requestData;

  const symptomList = Object.entries(requestData.symptoms ?? {})
    .filter(([_k, v]) => !!v)
    .map(([k, _v]) => k);

  const title = appendPartInformationToRequestTitle(
    requestData?.title ||
      generateDefaultTitle({ ...requestData, categoryName: equipment?.[0]?.specMetadata?.category?.metadata?.name }) ||
      arrayToGrammaticalList(symptoms) ||
      `New ${serviceType.toLowerCase()} request`,
    { partNumber, partQuantity: partQuantity ? String(partQuantity) : undefined },
  );

  const requestMetadata = {
    ...metadataValues,
    assignedInternalUserId,
    organizationId,
    locationId,
    geolocation,
    title,
    severity: defaultServiceRequestSeverity,
    serviceType: serviceType,
    ...(createAsClosed ? { requestStatus: ServiceRequestStatusType.Closed, forceRequestStatus: ServiceRequestStatusType.Closed } : {}),
    ...(serviceType === ServiceTypeEnum.Consumables ? { forceRequestStatus: ServiceRequestStatusType.EquipsDispatch } : {}),
  };

  let errors: GraphQLError[] = [];
  let serviceRequests: ServiceRequest[] = [];

  if (args.incidentId) {
    const response = await createServiceRequestWithAttachment(
      () =>
        createServiceRequestForIncident(
          { incidentId: args.incidentId ?? '', serviceRequestMetadata: requestMetadata },
          { query: `metadata { serviceRequest { serviceRequestId } }` },
        ),
      organizationId,
      attachments,
    );

    if (response.errors) {
      errors.concat(response.errors);
    }
    const serviceRequest = response.data?.createServiceRequestForIncident?.metadata?.serviceRequest;
    if (serviceRequest) {
      serviceRequests.push(serviceRequest);
    }
  } else if (equipment && equipment.length > 0) {
    for (const eq of equipment) {
      if (eq && eq.equipmentId) {
        const response = await createServiceRequestWithAttachment(
          () =>
            postServiceRequest(
              {
                metadata: { ...requestMetadata, organizationId: eq.metadata?.organizationId, locationId: eq.metadata?.locationId },
                incident: {
                  organizationId: eq.metadata?.organizationId,
                  equipmentId: eq.equipmentId,
                  locationId: eq.metadata?.locationId,
                  symptoms: symptomList,
                },
              },
              {
                query: createSRQuery ?? createServiceRequestQuery,
              },
            ),
          organizationId,
          attachments,
        );

        // Patch SR with assigned internal user id to override dispatch routing
        const serviceRequestId = response?.data?.post?.serviceRequestId;
        if (serviceRequestId && assignedInternalUserId) {
          const patchResponse = await patchServiceRequest({ serviceRequestId, metadata: { assignedInternalUserId } });
          if (patchResponse.errors) {
            errors.concat(patchResponse.errors);
          }
        }

        if (response.errors) {
          errors.concat(response.errors);
        }

        if (response.data?.post) {
          serviceRequests.push(response.data?.post);
        }

        await waitSynchronously(500);
      }
    }
  } else if (location) {
    const response = await createServiceRequestWithAttachment(
      () =>
        postServiceRequest(
          {
            metadata: { ...requestMetadata, organizationId, locationId },
            incident: {
              organizationId,
              locationId,
              symptoms: symptomList,
            },
          },
          {
            query: createSRQuery ?? createServiceRequestQuery,
          },
        ),
      organizationId,
      attachments,
    );

    // Patch SR with assigned internal user id to override dispatch routing
    const serviceRequestId = response?.data?.post?.serviceRequestId;
    if (serviceRequestId && assignedInternalUserId) {
      const patchResponse = await patchServiceRequest({ serviceRequestId, metadata: { assignedInternalUserId } });
      if (patchResponse.errors) {
        errors.concat(patchResponse.errors);
      }
    }

    if (response.errors) {
      errors.concat(response.errors);
    }

    if (response.data?.post) {
      serviceRequests.push(response.data?.post);
    }

    await waitSynchronously(500);
  } else if (geolocation) {
    const response = await createServiceRequestWithAttachment(
      () =>
        postServiceRequest(
          {
            metadata: { ...requestMetadata, organizationId },
            incident: {
              organizationId,
              symptoms: symptomList,
            },
          },
          {
            query: createSRQuery ?? createServiceRequestQuery,
          },
        ),
      organizationId,
      attachments,
    );

    // Patch SR with assigned internal user id to override dispatch routing
    const serviceRequestId = response?.data?.post?.serviceRequestId;
    if (serviceRequestId && assignedInternalUserId) {
      const patchResponse = await patchServiceRequest({ serviceRequestId, metadata: { assignedInternalUserId } });
      if (patchResponse.errors) {
        errors.concat(patchResponse.errors);
      }
    }

    if (response.errors) {
      errors.concat(response.errors);
    }

    if (response.data?.post) {
      serviceRequests.push(response.data?.post);
    }

    await waitSynchronously(500);
  } else {
    throw new Error('Missing location.');
  }

  if (errors?.[0]) {
    console.error(errors);
    throw new Error('Error occured when submitting Service Request. Please check your request details and try again.');
  }

  for (const serviceRequest of serviceRequests) {
    const serviceRequestId = serviceRequest?.serviceRequestId || 'trialServiceRequestId';

    if (checklistBlueprint) {
      await createChecklistFromBlueprint({ blueprint: checklistBlueprint, serviceRequestId });
    }
  }

  return serviceRequests;
}
