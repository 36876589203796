import { OrganizationFeatureFlagEnum } from '@equips/entities-schema';
import { useQuery } from '@tanstack/react-query';
import states from 'us-state-converter';
import { useAuth } from '../../../common/auth/AuthContext';
import { isNsaOrganizationId, isUhwOrganizationId } from '../../../common/functions/uhwHelpers';
import { getClaimInforForNSAForm } from '../../home-warranty/claims/graphQLQueries';
import { generateId } from '../../../common/functions/id';
import config from '../../../config/dev/config';

interface NationalServiceAllianceProps {
  serviceRequestId: string;
  selectedProviderId?: string;
}
const NationalServiceAlliance = ({ serviceRequestId, selectedProviderId }: NationalServiceAllianceProps) => {
  const { auth, determineFeatureFlagVisibility } = useAuth();
  const isCurrentlyDispachedToNsa = isNsaOrganizationId(selectedProviderId);

  const isUhwUser = isUhwOrganizationId(auth?.organizationId);
  const isServiceProviderNetworkNsaEnabled = determineFeatureFlagVisibility(OrganizationFeatureFlagEnum.ServiceProviderNetworkNsa);

  const { data } = useQuery(['getClaimInforForNSAForm', serviceRequestId], () => getClaimInforForNSAForm({ serviceRequestId }), {
    enabled: isUhwUser && isServiceProviderNetworkNsaEnabled,
    select: (data) => data?.data?.serviceRequests?.data?.[0]?.metadata,
  });

  const createClaimNSABaseUrl = new URL(config?.nsa?.createClaimNSABaseUrl);

  const transactionId = generateId();
  const contract = data?.serviceContract?.metadata;
  const location = contract?.location || {};
  const address = location?.address || {};

  const firstName = location.metadata?.locationName?.split(' ')[0] || '';
  const lastName = location.metadata?.locationName?.split(' ')[1] || '';
  const line1 = address?.line1 || '';
  const line2 = address?.line2 || '';
  const city = address?.city || '';
  const stateInfo = states(address?.stateUnabbreviated || '');
  const state = stateInfo !== 'No state found!' ? stateInfo?.usps : '';
  const zip = address?.zip || '';
  const shortId = data?.shortId || '';
  const serviceCallFee = String(contract?.serviceCallFee) || '';

  const params = new URLSearchParams({
    xTransID: transactionId,
    lastn: lastName,
    firstn: firstName,
    addr1: line1,
    addr2: line2,
    city: city,
    state: state,
    zip: zip,
    custrefnumber: shortId,
    deductibleAmount: serviceCallFee,
    Plan: '859',
  });

  createClaimNSABaseUrl.search = params.toString();
  const createClaimNSAUrl = createClaimNSABaseUrl.toString();
  const viewClaimInNSAUrl = new URL(config?.nsa?.viewClaimInNSAUrl).toString();

  if (isUhwUser) {
    return (
      <>
        <p>National Service Alliance</p>
        <div className="flex flex-row gap-4">
          {!isCurrentlyDispachedToNsa ? (
            <a href={createClaimNSAUrl} target="_blank" rel="noopener noreferrer" className="underline">
              Create in NSA Portal
            </a>
          ) : (
            <a href={viewClaimInNSAUrl} target="_blank" rel="noopener noreferrer" className="underline">
              View in NSA Portal
            </a>
          )}
        </div>
      </>
    );
  }
  return null;
};

export default NationalServiceAlliance;
