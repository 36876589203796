import React from 'react';
import { Incident } from '@equips/entities-schema';
import { Link } from 'react-router-dom';
import { arrayToGrammaticalStringOmittingEmptyValues, formatAddressAsOneLine } from '@equips/common-resources';
import DropDown from '../../common/components/DropDown';
import { TableColumn } from '../../common/components/DataTable';
import { TableFilterTypes } from '../../common/components/Table/Filters';
import { defaultTableWidths } from '../../common/components/Table/tableDefaults';
import Urls from '../../routes/Urls';
import { clientValueForFilter } from '../../graphql/queries/organizationGraphQLQueries';
import { toDateStringFromUnixMillisecondTimestamp } from '../../common/functions/dateFunctions';
import { createdColumn, modifiedColumn } from '../../common/components/DataTable/columns';
import { displayEquipmentOnOneLine } from './displayEquipment';

export default function IncidentColumns(): TableColumn<Incident>[] {
  function ActionComponent(d) {
    return (
      <DropDown
        containerClasses="more-actions"
        actions={[{ Component: 'View incident', to: `${Urls.INCIDENTS}/${d?.row?.original?.metadata?.incidentId}` }]}
      />
    );
  }

  return [
    {
      id: 'metadata.symptoms',
      Header: 'Symptoms',
      accessor: (data) => data?.metadata?.symptoms,
      disableSortBy: true,
      width: defaultTableWidths.largeText,
      Cell: (d) => (
        <Link
          data-incidentid={d?.row?.original?.metadata?.incidentId}
          data-testid="toIncidentDetail"
          to={`${Urls.INCIDENTS}/${d?.row?.original?.metadata?.incidentId}`}
        >
          {arrayToGrammaticalStringOmittingEmptyValues(d?.row?.original?.metadata?.symptoms) || 'No symptoms'}
        </Link>
      ),
      filterOptions: {
        type: TableFilterTypes.text,
      },
    },
    {
      id: 'metadata.faultCode',
      Header: 'Fault code',
      accessor: (data) => data?.metadata?.faultCode,
      disableSortBy: true,
      width: defaultTableWidths.normalText,
      Cell: (d) => d?.value || '',
    },
    {
      id: 'metadata.faultDescription',
      Header: 'Fault description',
      accessor: (data) => data?.metadata?.faultDescription,
      disableSortBy: true,
      width: defaultTableWidths.largeText,
      Cell: (d) => d?.value || '',
    },
    {
      id: 'metadata.equipmentId',
      Header: 'Equipment',
      accessor: (data) => data?.metadata?.equipmentId,
      width: defaultTableWidths.largeText,
      Cell: (d) => displayEquipmentOnOneLine(d?.row?.original?.metadata?.equipment),
      filterOptions: {
        type: TableFilterTypes.hidden,
      },
    },
    {
      id: 'metadata.locationId',
      Header: 'Location',
      accessor: (data) => data?.metadata?.locationId,
      width: defaultTableWidths.largeText,
      Cell: (d) => formatAddressAsOneLine(d?.row?.original?.metadata?.location?.address) || '',
      filterOptions: {
        type: TableFilterTypes.locationSelect,
        organizationIdLocation: 'organizationId',
      },
    },
    {
      id: 'metadata.organizationId',
      Header: 'Organization',
      accessor: (data) => data?.metadata?.organizationId,
      width: defaultTableWidths.largeText,
      Cell: (d) => d?.row?.original?.metadata?.organization?.metadata?.organizationName || '',
      filterOptions: {
        type: TableFilterTypes.organizationSelect,
        label: 'Client',
        typeOfOrganizationToFind: clientValueForFilter,
      },
    },
    {
      id: 'metadata.originApiId',
      Header: 'Origin API Id',
      accessor: (data) => data?.metadata?.originApiId,
      disableSortBy: true,
      width: defaultTableWidths.normalText,
      Cell: (d) => d?.value || '',
    },
    {
      id: 'metadata.serviceRequestId',
      Header: 'Service Request number',
      accessor: (data) => data?.metadata?.serviceRequestId,
      disableSortBy: true,
      width: defaultTableWidths.largeText,
      Cell: (d) => d?.row?.original?.metadata?.serviceRequest?.metadata?.shortId || '',
      filterOptions: {
        label: 'Has Service Request',
        type: TableFilterTypes.select,
        options: [
          { label: 'All', value: '' },
          { label: 'Has service request', value: 'true' },
          { label: 'No service request', value: 'false' },
        ],
        defaultValue: 'false',
      },
    },
    {
      ...createdColumn,
      filterOptions: { defaultValue: '30', type: TableFilterTypes.hidden },
    },
    {
      ...modifiedColumn,
    },
    {
      id: 'metadata.endedAt',
      Header: 'Ended at',
      accessor: (data) => data?.metadata?.endedAt,
      disableSortBy: true,
      width: 150,
      Cell: (d) => toDateStringFromUnixMillisecondTimestamp(d?.original?.metadata?.endedAt),
      toText: (data) => toDateStringFromUnixMillisecondTimestamp(data?.original?.metadata?.endedAt),
    },
    {
      id: 'metadata.incidentId',
      Header: '',
      disableSortBy: true,
      accessor: (data) => data?.metadata?.incidentId,
      Cell: (data) => <ActionComponent {...data} />,
    },
  ];
}
