import { NotesFiltersInput } from '@equips/entities-schema';
import { TableFetchArgs } from '../../common/components/DataTable';
import { transformDateRangeSelectAndCustomDateIntoGraphQLQuery } from '../../common/components/Date/DateRangeFilter';

export function getNotesFiltersInput(filterMap: TableFetchArgs['filterMap']): NotesFiltersInput[] {
  return [
    {
      metadata: {
        ...(filterMap['metadata.deactivatedAt'] ? { deactivatedAt: [{ exists: true }] } : {}),
        createdAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.createdAt']),
        modifiedAt: transformDateRangeSelectAndCustomDateIntoGraphQLQuery(filterMap['metadata.modifiedAt']),
        ...(filterMap['metadata.createdByUserId']?.length
          ? { createdByUserId: [{ keyword: [{ terms: filterMap['metadata.createdByUserId'] }] }] }
          : {}),
        ...(filterMap['metadata.modifiedByUserId']?.length
          ? { modifiedByUserId: [{ keyword: [{ terms: filterMap['metadata.modifiedByUserId'] }] }] }
          : {}),
        ...(filterMap['metadata.tags']?.length ? { tags: [{ keyword: [{ terms: filterMap['metadata.tags'] }] }] } : {}),
        ...(filterMap['metadata.not.tags']?.length ? { tags: [{ keyword: [{ notTerms: filterMap['metadata.not.tags'] }] }] } : {}),
        ...(filterMap['metadata.parentLabel']?.length
          ? { parentLabel: [{ keyword: [{ terms: filterMap['metadata.parentLabel'] }] }] }
          : {}),
        ...(filterMap['metadata.byInternalUser'] ? { byInternalUser: [{ term: filterMap['metadata.byInternalUser'] === 'true' }] } : {}),
        ...((filterMap['metadata.organizationId']?.length || 0) > 0
          ? { organizationId: [{ keyword: [{ terms: filterMap['metadata.organizationId'] }] }] }
          : {}),
        ...((filterMap['metadata.not.organizationId']?.length || 0) > 0
          ? { organizationId: [{ keyword: [{ notTerms: filterMap['metadata.not.organizationId'] }] }] }
          : {}),
      },
      organizationMetadata: {
        ...(filterMap['organizationMetadata.clientOrganizationType']
          ? { clientOrganizationType: [{ keyword: [{ term: filterMap['organizationMetadata.clientOrganizationType'] }] }] }
          : {}),
      },
    },
  ];
}
