import { EntityLabelEnum, UserTagsType } from '@equips/entities-schema';
import { AuthContextValue } from '../../../common/auth/AuthContext';
import { internalUsers } from '../../../common/auth/roles';
import IncidentColumns from '../../incidents/IncidentColumns';
import { getInvoiceColumns } from '../../invoices/InvoicesColumns';
import { invoicePreviewEnabled } from '../../invoices/businessLogic/invoicesPreview';
import NoteColumns from '../../notes/NoteColumns';
import CaptureColumns from '../../qr-codes/components/CaptureColumns';
import { getServiceRequestColumns } from '../../service-requests/ServiceRequestsColumns';
import { getServiceRequestStatusOptions } from '../../service-requests/businessLogic/serviceRequestStatusOptions';

function getEntityColumns(entity: EntityLabelEnum, authContext: AuthContextValue) {
  const { auth, userCan, isAasUser, determineFeatureFlagVisibility, clientOrganizationType } = authContext;
  const isIht = auth?.tags && auth.tags.includes(UserTagsType.InHouseTech);

  switch (entity) {
    case EntityLabelEnum.ServiceRequest:
      return getServiceRequestColumns({
        serviceRequestSelectEnum: getServiceRequestStatusOptions({ auth }),
        userCan,
        isAasUser,
        auth,
        clientOrganizationType,
        determineFeatureFlagVisibility,
      });
    case EntityLabelEnum.Incident:
      return IncidentColumns();
    case EntityLabelEnum.Note:
      return NoteColumns();
    case EntityLabelEnum.Capture:
      return CaptureColumns();
    case EntityLabelEnum.Invoice:
      return getInvoiceColumns(userCan, isIht || userCan(internalUsers) || invoicePreviewEnabled(auth));
    default:
      return [];
  }
}

export { getEntityColumns };
