import { ChecklistBlueprint, ChecklistStep, postChecklist } from '@equips/entities-schema';

export async function createChecklistFromBlueprint({
  blueprint,
  serviceRequestId,
  serviceStatusId,
}: {
  blueprint: ChecklistBlueprint;
  serviceRequestId: string;
  serviceStatusId?: string;
}) {
  const { name, steps, checklistBlueprintId } = blueprint.metadata || {};

  await postChecklist({
    metadata: {
      serviceRequestId,
      checklistBlueprintId,
      serviceStatusId,
      name,
      steps: steps as ChecklistStep[],
    },
  });
}
