import { Maybe } from '@equips/entities-schema';

export const devUhwOrganizationId = '6d8e370e2f7c4e7c8978e77d314fb8b0';
export const prodUhwOrganizationId = '933eea2ca8364674a7363a75c5a21d5b';

export function getUhwOrganizationId() {
  return window.process.env.stage === 'prod' ? prodUhwOrganizationId : devUhwOrganizationId;
}

export function isUhwOrganizationId(organizationId: Maybe<string>) {
  if (organizationId) {
    return organizationId === getUhwOrganizationId();
  }
  return false;
}

// 🚨 TODO: RD-449-core-service-provider-selection
export const prodNsaOrganizationId = '';
export const devNsaOrganizationId = 'c0ec3d94a5c74600a90590ffb514098d';

export function getNsaOrganizationId() {
  return window.process.env.stage === 'prod' ? prodNsaOrganizationId : devNsaOrganizationId;
}

export function isNsaOrganizationId(organizationId: Maybe<string>) {
  if (organizationId) {
    return organizationId === getNsaOrganizationId();
  }
  return false;
}
