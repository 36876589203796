import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Warning from '@mui/icons-material/Warning';

export enum InlineAlertEnum {
  Info = 'info',
  Warning = 'warning',
  Basic = 'basic',
}

type SvgIconComponent = typeof SvgIcon;

type InlineAlertProps = { children: any; type?: InlineAlertEnum; padding?: string; margin?: string; IconOverride?: SvgIconComponent };

export default function InlineAlert({
  children,
  type = InlineAlertEnum.Info,
  padding = 'p-2',
  margin = '',
  IconOverride,
  ...restOfProps
}: InlineAlertProps) {
  const theme = {
    info: {
      background: 'bg-blue-200',
      text: 'text-blue-800',
      Icon: InfoOutlined,
    },
    warning: {
      background: 'bg-orange-200',
      text: 'text-black-800',
      Icon: Warning,
    },
    basic: {
      background: 'bg-gray-50',
      text: 'text-gray-800',
      Icon: InfoOutlined,
    },
  };
  const Icon = IconOverride || theme[type]?.Icon;

  return (
    <div className={`rounded-lg ${padding} ${margin} ${theme[type].background}`} {...restOfProps}>
      <div className={`flex items-center px-2 text-xs leading-relaxed ${theme[type].text}`}>
        <div className="mr-2">{Icon && <Icon fontSize="small" />}</div>
        <div>{children}</div>
      </div>
    </div>
  );
}
