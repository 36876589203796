import { useMemo } from 'react';
import {
  decodeQueryParams,
  encodeQueryParams,
  updateLocation,
  StringParam,
  NumberParam,
  JsonParam,
  withDefault,
} from 'serialize-query-params';
import queryStringLib from 'query-string';
import { TableColumn } from '.';

function isEmpty(value: unknown) {
  return value == null || (typeof value === 'string' && !value.length) || (Array.isArray(value) && !value.length);
}

export function getQuerySchema(options: { columnDefinitions: TableColumn<any>[]; defaults: Record<string, any> }) {
  const schema = {
    search: withDefault(StringParam, options.defaults.search),
    pageIndex: withDefault(NumberParam, options.defaults.pageIndex),
    pageSize: withDefault(NumberParam, options.defaults.pageSize),
    sortBy: withDefault(JsonParam, options.defaults.sortBy),
  };
  // Filter param encoding is determined by the column's filterOptions
  options.columnDefinitions.forEach((column) => {
    const { filterOptions } = column;
    const type = filterOptions?.filterType ?? StringParam;
    const defaultValue = filterOptions?.defaultValue ?? undefined;
    schema[column.id] = withDefault(type, defaultValue);
  });

  return schema;
}

type QuerySchema = ReturnType<typeof getQuerySchema>;

export function getQueryParams(querySchema: QuerySchema, search: Window['location']['search']) {
  return decodeQueryParams(querySchema, queryStringLib.parse(search));
}

export function useTableQueryParams(options: { columnDefinitions: TableColumn<any>[]; defaults: Record<string, any> }) {
  const querySchema = useMemo(() => getQuerySchema(options), [options.columnDefinitions]);

  function setQueryParams(value: Record<string, any>) {
    // Reset empty items and skip default values to prevent clutter
    const filteredParams = Object.fromEntries(
      Object.entries(value).map(([key, value]) => {
        const skip = isEmpty(value) || options.defaults[key] === value;

        return skip ? [key, undefined] : [key, value];
      }),
    );
    const params = encodeQueryParams(querySchema, filteredParams);
    const location = updateLocation(params, window.location);
    window.history.pushState(null, '', location.href);
  }

  const query = useMemo(() => getQueryParams(querySchema, window.location.search), []);
  const { search, pageIndex, pageSize, sortBy, ...filterParams } = query;

  const initialState = {
    ...options.defaults,
    globalFilter: search,
    search,
    pageIndex,
    pageSize,
    sortBy,
    filters: Object.entries(filterParams).map(([id, value]) => ({ id, value })),
  };

  function getUpdatedQueryParams() {
    const updatedQuery = getQueryParams(querySchema, window.location.search);
    const { search, pageIndex, pageSize, sortBy, ...filterParams } = updatedQuery;
    const updatedState = {
      ...options.defaults,
      globalFilter: search,
      search,
      pageIndex,
      pageSize,
      sortBy,
      filters: Object.entries(filterParams).map(([id, value]) => ({ id, value })),
    };
    return updatedState;
  }

  return {
    initialState,
    setQueryParams,
    getUpdatedQueryParams,
  };
}
