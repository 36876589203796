import { useQuery } from '@tanstack/react-query';
import { queryOrganizations } from '@equips/entities-schema';
import { formatOrganizationForReactSelect } from '../../../../common/components/Selects/OrganizationsSelect';
import { getOrganizationsForSelect, providerSources, providerValueForFilter } from '../../../../graphql/queries/organizationGraphQLQueries';

const useGroupedProviderOptions = ({
  queryKey,
  possibleProviderIds = [],
  defaultProviderIds = [],
}: {
  queryKey: string;
  possibleProviderIds: string[];
  defaultProviderIds?: string[];
}) => {
  const { data: possibleProviders } = useQuery(
    ['getPrioritizedOrgInfo', possibleProviderIds],
    () =>
      queryOrganizations({
        search: {
          filters: [
            {
              metadata: { organizationId: [{ keyword: [{ terms: possibleProviderIds }] }] },
              // remove after tarp is available to all organizations
              provider: { providerSource: [{ keyword: [{ terms: providerSources }] }, { keyword: [{ term: null }] }] },
            },
          ],
        },
      }),
    {
      select: (data) => {
        const options = data?.data?.organizations?.data || [];
        return options.map((item) => formatOrganizationForReactSelect(item));
      },
      enabled: possibleProviderIds.length >= 1,
    },
  );

  const { data: allProviders } = useQuery(
    ['getOrganizationsForSelect', queryKey],
    () =>
      getOrganizationsForSelect({
        search: '',
        defaultOrganizationIds: defaultProviderIds,
        typeOfOrganizationToFind: providerValueForFilter,
        possibleProviderIds,
      }),
    {
      select: (data) => {
        const options = data?.data?.organizations?.data || [];
        return options.map((item) => formatOrganizationForReactSelect(item));
      },
    },
  );

  const groupedOptions = [
    {
      label: 'Possible Providers',
      options: possibleProviders ?? [],
    },
    { label: 'All Providers', options: allProviders ?? [] },
  ];

  return groupedOptions;
};

export default useGroupedProviderOptions;
