import {
  EntityLabelEnum,
  GraphQLQueryOptions,
  queryCaptures,
  queryIncidents,
  queryInvoices,
  queryNotes,
  queryServiceRequests,
  SortByElasticSearchOptions,
} from '@equips/entities-schema';
import { useQueries, UseQueryOptions } from '@tanstack/react-query';
import { isEmpty, pickBy } from 'lodash';
import { useMemo } from 'react';
import { AuthContextValue, useAuth } from '../../../common/auth/AuthContext';
import { getQueryParams, getQuerySchema } from '../../../common/components/DataTable/useTableQueryParams';
import transformTableSortToGraphQLSort from '../../../graphql/transformTableSortToGraphQLSort';
import { Task, TaskFeed } from '../libs/interfaces';
import { getEntityColumns } from '../utils/getEntityColumns';
import { getEntityFiltersInput } from '../utils/getEntityFiltersInput';

function getEntityQueryFnOptions(entity: EntityLabelEnum): GraphQLQueryOptions | undefined {
  switch (entity) {
    case EntityLabelEnum.ServiceRequest:
      return {
        query: `
          __typename
          metadata {
            cancelationRequested
            createdAt
            equipment {
              metadata {
                hasChronicIssues
              }
            }
            potentiallyChronic
            requestStatus
            serviceRequestId
            severity
            shortId
            title
            tags
          }
          organizationMetadata {
            organizationName
            tags
          }
          specMetadata {
            category {
              metadata {
                name
              }
            }
          }  
          `,
      };
    case EntityLabelEnum.Incident:
      return {
        query: `
          __typename
          metadata {
            incidentId
            createdAt
            faultDescription
            equipment {
              metadata {
                externalId
                serialNumber
                shortId
                tid
                cardReaderLastRebootedAt
                dispenserLastRebootedAt
                journalLastRebootedAt
                machineLastRebootedAt
                pinpadLastRebootedAt
                receiptLastRebootedAt
              }
            }
            organization {
              metadata {
                organizationName
              }
            }
          }
        `,
      };
    case EntityLabelEnum.Note:
      return {
        query: `
          __typename
          noteId
          metadata {
            noteId
            parentId
            parentLabel
            message
            attachmentIds
            aiGeneratedSummary
            createdAt
            tags
            createdByUser {
              metadata {
                fullName
              }
            }
            serviceRequest {
              metadata {
                shortId
                title
              }
            }
            equipment {
              metadata {
                shortId
              }
            }
          }
          organizationMetadata {
            tags
          }
        `,
      };
    case EntityLabelEnum.Capture:
      return {
        query: `
          __typename
          metadata{
            captureId
            shortId
            notes
            locationId
            location{
              metadata{
                locationDisplayName
              }
            }
            createdAt
          }
        `,
      };
    case EntityLabelEnum.Invoice:
      return {
        query: `
          __typename
          metadata {
            invoiceId
            shortId
            invoiceStatus
            followUpDate
            createdAt
            followUpDate
            serviceRequest{
              metadata{
                assignedInternalUserId
              }
            }
            providerOrganizationName
          }
        `,
      };
    default:
      return {
        query: `
          __typename
        `,
      };
  }
}

function transformTaskFeed(feed: TaskFeed, authContext: AuthContextValue) {
  const { entity, search } = feed;
  const columnDefinitions = getEntityColumns(entity, authContext);
  const querySchema = getQuerySchema({
    columnDefinitions,
    defaults: {},
  });

  const queryParams = getQueryParams(querySchema, search);

  const query = pickBy(queryParams, (value) => !isEmpty(value));

  const filters = getEntityFiltersInput(entity, query, authContext);

  const sortBy = transformTableSortToGraphQLSort(query?.sortBy);

  const pageSize = queryParams.pageSize; // for some reason isEmpty(1) is true

  return { entity, filters, sortBy, pageSize };
}

type TransformedTaskFeed = ReturnType<typeof transformTaskFeed>;

function taskFeedReducer(accumulator: Record<EntityLabelEnum, TransformedTaskFeed>, currentValue: TransformedTaskFeed) {
  const { entity, filters, sortBy, pageSize } = currentValue;

  if (!accumulator[entity]) {
    return {
      ...accumulator,
      [entity]: { filters, sortBy, pageSize },
    };
  }

  return {
    ...accumulator,
    [entity]: {
      filters: [...accumulator[entity].filters, ...filters],
      sortBy: [...accumulator[entity].sortBy, ...sortBy],
      pageSize: pageSize > accumulator[entity].pageSize ? pageSize : accumulator[entity].pageSize, //get max
    },
  };
}

function getEntityQueryFn(entity: EntityLabelEnum, params: TransformedTaskFeed) {
  const DEFAULT_SORT_BY = [{ metadata: { createdAt: SortByElasticSearchOptions.Desc } }];
  const sortBy = params.sortBy;
  const pageSize = params.pageSize;

  const variables = {
    search: {
      sortBy: isEmpty(sortBy) ? DEFAULT_SORT_BY : sortBy,
      size: pageSize ? pageSize : 25,
      filters: params.filters,
    },
  };

  const options = getEntityQueryFnOptions(entity);

  switch (entity) {
    case EntityLabelEnum.ServiceRequest:
      return queryServiceRequests(variables, options);
    case EntityLabelEnum.Incident:
      return queryIncidents(variables, options);
    case EntityLabelEnum.Note:
      return queryNotes(variables, options);
    case EntityLabelEnum.Capture:
      return queryCaptures(variables, options);
    case EntityLabelEnum.Invoice:
      return queryInvoices(variables, options);
  }
}

const defaultOptions: Omit<UseQueryOptions, 'queryKey' | 'queryFn'> = {
  refetchInterval: 10000,
  refetchIntervalInBackground: false,
  refetchOnWindowFocus: true,
};

function useTask(task: Task | undefined, options: Omit<UseQueryOptions, 'queryKey' | 'queryFn'> = defaultOptions) {
  const authContext = useAuth();
  const { auth } = authContext;
  if (!auth) throw new Error('Not authorized');

  const { slug, feeds } = {
    slug: undefined,
    feeds: [],
    ...(task ? task : {}),
  };

  const entityFilters = useMemo(
    () =>
      feeds.map((feed) => transformTaskFeed(feed, authContext)).reduce(taskFeedReducer, {} as Record<EntityLabelEnum, TransformedTaskFeed>),
    [feeds, authContext],
  );

  const queries = useMemo(() => {
    return Object.entries(entityFilters).map(([entity, params]) => {
      return {
        queryKey: ['tasks', 'feeds', entity, slug],
        queryFn: () => getEntityQueryFn(entity as EntityLabelEnum, params),
        select: (data) => data.data?.[`${entity}s`]?.data ?? [],
        ...defaultOptions,
        ...options,
      };
    });
  }, [entityFilters, options, slug]);

  return useQueries({
    queries,
  });
}

export { useTask };
