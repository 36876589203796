import React from 'react';
import Label from '../Form/Label';
import FormGroup from '../Form/FormGroup';
import StyledSelect from '../Selects/StyledSelect';
import timezones from '../../constants/timezones';
import InlineAlert, { InlineAlertEnum } from '../Alerts/InlineAlert';

type TimeZoneSelectorType = {
  value: string;
  onChange: any;
  fullWidth?: boolean;
  discardFormGroup?: boolean;
  loading?: boolean;
  label?: string;
  id?: string;
  name?: string;
  labelHelper?: string;
};

export default function TimeZoneSelector({
  discardFormGroup = false,
  loading = false,
  label = 'Timezone',
  labelHelper = '',
  name = 'timeZoneSelector',
  id = 'timeZoneSelector',
  value,
  onChange,
  fullWidth = false,
}: TimeZoneSelectorType) {
  function InputInners() {
    return (
      <>
        <InlineAlert type={InlineAlertEnum.Basic} padding="p-4">
          <p className="font-semibold">Timezone is only required for off-hours dispatch.</p>
          <p>
            We will try to infer your timezone from your address. If your timezone is still empty after submission, please select it
            manually. If your timezone is not listed, please contact us.
          </p>
        </InlineAlert>
        <Label className="mt-2" label={label} id={id} helper={labelHelper} />
        <StyledSelect id={id} name={name} value={value} loading={loading} onChange={(e) => onChange(e)}>
          <option value="">-- Select Timezone --</option>
          {timezones.map((timezone) => (
            <option key={timezone} value={timezone}>
              {timezone}
            </option>
          ))}
        </StyledSelect>
      </>
    );
  }

  if (discardFormGroup) return <InputInners />;

  return (
    <FormGroup data-testid="state-selector" fullWidth={fullWidth}>
      <InputInners />
    </FormGroup>
  );
}
