import { EntityLabelEnum } from '@equips/entities-schema';
import { AuthorizationData } from '../../../../common/auth/AuthContext';
import { getAasOrganizationId } from '../../../../common/functions/aasHelpers';
import { generateId } from '../../../../common/functions/id';
import { Task } from '../../libs/interfaces';

export async function fetchMockTasks({ userId }: AuthorizationData) {
  const newTask: Task = {
    taskId: generateId(),
    shortId: generateId(),
    name: 'New',
    slug: 'new',
    description: 'Get one assigned (takes priority) or one unassigned invoice',
    feeds: [
      {
        taskFeedId: generateId(),
        shortId: generateId(),
        name: 'assigned',
        slug: 'assigned',
        description: '',
        entity: EntityLabelEnum.Invoice,
        search: `?metadata.invoiceStatus=%5B"NEW"%5D&metadata.followUpDate=%7Bnone%7D&metadata.quoteFileId=true&metadata.clientOrganizationId=${getAasOrganizationId()}&serviceRequestMetadata.assignedInternalUserId=${userId}&claimMetadata.processingStatus=%5B%22authorization%22%2C%22reauthorization%22%5D&sortBy=%5B%7B"id"%3A"metadata.createdAt"%2C"desc"%3Afalse%7D%5D&pageSize=1`,
      },
      {
        taskFeedId: generateId(),
        shortId: generateId(),
        name: 'unassigned',
        slug: 'unassigned',
        description: '',
        entity: EntityLabelEnum.Invoice,
        search: `?metadata.invoiceStatus=%5B"NEW"%5D&metadata.followUpDate=%7Bnone%7D&metadata.quoteFileId=true&metadata.clientOrganizationId=${getAasOrganizationId()}&serviceRequestMetadata.assignedInternalUserId=unassigned&claimMetadata.processingStatus=%5B%22authorization%22%2C%22reauthorization%22%5D&sortBy=%5B%7B"id"%3A"metadata.createdAt"%2C"desc"%3Afalse%7D%5D&pageSize=1`,
      },
    ],
  };

  const holdsTask: Task = {
    taskId: generateId(),
    shortId: generateId(),
    name: 'Holds',
    slug: 'holds',
    description: 'Get all invoices created by the user, on hold--oldest one appears first',
    feeds: [
      {
        taskFeedId: generateId(),
        shortId: generateId(),
        name: '',
        slug: '',
        description: '',
        entity: EntityLabelEnum.Invoice,
        search: `?metadata.invoiceStatus=%5B"NEW"%5D&metadata.followUpDate=%7Bnotempty%7D&metadata.quoteFileId=true&metadata.clientOrganizationId=${getAasOrganizationId()}&serviceRequestMetadata.assignedInternalUserId=${userId}&claimMetadata.processingStatus=%5B%22authorization%22%2C%22reauthorization%22%5D&sortBy=%5B%7B"id"%3A"metadata.followUpDate"%2C"desc"%3Afalse%7D%5D`,
      },
    ],
  };

  return await Promise.resolve([newTask, holdsTask]);
}
